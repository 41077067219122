import React from 'react';
import { IWinnersListPreloaderProps } from '../types';
import { detectMediaSize } from '../../../helpers/utils';

const itemCountMap: { [key: string]: number } = {
	web        : 6,
	new_tablet : 5,
	tablet     : 4,
	tablet_mini: 4,
	mobile     : 4,
};

const WinnersListPreloader: React.FC<IWinnersListPreloaderProps> = ({ className, headClassName, pageNumber }) => {
	const device = detectMediaSize();
	const itemCount = itemCountMap[device];
	const skeletonCount: string[] = new Array(itemCount).fill('Item');
	const showHeader = Number(pageNumber) <= 2;
	
	return (
		<div className={className}>
			{showHeader && headClassName && <div className={headClassName}> <span className="animate_block"></span> </div>}
			<ul className="winners_preloader">
				{skeletonCount.map((_, i) =>  <li key={i}><span></span></li>)}
			</ul>
		</div>
	);
};

export default WinnersListPreloader;
