import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';

class CookiesApi {
	getCookieIDs = () => {
		const url = '/v1/available/cookies';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};
}

export default CookiesApi;