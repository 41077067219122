import { FC } from 'react';
import { IModalDescriptionProps } from './types';


export const ModalDescription: FC<IModalDescriptionProps> = (props) => {
	const { title, description } = props;

	return (
		<div>
			<div>
				{title}
			</div>
			<div>
				{description}
			</div>
		</div>
	);
};