import { apiRequest } from '../../service/service';
import { ITimeOutRequestPayload } from './types';
import { PartialConfigs } from '../../service/types';

class HistoryAPI {

	addTimeOut = (data: ITimeOutRequestPayload) => {
		const url = '/v1/gambling';
		const config: PartialConfigs<ITimeOutRequestPayload> = {
			method: 'post',
			url   : url,
			data,
		};

		return apiRequest(config);
	};

	getTimeOut = () => {
		const url = '/v1/userGamblings';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		return apiRequest(config);
	};

}

export default HistoryAPI;
