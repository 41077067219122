import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { NotDesktop, Desktop } from '../../../helpers/devices';
import SearchPanel from '../../search-panel';
import SidebarBottom from '../../sidebar/sidebar-bottom';
import SidebarMiddle from '../../sidebar/sidebar-middle';
import { menuSelectors } from '../../../redux/menu/selectors';
import { menuReducers } from '../../../redux/menu/slice';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';

import './sidebar.scss';
import { translate } from '../../../translations/utils';

const {
	selectSidebar,
	selectBottomMenuUI,
} = menuSelectors;
const {
	toggleSidebar,
} = menuReducers;

const Sidebar: React.FC = () => {
	const sidebar = useAppSelector(selectSidebar);
	const layout = useAppSelector(store => store.Settings.layout);
	const { notificationVisible } = useAppSelector(selectBottomMenuUI);
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { pathname } = location || {};

	const isSidebar = pathname.includes('tournament');
	const blurEffect = notificationVisible ? 'blurEffect' : '';
	if (layout === 'promotion' || layout === 'error'){
		return false;
	}

	const handleClick = () => {
		dispatch(toggleSidebar());
		sidebar ?
			document.body.classList.remove('menu_opened')
			: document.body.classList.add('menu_opened');
	};

	return (
		<Fragment>
			{
				layout === 'game' ? <Desktop>
					<div className={`sidebar_menu ${sidebar ? 'sidebar_toggle' : ''} ${isSidebar ? 'display_none' : ''}`}>
						<div className="sidebar_inner">
							<div className="inner_content">
								<NotDesktop><SearchPanel/></NotDesktop>
								<SidebarMiddle/>
								<SidebarBottom/>
							</div>
						</div>
						<button aria-label={translate('sidebar')}  className={`sidebar_btn icon_right ${blurEffect}`} onClick={handleClick}><span className="burger_lines"></span>
						</button>
					</div>
				</Desktop> : <div className={`sidebar_menu ${sidebar ? 'sidebar_toggle' : ''} ${isSidebar ? 'display_none' : ''}`}>
					<div className="sidebar_inner">
						<div className="inner_content">
							<NotDesktop>
								<SearchPanel/>
							</NotDesktop>
							<SidebarMiddle/>
							<SidebarBottom/>
						</div>
					</div>
					<button aria-label={translate('sidebar')}  className={`sidebar_btn icon_right ${blurEffect}`} onClick={handleClick}>
						<span className="burger_lines"></span>
					</button>
				</div>
			}
		</Fragment>


	);
};

export default Sidebar;
