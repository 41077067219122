import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ISocketInitialState, SOCKET_CONNECTION_STATUS } from './types';

const initialState: ISocketInitialState =  {
	status: SOCKET_CONNECTION_STATUS.DISCONNECTED,
	sid   : '',
};
const socket = createSlice({
	name        : 'socket',
	initialState: initialState,
	reducers    : {
		setSocketStatus: (state: Draft<ISocketInitialState>, action: PayloadAction<SOCKET_CONNECTION_STATUS>) => {
			state.status = action.payload;
		},
		setSocketSID: (state: Draft<ISocketInitialState>, action: PayloadAction<string>) => {
			state.sid = action.payload;
		},
		resetSocket: (state: Draft<ISocketInitialState>) => {
			state.status = initialState.status;
			state.sid = initialState.sid;
		},
	},
});

export const socketReducers = {
	setSocketStatus: socket.actions.setSocketStatus,
	setSocketSID   : socket.actions.setSocketSID,
	resetSocket    : socket.actions.resetSocket,
};

export default socket.reducer;