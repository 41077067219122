import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IWinner, IWinnersInitialState, IWinnerUI } from './types';

const initState: IWinnersInitialState = {
	topWinners : [],
	lastWinners: [],
	UI         : {
		loading: false,
	},
};

const winners = createSlice({
	name        : 'winners',
	initialState: initState,
	reducers    : {
		setTopWinners(state: Draft<IWinnersInitialState>, action: PayloadAction<IWinner[]>) {
			state.topWinners = action.payload;
		},
		updateTopWinners(state: Draft<IWinnersInitialState>, action: PayloadAction<IWinner[]>) {
			state.topWinners = [...action.payload, ...state.topWinners];
		},
		setLastWinners(state: Draft<IWinnersInitialState>, action: PayloadAction<IWinner[]>) {
			state.lastWinners = action.payload;
		},
		setUI(state: Draft<IWinnersInitialState>, action: PayloadAction<Partial<IWinnerUI>>) {
			const source = action.payload;
			const target = state.UI;
			state.UI = { ...target, ...source };
		},
	},
});

export const winnersReducers = {
	setTopWinners   : winners.actions.setTopWinners,
	updateTopWinners: winners.actions.updateTopWinners,
	setLastWinners  : winners.actions.setLastWinners,
	setUI           : winners.actions.setUI,
};

export default winners.reducer;