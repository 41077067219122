import { FC } from 'react';
import LazyLoad from 'react-lazyload';
import { ILazyImage } from './types';

const offset = 283; // height in one game grid, in screen 1920

const LazyImage:FC<ILazyImage> = (props) => {
	const { width='80', height='80', alt, src, offsetLazy = offset } = props;
	return (
		<LazyLoad style={{  height: '100%' }} offset={offsetLazy}>
			<img {...props} width={ width} height={height} src={src} alt={alt}  loading={'lazy'} /> 
		</LazyLoad>
	);
};

export default LazyImage;