import { fork, all, call, put } from 'redux-saga/effects';
import actions from './actions';
import { ISagaActionType } from '../../redux/types';
import { winnersReducers } from '../../redux/winners/slice';
import { IWinner } from '../../redux/winners/types';

function* updateWinners(action: ISagaActionType<IWinner[]>) {
	switch (action.type) {
		case actions.UPDATE_LAST_WINNERS_SOCKET:
			yield call(updateTopWinners, action.data);
			break;

		default:
			break;
	}
}

function* updateTopWinners(data: IWinner[]) {
	yield put(winnersReducers.updateTopWinners(data));
}

export default function* winnersSocketSaga(action: ISagaActionType<IWinner[]>) {
	yield all([
		fork(updateWinners, action),
	]);
}
