import { langToString } from '../../../translations';

import { ICategory } from '../../../redux/games/games_list/types';

export const allGamesCategory: ICategory = {
	alias          : 'all_games',
	category       : -1,
	id             : -1,
	is_deletable   : false,
	left_menu      : true,
	logo_url       : null,
	name           : langToString('all_games'),
	og_description : '',
	og_image       : '',
	og_title       : '',
	order_id       : 0,
	seo_description: '',
	seo_keywords   : '',
	seo_title      : '',
	website_id     : 9,

};