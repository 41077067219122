import React, { useState, useEffect, ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import './internetConnection.scss';


const InternetConnectionShield = <P extends object>(ComposedComponent: ComponentType<P>) => {
	const NetworkDetector: React.FC<P> = (props) => {
		const [isDisconnected, setIsDisconnected] = useState<boolean>(false);

		const handleConnectionChange = () => {
			setIsDisconnected(!navigator.onLine);
		};

		useEffect(() => {
			handleConnectionChange();
			window.addEventListener('online', handleConnectionChange);
			window.addEventListener('offline', handleConnectionChange);

			return () => {
				window.removeEventListener('online', handleConnectionChange);
				window.removeEventListener('offline', handleConnectionChange);
			};
		}, []);

		return (
			<div>
				{isDisconnected && (
					<div className={'internet_error'}>
						<p>
							<FormattedMessage id={'internet.connection.lost'} />
						</p>
					</div>
				)}
				<ComposedComponent {...props as P} />
			</div>
		);
	};

	return NetworkDetector;
};

export default InternetConnectionShield;
