import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import { IRateTopicPayload } from './types';

class HistoryAPI {
	getTopics = () => {
		const url = '/v1/content/helpcenter/topics';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		return apiRequest(config);
	};
	rateTopic = (data: IRateTopicPayload) => {
		const url = '/v1/content/helpcenter/rating';
		const config: PartialConfigs<IRateTopicPayload> = {
			method: 'post',
			url   : url,
			data  : data,
		};
		return apiRequest(config);
	};

}

export default HistoryAPI;
