import React from 'react';

const ProfilePreloader: React.FC = () => {
	return (
		<div className="profile_preloader">
			<ul className="top_preloader">
				<li><span></span></li>
				<li><span></span></li>
			</ul>
			<div className="bottom_preloader">
				<ul>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
				</ul>
				<ul>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
					<li><span></span></li>
				</ul>
			</div>
		</div>
	);
};

export default ProfilePreloader;