import { BANNER_GROUPS } from './types';

const actions = {
	GET_BANNERS: 'GET_BANNERS',
	
	getBanners: (data: BANNER_GROUPS) => {
		return {
			type: actions.GET_BANNERS,
			data,
		};
	},
};

export { actions as bannerActions };