import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import { logger } from '../../helpers/debugLogger';
import { AxiosApiResponse } from '../../service/types';
import { IRateTopicPayload, ITopicsResponse } from './types';
import { helpReducers } from './slice';
import { ISagaActionType } from '../types';


const API = new Api();

function* getTopics() {
	yield takeEvery(actions.GET_TOPICS, function* () {
		try {
			const response: AxiosApiResponse<ITopicsResponse[]> = yield call(API.getTopics);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(helpReducers.setTopics(data));
			}
		} catch (e) {
			logger.log(e);
		}
	});

}

function* rateTopic() {
	yield takeEvery(actions.RATE_TOPIC, function* (action: ISagaActionType<IRateTopicPayload>) {
		try {
			const { data } = action;
			yield call(API.rateTopic,data);

		} catch (e) {
			logger.log(e);
		}
	});

}

function* balanceSaga() {
	yield all([
		fork(getTopics),
		fork(rateTopic),
	]);
}

export default balanceSaga;
