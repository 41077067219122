import axios, { AxiosResponse } from 'axios';
import { API_URL, REACT_APP_WEBSITE_ID } from '../config';
import reduxStore from '../redux/store';
import { ILanguage } from '../redux/types';
import profileActions from '../redux/profile/actions';
import { RGErrorKey, STORAGE_KEYS } from '../helpers/constants';
import { clearData, reStoreAuthToken, restoreLocale, restoreReactiveToken } from '../helpers/localStorageUtils';
import { PartialConfigs } from './types';
import { errorHandler } from './ErrorHandler';

const instance = axios.create({
	baseURL: API_URL,
});

export const apiRequest = <TData, TParams>(config: PartialConfigs<TData, TParams>): Promise<void | AxiosResponse> => {
	const { params, ...rest } = config;
	let lang_id = 1;
	if (config.lang_id && config.withoutLangID) {
		lang_id = config.lang_id;
	} else {
		const locale: ILanguage | null = restoreLocale();
		if (locale) {
			lang_id = locale.id;
		}
	}

	const langID = !config.withoutLangID ? lang_id : null;

	const apiParams = config.params
		? {
			...params,
			website_id: REACT_APP_WEBSITE_ID,
			lang_id   : langID,
		}
		: { website_id: REACT_APP_WEBSITE_ID, lang_id: langID };

	const reactiveToken = restoreReactiveToken();
	const authToken = reStoreAuthToken();
	const conf = {
		...rest,
		withCredentials: true,
		headers        : {
			authorization: reactiveToken ?
				reactiveToken : authToken ? authToken : '',
		},
		params: apiParams,
	};
	const { dispatch } = reduxStore;

	const result = instance(conf)
		.then(function (response) {
			return response;
		})
		.catch(function (error) {
			if (!error.response) {
				throw Error('something_went_wrong');
			}

			if (!(error && error.response)) {
				return;
			}

			const { status } = error.response;

			if (status && status === 401) {
				let code = '';
				const value = restoreLocale();
				if (value) {
					code = value.code + '/';
				}
				clearData(STORAGE_KEYS.AUTH_TOKEN);
				window.location.href = `/${code}`;
			}
			// handle error
			if (error.response && error.response.data) {
				const { text, text_code } = error.response.data;
				if (text !== 'INSUFFICIENT_BALANCE' && text !== RGErrorKey && !config.hideError) {
					errorHandler(error);
				}

				if (text === RGErrorKey) {
					dispatch(profileActions.profileDataReload());
				}

				throw Error(text_code);
			}
		});
	return result;
};
