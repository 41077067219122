import { I2FAMethod } from './types';
import { modalTypeIDs } from '../../components/modals/TwoFA/utils';

export const twoFAMethods: I2FAMethod[] = [
	{
		titleID      : '2FA_item_auth_title',
		descriptionID: '2FA_item_auth_desc',
		active       : false,
		id           : 1,
	},
	{
		titleID      : '2FA_item_phone_title',
		descriptionID: '2FA_item_phone_desc',
		active       : false,
		id           : 2,
	},
];

export const methods2FA = (methods: I2FAMethod[], enable_2fa_app: boolean, enable_2fa_sms: boolean) => {
	return  methods.map((item: I2FAMethod) => {
		switch (item.id) {
			case modalTypeIDs.appModal:
				item.active = enable_2fa_app;
				return item;
			case modalTypeIDs.smsModal:
				item.active = enable_2fa_sms;
				return item;
			default:
				return item;
		}
	});
};