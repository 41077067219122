import { createSearchParams } from 'react-router-dom';
import { IProviderBase } from './types';
import { StringOrNull } from '../../types/types';
import { ICategory } from '../../redux/games/games_list/types';

/**
 * Generate query string for multiple providers
 * @param ids is the list of provider ids
 * @param current is the current provider id
 * @param list is the list of providers
 * @param categoryAlias is the alias of the selected category
 * @param categories list of all categories
 */
export const generateQueryString = <T extends IProviderBase>(ids: string[], current: StringOrNull, list: T[], categoryAlias?: string, categories?: ICategory[]): string => {
	const normalizedIds = toggleList(ids, current);
	const [location] = window.location.pathname.split('?');
	const normalizedPath =  removeEndingSlash(location);

	const checkedAliases = normalizedIds.map(providerID => {
		const provider = list.find(item => item.display_id === providerID);
		return provider?.alias || '';
	});

	const query = createSearchParams({
		provider: checkedAliases.filter(item => !!item),
	}).toString();

	if (!categoryAlias) {
		const firstCategory = categories?.[0];
		if (firstCategory) {
			return `${normalizedPath}/${firstCategory.alias}?${query}`;
		}
	}
	return `${normalizedPath}?${query}`;
};


/**
 * Toggle the list of items
 * @param list is the list of items
 * @param id is the id of the item to toggle
 */
export const toggleList = <T>(list: T[], id: T ): T[] => {
	const target = Array.from(list);
	const index = target.indexOf(id);
	if (index > -1) {
		target.splice(index, 1);
	} else {
		target.push(id);
	}
	return target;
};

export const removeEndingSlash = (string: string): string => {
	return string.replace(/\/$/, '');
};
