import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IBonusInitialState, IBonusAdapted, IBonusUI, ICasinoSpins, ICasinoBonuses } from './types';
import { fill } from '../../helpers/utils';

const initState: IBonusInitialState = {
	bonusData  : [],
	casinoSpins: {
		data     : [],
		dataCount: 0,
		UI       : {
			loading: false,
		},
	},
	casinoBonuses: {
		data     : [],
		dataCount: 0,
		UI       : {
			loading: false,
		},
	},
	UI: {
		loading  : false,
		error    : '',
		showError: false,
		page     : 1,
	},
};
export const balance = createSlice({
	name        : 'balance',
	initialState: initState,
	reducers    : {
		setBonusData(state: Draft<IBonusInitialState>, action: PayloadAction<IBonusAdapted[]>) {
			state.bonusData = action.payload;
		},
		setCasinoSpins(state: Draft<IBonusInitialState>, action: PayloadAction<Partial<Partial<ICasinoSpins>>>) {
			const source = action.payload;
			const target = state.casinoSpins;
			const result = fill(source, target);
			state.casinoSpins = result;
		},
		setCasinoBonuses(state: Draft<IBonusInitialState>, action: PayloadAction<Partial<ICasinoBonuses>>) {
			const source = action.payload;
			const target = state.casinoBonuses;
			const result = fill(source, target);
			state.casinoBonuses = result;
		},
		setUI(state: Draft<IBonusInitialState>, action: PayloadAction<Partial<IBonusUI>>) {
			const source = state.UI;
			const target = action.payload;
			const result = { ...source, ...target };
			state.UI = result;
		},
		resetBonusState() {
			return initState;
		},
	},
});


export const bonusReducers = {
	setBonusData    : balance.actions.setBonusData,
	setCasinoSpins  : balance.actions.setCasinoSpins,
	setCasinoBonuses: balance.actions.setCasinoBonuses,
	setUI           : balance.actions.setUI,
	resetBonusState : balance.actions.resetBonusState,
};

export default balance.reducer;
