import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Api from './api';
import { gamblingActions } from './actions';
import { logger } from '../../helpers/debugLogger';
import { RGamblingReducers } from './slice';
import { ITimeOutPayload, ITimeOutRequestPayload } from './types';
import { AxiosApiResponse } from '../../service/types';
import profileActions from '../profile/actions';
import { ISagaActionType } from '../types';


const API = new Api();


function* addTimeOut() {
	yield takeEvery(gamblingActions.ADD_TIME_OUT, function* (action: ISagaActionType<ITimeOutRequestPayload>) {
		yield put(RGamblingReducers.uiRefresh({ loading: true }));

		try {
			const params = {
				...action.data,
			};
			const response: AxiosApiResponse<ITimeOutPayload> = yield call(API.addTimeOut, params);
			if (response && response.status === 200) {
				yield put(RGamblingReducers.timeOutDataRefresh(response.data.data));
				yield put(profileActions.profileDataReload());
			}
		} catch (e) {
			const err = e as Error;
			const message = {
				title  : 'Error',
				message: err.message,
				type   : 'danger',
			};
			logger.log(message);
		}

		yield put(RGamblingReducers.uiRefresh({ loading: false }));
	});
}

function* getTimeOut() {
	yield takeEvery(gamblingActions.GET_TIME_OUT, function* () {
		yield put(RGamblingReducers.uiRefresh({ loading: true }));

		try {
			const response: AxiosApiResponse<ITimeOutPayload[]> = yield call(API.getTimeOut);
			if (response && response.status === 200) {
				const timeOutData = response.data.data[0];
				yield put(RGamblingReducers.timeOutDataRefresh(timeOutData));
			}
		} catch (e) {
			const err = e as Error;
			const message = {
				title  : 'Error',
				message: err.message,
				type   : 'danger',
			};
			logger.log(message);
		}

		yield put(RGamblingReducers.uiRefresh({ loading: false }));
	});
}

function* gamblingSaga() {
	yield all([
		fork(addTimeOut),
		fork(getTimeOut),
	]);
}

export default gamblingSaga;
