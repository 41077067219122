import React from 'react';

const TournamentConditionsPreloader: React.FC = () => {
	return (
		<>
			<div className="list_head_tournament">
				<span className="animate_block"></span>
			</div>
			<ul className="tournamentConditions custom_container">
                
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</>
	);
};

export default TournamentConditionsPreloader;