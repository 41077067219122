import { CHAT_VIEW_STATUS, IHelpInitialState, ITopic } from './types';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

const initialState: IHelpInitialState = {
	topics : [],
	ratings: [
		{ id: 3,name: 'negative' },
		{ id: 2,name: 'neutral' },
		{ id: 1,name: 'positive' },
	],
	term       : '',
	topicsRates: {},
	chat       : CHAT_VIEW_STATUS.CLOSE,
};

const help = createSlice({
	name    : 'Help',
	initialState,
	reducers: {
		setTopics(state: Draft<IHelpInitialState>,action: PayloadAction<ITopic[]>) {
			state.topics = action.payload;
		},
		setTerm(state: Draft<IHelpInitialState>,action: PayloadAction<string>) {
			state.term = action.payload;
		},
		setTopicsRates(state: Draft<IHelpInitialState>,action: PayloadAction<{ topic_id: number; rate_id: number }>) {
			state.topicsRates[action.payload.topic_id] = action.payload.rate_id;
		},
		setChat(state: Draft<IHelpInitialState>,action: PayloadAction<CHAT_VIEW_STATUS>) {
			state.chat = action.payload;
		},
	},
});

export const helpReducers = {
	setTerm       : help.actions.setTerm,
	setTopicsRates: help.actions.setTopicsRates,
	setTopics     : help.actions.setTopics,
	setChat       : help.actions.setChat,
};
export default help.reducer;