const HeaderPreloader = () => {
	return (
		<div className='header_preloader'>
			<div className='header_search'></div>
			<div className='profile_block'>
				<div className='circle_button'></div>
				<div className='dep_btn'></div>
				<div className='pre_header_profile'></div>
			</div>
		</div>
	);
};

export default HeaderPreloader;