import { ReactElement } from 'react';
import { NumberOrString } from '../../../types/types';

export enum TypesInput {
    phone = 'phone',
    email = 'email'
}

export interface IInputButton {
    nameButton          : string | ReactElement;
    value               : string;
    defaultValue?       : NumberOrString;
    placeholder?        : string;
    onChange            : (v: string) => void;
    onClickButton       : (v:string) => void;
    countDownButtonFunc?: (v:number) => void;
    name?               : string;
    id?                 : string;
    statusType?         : string;
    disabled?           : boolean;
    buttonDisabled?     : boolean;
    showButton?         : boolean;
    type?               : 'phone' | 'email';
    countDownDate?      : number;
}

export interface CountdownTimerProps {
    initialTime?: number;
    afterDown?  : (v:number) => void;
    checkResend?: (v: boolean) => void;
    customClass?: string;
    isResend?   : boolean;
    loading?    : boolean;
}
