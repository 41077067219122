import React from 'react';
import { FormattedMessage } from 'react-intl';
import { INoDataProps } from './types';
import LazyImage from '../LazyImage/LazyImage';

const NoData: React.FC<INoDataProps> = ({ className='no_data_block' }) => {
	return (
		<div className={className}>
			<LazyImage width="213" height="213" src="/resources/images/svg/no_data.svg" className="no_data_icon" alt="" title=""  /> 
			<div className="no_data_title"><FormattedMessage id="no_data_title" /></div>
			<div className="no_data_info"><FormattedMessage id="no_data_info"/></div>
		</div>
	);
};

export default NoData;
