import { CHANNELS } from '../settings/utils';

export interface IBannersInitialState {
    [BANNER_GROUPS.footerApps]     : IBanner[];
    [BANNER_GROUPS.footerCompanies]: IBanner[];
    [BANNER_GROUPS.footerPayments] : IBanner[];
    [BANNER_GROUPS.footerProviders]: IBanner[];
    [BANNER_GROUPS.footerSocial]   : IBanner[];
    [BANNER_GROUPS.lumos]          : IBanner[];
    [BANNER_GROUPS.sidebarBlog]    : IBanner[];
    [BANNER_GROUPS.gameList]       : IBanner[];
    [BANNER_GROUPS.promotions]     : IBanner[];
    [BANNER_GROUPS.license]        : IBanner[];
    UI                             : IUI;
}

export interface IUI {
    loading: boolean;
}

export interface IBanner {
	id            : number;
	image_url     : string;
	link          : string;
	link_text     : string;
	name          : string;
	order_id      : number;
	script_code   : string;
	sub_title     : string;
	title         : string;
	description   : string;
	view_count    : number;
	max_view_count: number;
	rotation_time?: number;
}

export interface IBannerPayload {
    section: BANNER_GROUPS;
    data   : IBanner[];
}

export interface IGetBannerPayload {
    section: BANNER_GROUPS;
    params : IGetBannerActionPayload;
}

export interface IBannersResponse {
    data: IBanner[];
}

export interface IGetBannerActionPayload {
    group_name: BANNER_GROUPS;
}

export interface IBannerParams extends IGetBannerActionPayload {
    request_from_front: boolean;
    website_id        : number;
    channel_id        : CHANNELS;

}

export enum BANNER_GROUPS {
	lumos = 'main-banners', // Main banner
	footerSocial = 'footer-socials', // footer social apps
	footerProviders = 'footer-providers', //
	footerApps = 'footer-app', //
	footerCompanies = 'footer-companies', //
	footerPayments = 'footer-payments',//
	sidebarBlog = 'sidebar-blog', // left-sidebar not used <SidebarBlog /> component
	gameList = 'game-list',
	promotions = 'promotion-banners',
	license = 'license',
}

