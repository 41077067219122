import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import NoData from '../no-data';
import Preloader from '../preloader';
import { useRootAppNavigate } from '../../hooks/useAppNavigate';
import { useAppDispatch } from '../../hooks/storeHooks';
import { modalReducers } from '../../redux/modals/slice';
import { ISearchPanelGameListProps } from './types';
import LazyImage from '../LazyImage/LazyImage';

const {
	setLoginUI,
} = modalReducers;

const GamesList: React.FC<ISearchPanelGameListProps> = (props) => {
	const { loading, data, user, resetSearch } = props;
	const navigate = useRootAppNavigate();
	const dispatch = useAppDispatch();

	if (loading){
		return <Preloader section="searchedGames"/>;
	}
	else if (!data.length && !loading){
		return <NoData/>; // here
	}

	const handlePlay = (alias: string) => {
		resetSearch();
		if (user){
			navigate(`/game/${alias}/`);
		}
		else {
			dispatch(setLoginUI({ visible: true,error: '',redirect: `/game/${alias}/` }));
		}
	};


	const Result = data.map((item,idx)=>{
		const icon = item.icon ? `icon_${item.icon}` : '';
		return (
			<li key={`list-item-${idx}`}>
				{item.thumb_url ?
					<LazyImage src={`${item.thumb_url}`} width="36" height="36" alt="" title="" /> 
					: null}
				{
					item.url ?  <Link className={`menu_item ${icon}`} to={item.url}>{item.name}</Link> : <div className={`menu_item ${icon}`}>{item.name}</div>
				}
				<button aria-label="play_btn" className="play_btn" onClick={()=>handlePlay(item.alias)}><FormattedMessage id="play"/></button>
			</li>
		);
	});


	return (
		<ul>
			{Result}
		</ul>

	);
};

export default GamesList;
