import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ICard } from '../../components/withdraw/components/PaymentMethodFields/types';
import { RootState } from '../store';
import {
	IWithdrawBaseData, IWithdrawBaseError,
	IWithdrawFailure, IWithdrawMethod, IWithdrawPaymentMethod, IWithdrawPaywaysMethod,
	IWithdrawSuccess, IWithdrawUI,
	WithdrawInitialState,
} from './types';
import { objectCloneDeep } from '../../helpers/utils';


const initialState: WithdrawInitialState = {
	withdrawMethods      : {},
	withdrawPaywayMethods: [],
	tronLinkFee          : '',
	withdraw_method      : null,
	tronLinkWithdrawError: false,
	baseData             : {
		amount : '',
		account: '',
	},
	baseError: {},
	failure  : {
		showError: false,
		errorMsg : '',
		id       : null,
	},
	success: {
		showSuccess: false,
		successMsg : '',
		id         : null,
	},
	UI: {
		loading        : false,
		loadingFee     : false,
		loadingPayways : false,
		listLoading    : false,
		favouriteAmount: 0,
	},
	cards: {},
};

const slice = createSlice({
	name    : 'withdraw',
	initialState,
	reducers: {
		setWithdrawMethods(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawMethod>) {
			state.withdrawMethods = action.payload;
		},
		setWithdrawPaywaysMethods(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawPaywaysMethod[]>) {
			state.withdrawPaywayMethods = action.payload;
		},
		setWithdrawFailure(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawFailure>) {
			state.failure = action.payload;
		},
		setWithdrawSuccess(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawSuccess>) {
			state.success = action.payload;
		},
		selectWithdrawMethod(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawPaymentMethod | null>) {
			state.withdraw_method = action.payload;
		},
		setCards(state, action: PayloadAction<{ cards: ICard[]; paymentID: number }>) {
			state.cards = { ...state.cards, [action.payload.paymentID]: action.payload.cards };
		},
		setFeeTron(state, action: PayloadAction<string>) {
			state.tronLinkFee = action.payload;
		},
		resetFeeTron(state) {
			state.tronLinkFee = '';
		},
		withdrawUiRefresh(state, action: PayloadAction<Partial<IWithdrawUI>>) {
			state.UI = Object.assign({}, state.UI, action.payload);
		},
		baseDataRefresh(state, action: PayloadAction<Partial<IWithdrawBaseData>>) {
			state.baseData = Object.assign({}, state.baseData, action.payload);
		},
		baseErrorRefresh(state, action: PayloadAction<Partial<Partial<IWithdrawBaseError>>>) {
			state.baseError = Object.assign({}, state.baseError, action.payload);
		},
		resetBase(state){
			state.baseData = objectCloneDeep(initialState.baseData);
			state.baseError = objectCloneDeep(initialState.baseError);
		},
		resetSlice(){
			return   objectCloneDeep(initialState);
		},
	},
});

export const withdrawReducers = {
	...slice.actions,
};

export const selectWithdraw = (state: RootState) => state.Withdraw;

export default slice.reducer;
