import { all, call, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import { currencyList } from '../../config';
import { adaptNotifications } from './utils';
import { logger } from '../../helpers/debugLogger';
import { RootState } from '../store';
import {
	IGetNotificationPayload,
	IMarkReadResponse,
	INotification,
	INotificationCountResponse,
	INotificationGetStore, INotificationResponse,
} from './types';
import { notificationReducers } from './slice';
import { AxiosApiResponse } from '../../service/types';
import { ISagaActionType } from '../types';

const API = new Api();

const getStore = (store: RootState): INotificationGetStore => {
	return {
		currency_code: store.Profile.profileData.currency_code,
	};
};
function* getNotificationsCount() {

	yield takeLatest(actions.GET_NOTIFICATIONS_COUNT, function* () {
		yield put(notificationReducers.setUI({ loading: true }));

		try {
			const response: AxiosApiResponse<INotificationCountResponse> = yield call(API.getNotificationsCount);

			if (response && response.status === 200) {
				const meta = {
					total : response.data.data.total,
					unread: response.data.data.unread_count,
				};
				yield put(notificationReducers.setMeta(meta));
			}
		} catch (e) {
			logger.log('e', e);
		}

		yield put(notificationReducers.setUI({ loading: false }));
	});
}

function* getNotifications() {
	yield takeLatest(actions.GET_NOTIFICATIONS, function* (action: ISagaActionType<IGetNotificationPayload>) {
		yield put(notificationReducers.setUI({ loading: true }));

		const { currency_code : currencyCode } = yield select(getStore);

		try {
			const { data } = action;
			const { payload, set = false } = data;

			yield put(actions.getNotificationsCountAction());

			const result: AxiosApiResponse<{ count: number; data: INotification[]}> = yield call(API.getNotifications, payload);
			result.data.data.data = result.data.data.data.map(item => (
				// @ts-expect-error FIXME: currencyList[currencyCode] is not guaranteed to be a string
				{ ...item, title: item.title.replace(/{{currency}}/gm, currencyList[currencyCode] || currencyCode) }
			));

			if (result && result.status === 200) {
				result.data.data.data = adaptNotifications(result.data.data.data);

				if (set) {
					yield put(notificationReducers.setNotifications(result.data.data));
				} else {
					yield put(notificationReducers.addNotificationEndList(result.data.data.data));
				}
			}
		} catch (e) {
			logger.log('e', e);
		}

		yield put(notificationReducers.setUI({ loading: false }));
	});
}

function* markRead() {

	yield takeEvery(actions.MARK_READ, function* (action: ISagaActionType<{ id: number}>) {
		const { data } = action;

		try {
			yield put(actions.updateNotificationLoadAction({ ...data, loading: true }));
			const result: AxiosApiResponse<INotificationResponse> = yield call(API.markRead, data.id);
			const normalized: INotification = {
				...result.data.data,
				loading: false,
			};
			if (result && result.status === 200) {
				const updateData = data ? result.data.data : null;
				if (updateData)
					yield put(notificationReducers.updateNotificationSeen(normalized));

			}
		} catch (e) {
			logger.log('e', e);
		}

		yield put(actions.updateNotificationLoadAction({ id: data.id, loading: false }));
	});
}

function* markAllRead() {

	yield takeLatest(actions.MARK_All_READ, function* (action: ISagaActionType<IGetNotificationPayload>) {
		yield put(notificationReducers.setUI({ markAllLoading: true }));
		try {
			const { data } = action;
			const result: AxiosApiResponse<IMarkReadResponse> = yield call(API.markRead);
			if (result && result.status === 200) {
				yield put(actions.getNotificationsAction({ payload: data.payload, set: true }));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

	yield put(notificationReducers.setUI({ markAllLoading: false }));
}


function* notificationsSaga() {
	yield all([
		fork(getNotifications),
		fork(markRead),
		fork(markAllRead),
		fork(getNotificationsCount),
	]);
}

export default notificationsSaga;
