import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import Api from './api';
import { AxiosApiResponse } from '../../service/types';
import { actions as sportsBookActions } from './slice';
import { IGetTokenActions, ISportBookData, ISportBookProvidersData } from './types';
import { logger } from '../../helpers/debugLogger';

const API: Api = new Api();
function* getToken() {

	yield takeLatest(actions.GET_TOKEN, function* ({ data }: { type: string; data: IGetTokenActions }) {
		yield put(sportsBookActions.setSportsBookUI({ loading: true }));

		try {
			const body = {
				provider_id: data.providerID,
			};

			const result: AxiosApiResponse<ISportBookData> = yield call(API.getScripts, {}, body);

			if (result && result.status === 200) {
				yield put(sportsBookActions.setSportsBookUI({ error: false }));
				yield put(sportsBookActions.setToken(result.data.data));
			}
		} catch (e) {
			yield put(sportsBookActions.setSportsBookUI({ error: true }));
			logger.log('e', e);
		}

		yield put(sportsBookActions.setSportsBookUI({ loading: false }));
	});
}

function* getSportProviderId() {

	yield takeLatest(actions.GET_SPORT_PROVIDER_ID, function* () {
		yield put(sportsBookActions.setSportsBookUI({ loading: true }));

		try {
			const result: AxiosApiResponse<ISportBookProvidersData[]>= yield call(API.getSportProviderId);

			if (result && result.status === 200) {
				yield put(sportsBookActions.setSportsBookUI({ error: false }));
				const providerID = result.data.data[0].provider_id;

				if (providerID) {
					yield put(sportsBookActions.setSportProviderId(providerID));
					yield put(actions.getScriptsAction({ providerID }));
				}
			}
		} catch (e) {
			yield put(sportsBookActions.setSportsBookUI({ error: true }));
			logger.log('e', e);
		}

		yield put(sportsBookActions.setSportsBookUI({ loading: false }));
	});
}


function* sportsBookSaga() {
	yield all([
		fork(getToken),
		fork(getSportProviderId),
	]);
}

export default sportsBookSaga;
