import { Statuses } from '../../constants/common';
import { ILanguage } from '../types';
import { LoginModalTypes, RegisterModalTypes } from '../modals/types';
import { IReferer } from '../../helpers/types';
import { DOCUMENT_TYPES, USER_DOCUMENT_STATUSES } from '../../components/profile-components/documents/types';
import { NumberOrString, StringOrNull } from '../../types/types';

export interface IProfileData {
    id                             : number;
    website_id                     : number;
    username                      ?: string;
    first_name                    ?: string | null;
    last_name                     ?: string | null;
    email                          : string;
    full_name                     ?: string;
    avatar                        ?: string;
    verification_photo            ?: string;
    roles                         ?: number[];
    state_id                      ?: NumberOrString | null;
    state_id_issue_date           ?: NumberOrString | null;
    city                          ?: string;
    country                       ?: string;
    address_1                     ?: string;
    address_2                     ?: string;
    phone                         ?: string;
    gender                         : GENDER | null;
    birth                         ?: string;
    utm                           ?: string;
    referrer                      ?: string;
    is_verified                   ?: boolean;
    state                         ?: string;
    status_id                     ?: number;
    description                   ?: string;
    balance                       ?: number;
    bonus_balance                 ?: number;
    gaming_status                 ?: number;
    time_zone                     ?: string;
    odds_format                   ?: NumberOrString | null;
    language_id                    : number | null;
    phone_contact                 ?: boolean;
    email_contact                 ?: boolean;
    receive_newsletters           ?: boolean;
    currency_id                   ?: number;
    is_online                     ?: boolean;
    currency_code                 ?: string;
    customer_id                   ?: string;
    risk_group_id                 ?: number;
    risk_group_bet_factor         ?: number;
    risk_group_max_stake          ?: number;
    risk_group_max_won            ?: number;
    bets_count                    ?: number;
    last_bet_amount               ?: number;
    bets_amount                   ?: number;
    last_bet_date                 ?: NumberOrString | null;
    last_login_date               ?: string;
    age                           ?: NumberOrString | null;
    affiliate_reference           ?: NumberOrString | null;
    facebook_id                   ?: NumberOrString | null;
    linkedin_id                   ?: NumberOrString | null;
    google_id                     ?: NumberOrString | null;
    tronlink_address              ?: StringOrNull;
    vk_id                         ?: NumberOrString | null;
    ok_id                         ?: NumberOrString | null;
    mailru_id                     ?: NumberOrString | null;
    passport_verified             ?: boolean;
    credit_card_verified          ?: boolean;
    phone_verified                ?: boolean;
    age_verified                  ?: boolean;
    email_verified                ?: boolean;
    verification_status            : VERIFICATION_STATUS;
    average_stake                 ?: number;
    turnover                      ?: number;
    deposits_amount               ?: number;
    deposits_count                ?: number;
    last_deposit_date             ?: NumberOrString | null;
    last_deposit_amount           ?: NumberOrString | null;
    withdrawal_amount             ?: number;
    withdrawal_count              ?: number;
    last_withdrawal_date          ?: NumberOrString | null;
    last_withdrawal_amount        ?: NumberOrString | null;
    profitability                 ?: number;
    firebase_registration_token   ?: NumberOrString | null;
    registration_ip               ?: string;
    clickid                       ?: NumberOrString | null;
    subid                         ?: NumberOrString | null;
    subid1                        ?: NumberOrString | null;
    subid2                        ?: NumberOrString | null;
    subid3                        ?: NumberOrString | null;
    zip_code                      ?: string;
    min_stake                     ?: number;
    min_comb                      ?: NumberOrString | null;
    max_comb                      ?: NumberOrString | null;
    last_login_country            ?: NumberOrString | null;
    pwa_uuid                      ?: NumberOrString | null;
    first_withdrawal_date         ?: NumberOrString | null;
    first_deposit_date            ?: NumberOrString | null;
    unclaimed_bonus_count         ?: number;
    partner_id                    ?: number;
    partner_ids                   ?: number[];
    website_ids                   ?: number[];
    admin_type                    ?: number;
    last_password_update_date     ?: NumberOrString | null;
    deposit_blocked               ?: boolean;
    withdrawal_blocked            ?: boolean;
    email_verify_source_id        ?: number;
    email_verify_source_type      ?: number;
    email_verify_date             ?: string;
    phone_verify_source_id        ?: number;
    phone_verify_source_type      ?: number;
    phone_verify_date             ?: string;
    age_verify_source_id          ?: NumberOrString | null;
    age_verify_source_type        ?: NumberOrString | null;
    age_verify_date               ?: NumberOrString | null;
    credit_card_verify_source_id  ?: NumberOrString | null;
    credit_card_verify_source_type?: NumberOrString | null;
    credit_card_verify_date       ?: NumberOrString | null;
    passport_verify_source_id     ?: NumberOrString | null;
    passport_verify_source_type   ?: NumberOrString | null;
    passport_verify_date          ?: NumberOrString | null;
    exclude_mass_bonus            ?: boolean;
    national_id                   ?: string ;
    hash_created_date             ?: string;
    max_stake                     ?: number;
    unread_inbox_count            ?: number;
    rg_timeout                    ?: NumberOrString;
    enable_2fa_app                 : boolean;
    enable_2fa_sms                 : boolean;
    blocked_fields                 : IProfileBLockedFields; 
}
export interface IProfileBLockedFields  {
    username   : boolean;
    first_name : boolean;
    last_name  : boolean;
    gender     : boolean;
    birth      : boolean;
    phone      : boolean;
    city       : boolean;
    address    : boolean;
    national_id: boolean;
    country    : boolean;
    zip_code   : boolean;
}

export enum GENDER {
    MALE = 1,
    FEMALE = 2,
}

export enum VERIFICATION_STATUS {
    VERIFIED = 1,
    NOT_VERIFIED = 2,
}

export interface IProfileDocs {}

export interface IProfileEditStatus  {
    hasError: boolean;
    errorMsg: { [key: string]: string };
}
export interface IProfileDocBinaryStatus {
    url?     : string;
    hasError?: boolean;
    errorMsg?: { [key: string]: string };
    loading? : boolean;
}
export interface IProfileInitState {
    profileData           : IProfileData;
    tempAvatar            : string | null;
    userLang              : number | null;
    profileDocs           : IProfileDoc[];
    authToken             : string | null;
    pendingLogout         : boolean;
    scrollIntoView        : boolean;
    open_document         : boolean;
    profileEditStatus     : IProfileEditStatus;
    profileDocBinaryStatus: IProfileDocBinaryStatus;
    passwordReseted       : boolean;
    tronlink              : ITronlinkData;
    hasMore               : boolean;
    UI                    : IProfileUI;

}

export interface IProfileUI {
    statusPhoneVerification: Statuses;
    statusEmailVerification: Statuses;
    emailResendTime        : number;
    error                  : boolean;
    pendingLogout          : boolean;
    forgetLoading          : boolean;
    loading                : boolean;
    passwordChanged        : boolean;
    isEditData             : boolean;
}
export interface ITronlinkParams {
    url        : string;
    callbackUrl: string;
    dappName   : string;
    protocol   : string;
    version    : string;
    chainId    : string;
    action     : string;
    actionId   : string;

}
export interface ITronlinkSignParams {
    url         : string;
    callbackUrl : string;
    dappIcon    : string;
    dappName    : string;
    protocol    : string;
    version     : string;
    chainId     : string;
    loginAddress: string;
    signType    : string;
    message     : string;
    action      : string;
    actionId    : string;
}
export interface ITronlinkSignTransaction {
    url         : string;
    callbackUrl : string;
    dappIcon    : string;
    dappName    : string;
    protocol    : string;
    version     : string;
    chainId     : string;
    action      : string;
    loginAddress: string;
    method      : string;
    signType    : string;
    data        : string;
    actionId    : string;
}

export interface ITronlinkUI {
    loading       : boolean;
    actionIdStatus: boolean;
    forgetLoading : boolean;
    timerID       : number ;
}

export interface ITronlinkData {
        params         : ITronlinkParams;
        signParams     : ITronlinkSignParams;
        signTransaction: ITronlinkSignTransaction;
        UI             : ITronlinkUI;
}

export interface IProfileDoc {
    id                      : number;
    url                     : string;
    ip                      : string;
    type_id                 : DOCUMENT_TYPES;
    status_id               : USER_DOCUMENT_STATUSES;
    created                 : string;
    document_number         : null;
    description             : string;
    mimetype                : string;
    decline_reason          : null;
    name                    : null;
    surname                 : null;
    nationality             : null;
    date_of_birth           : null;
    passport_expiration_date: null;
}

export interface IProfileDocAction {
    data    : IProfileDoc;
    callBack: () => void;
}


export interface FormDataInterface extends FormData {
    birth?     : string | File;
    avatar?    : string;
    currency_id: number;
    id         : number;
}

export interface IEditProfilePayload {
    data       : FormDataInterface;
    setCurrency: boolean;
    is2FA      : boolean;
}

export interface ISignUpAction  {
    currency_id      : number;
    email            : string;
    national_id      : string;
    password         : string;
    confirm_password?: string;
}

export interface IMarkReadAction {
    letterId          : number;
    messageId         : number;
    body              : { seen_by_user: boolean };
    currentLetterCount: number;
}


export interface IChangePasswordAction {
    username    : string;
    old_password: string;
    new_password: string;
}

export interface IDeactivateTronAction {
    tronLinkAddress: string;
}

export interface ITronSignData {
    action_id   : string;
    sign_message: string;
}

export interface ITronLinkDeactivate extends ITronSignData{
}
export interface ITronLoginWalletResponse extends ITronSignData{
    access_token: string;
}

export interface IPrepareTronDeactivate {
    actionId  : string;
    signedData: string;
}

export interface ITronLoginAction {
    walletID  : string;
    actionType: TRON_ACTION_TYPES;
}


export interface ITronAccount {
    account: string;
    id     : number;
    name   : string;
    amount : {
        value: string;
        error: string;
    };
    loading: boolean;
    open   : boolean;
}

export interface ITronAccountEntities {
    [key: number]: ITronAccount;
}


export interface IProfileResponse {
    id                            : number;
    website_id                    : number;
    username                      : string;
    first_name                    : string;
    last_name                     : string;
    email                         : string;
    avatar                        : null;
    verification_photo            : null;
    state_id                      : null;
    state_id_issue_date           : null;
    city                          : string;
    country                       : string;
    address_1                     : string;
    address_2                     : null;
    phone                         : null;
    gender                        : null;
    birth                         : null;
    utm                           : null;
    referrer                      : null;
    is_verified                   : boolean;
    state                         : null;
    status_id                     : number;
    description                   : null;
    balance                       : number;
    bonus_balance                 : number;
    gaming_status                 : number;
    time_zone                     : string;
    odds_format                   : null;
    language_id                   : number;
    phone_contact                 : boolean;
    email_contact                 : boolean;
    receive_newsletters           : boolean;
    currency_id                   : number;
    is_online                     : boolean;
    currency_code                 : string;
    customer_id                   : string;
    risk_group_id                 : number;
    risk_group_bet_factor         : number;
    risk_group_max_stake          : number;
    risk_group_max_won            : number;
    bets_count                    : number;
    last_bet_amount               : number;
    bets_amount                   : number;
    last_bet_date                 : null;
    last_login_date               : string;
    age                           : null;
    affiliate_reference           : null;
    facebook_id                   : null;
    linkedin_id                   : null;
    google_id                     : null;
    tronlink_address              : null;
    vk_id                         : null;
    ok_id                         : null;
    mailru_id                     : null;
    passport_verified             : boolean;
    credit_card_verified          : boolean;
    phone_verified                : boolean;
    age_verified                  : boolean;
    email_verified                : boolean;
    verification_status           : number;
    average_stake                 : number;
    turnover                      : number;
    deposits_amount               : number;
    deposits_count                : number;
    last_deposit_date             : null;
    last_deposit_amount           : null;
    withdrawal_amount             : number;
    withdrawal_count              : number;
    last_withdrawal_date          : null;
    last_withdrawal_amount        : null;
    profitability                 : number;
    firebase_registration_token   : null;
    registration_ip               : string;
    clickid                       : null;
    subid                         : null;
    subid1                        : null;
    subid2                        : null;
    subid3                        : null;
    zip_code                      : string;
    min_stake                     : number;
    min_comb                      : null;
    max_comb                      : null;
    last_login_country            : null;
    pwa_uuid                      : null;
    first_withdrawal_date         : null;
    first_deposit_date            : null;
    unclaimed_bonus_count         : number;
    partner_id                    : number;
    admin_type                    : number;
    last_password_update_date     : null;
    deposit_blocked               : boolean;
    withdrawal_blocked            : boolean;
    email_verify_source_id        : null;
    email_verify_source_type      : null;
    email_verify_date             : null;
    phone_verify_source_id        : null;
    phone_verify_source_type      : null;
    phone_verify_date             : null;
    age_verify_source_id          : null;
    age_verify_source_type        : null;
    age_verify_date               : null;
    credit_card_verify_source_id  : null;
    credit_card_verify_source_type: null;
    credit_card_verify_date       : null;
    passport_verify_source_id     : null;
    passport_verify_source_type   : null;
    passport_verify_date          : null;
    exclude_mass_bonus            : boolean;
    national_id                   : null;
    hash_created_date             : string;
    enable_2fa_app                : boolean;
    enable_2fa_sms                : boolean;
    max_stake                     : number;
    max_payout                    : number;
    rg_timeout                    : null;
}

export interface IReturnTypeofGetStore {
    languages             : ILanguage[];
    profileData           : IProfileData;
    profileDocBinaryStatus: IProfileDocBinaryStatus;
    profileDocs           : IProfileDoc[];
    Login                 : LoginModalTypes;
    Register              : RegisterModalTypes;
    sid                   : string;
    tronLoginDeepLink     : ITronlinkParams;
    tronSignDeepLink      : ITronlinkSignParams;
    API_URL               : string;
    daysFormat           ?: string;
    resendEmailTime      ?: number;

}

export interface IProfileDocData {
    description: string;
    type_id    : number;
    url        : string;
}
export interface IProfileDocBinary {
    data    : IProfileDocData;
    callBack: () => void;
}


export interface ISignInResponse {
    rg_timeout  : number | null;
    access_token: string;
    user        : IProfileData;
}

export interface ISignInData {
    email   ?: string;
    password?: string;
}

export type IEmailData = Pick<IProfileData, 'email'>;

export interface IInboxCount {
    count: number;
}

export enum SLUGS {
    verification = 'verification',
}

export interface IPreparedPhoneVerify {
    user_id: number;
    slug   : SLUGS;
}

export interface IPrepareConfirmPhone {
    code   : string;
    user_id: string;

}

export enum TRON_ACTION_TYPES {
    LOGIN = 'login',
    REGISTRATION = 'registration',
}

export interface ITronLoginPrepared {
    token_address: string;
    sign_message : string;
    sign_hex     : string;
}

export interface IParamsTronHash extends IReferer {}
