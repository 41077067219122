import React from 'react';

const HistoryPreloader: React.FC = () => {
	return (
		<ul className="table_preloader">
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
		</ul>
	);
};

export default HistoryPreloader;