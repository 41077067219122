import { Statuses } from '../../constants/common';
import { TRONLINK_DEEP_LINK_URL } from '../../helpers/constants';
import { IProfileData, ITronlinkParams, ITronlinkSignParams, ITronlinkSignTransaction } from './types';
import { PAYMENT_PROVIDERS } from '../../constants/payments';
import { REACT_APP_WEBSITE_ID } from '../../config';

const apiPath = process.env.REACT_APP_API_PATH || 'https://api.bet-makers.com/';
const paramsUrl = `${window.location.href}tronlink`;

export function getStatuses(verificationBool: boolean) {
	if (verificationBool) {
		return Statuses.success;
	}
	return Statuses.none;
}

export const tronDeepLink = (params: ITronlinkSignParams) => {
	return `${TRONLINK_DEEP_LINK_URL}${JSON.stringify(params)}`;
};


export const tronDefaultSignParams: ITronlinkSignParams = {
	url         : window.location.origin,
	callbackUrl : `${apiPath}/v1/sign/tronLink/callback/`,
	dappIcon    : `${window.location.origin}/resources/images/svg/main_logo.svg`,
	dappName    : document.title,
	protocol    : 'tronLink',
	version     : '1.0',
	chainId     : '0x2b6653dc',
	loginAddress: '',
	signType    : 'signStr',
	message     : '',
	action      : 'sign',
	actionId    : '',
};

export const tronDefaultParams: ITronlinkParams = {
	url        : paramsUrl,
	callbackUrl: `${apiPath}/v1/auth/tronLink/callback/`,
	dappName   : document.title,
	protocol   : 'tronLink',
	version    : '1.0',
	chainId    : '0x2b6653dc',
	action     : 'login',
	actionId   : '',
};

export const tronDefaultSignTransaction: ITronlinkSignTransaction = {
	url         : window.location.origin,
	callbackUrl : `https://api.bet-makers.com/v1/payments/${PAYMENT_PROVIDERS.tronlink}/interact?website_id=${REACT_APP_WEBSITE_ID}`,
	dappIcon    : `${window.location.origin}/resources/images/svg/main_logo.svg`,
	dappName    : document.title,
	protocol    : 'tronLink',
	version     : '1.0',
	chainId     : '0x2b6653dc',
	action      : 'sign',
	loginAddress: '',
	method      : 'transfer(address,uint256)',
	signType    : 'signTransaction',
	data        : '',
	actionId    : 'unique-id',
};

export const getFullName = (profileData: IProfileData) => {
	const { first_name, last_name, email, username } = profileData;
	if (!first_name && !last_name && username) {
		return username;
	} else if (!first_name && !last_name && email) {
		return email;
	} else {
		return `${first_name} ${last_name ? last_name : ''}`;
	}
};