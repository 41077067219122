import { lang } from '../../header-profile/lang';
import { IBalanceDropDown } from '../types';
import TotalBalance from './totalBalance';

export const BalanceDropDown = ({ currencyIcon, realBalance, balance, bonusBalance, className = 'totalBalanceWrapper' } : IBalanceDropDown) => {
	return (
		<div className='balanceDropDownWrapper'>
			<div className='balanceDropHeader'>
				<span>{lang.totalBalance}</span>
				<TotalBalance 
					currencyIcon={currencyIcon}
					balance={balance}
					className={className}
				/>
			</div>
			<div className='balanceDropBody'>

                
				<div className='realBalanceWrapper'>
					<div className='icon_user_balance_case'>
						<span className='currencyIcon'> {currencyIcon} </span>
					</div>
					<span className={className}> {lang.realBalance} </span>
					<div className='balancePrice'> {realBalance}</div>
				</div>
				<div className='balancePlusIcon'> + </div>
				<div className='bonusBalanceWrapper'>
					<div className='icon_user_bonus_balance'/>
						
					<span className={className}> {lang.bonusBalance} </span>
					<div className='balancePrice'> {bonusBalance}</div>
				</div>

				
			</div>
		</div>
	);
};

{/* <div className=''>

</div> */}