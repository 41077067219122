import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IInboxDocBinaryStatus,
	IInboxInitState,
	IMessage,
	IProfileUI,
} from './types';

const initialState: IInboxInitState = {
	inboxDocBinaryStatus: {
		attachments: [],
		hasError   : false,
		errorMsg   : {},
		loading    : false,
	},
	messages       : [],
	selectedMessage: null,
	hasMore        : false,
	UI             : {
		error             : false,
		loading           : false,
		getMessageLoading : false,
		getMessagesLoading: false,
	},
};

const inboxSlice = createSlice({
	name    : 'inbox',
	initialState,
	reducers: {
		inboxDocBinaryStatus(state, action: PayloadAction<IInboxDocBinaryStatus>) {
			state.inboxDocBinaryStatus = action.payload;
		},
		setMessages(state, action: PayloadAction<IMessage[]>) {
			state.messages = action.payload;
			state.hasMore = action.payload.length > 0;
		},
		setMessage(state, action: PayloadAction<IMessage | null>) {
			state.selectedMessage = action.payload;
		},
		setLetterRead(state, action: PayloadAction<{ letterId: number }>) {
			const index = state.messages.findIndex((element) => element.id === action.payload.letterId);
			if (index !== -1) {
				state.messages[index].messages[0].seen_by_user = true;
			}
		},
		UIRefresh(state, action: PayloadAction<Partial<IProfileUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		inboxReset() {
			return initialState;
		},
	},
});


export const inboxReducers = {
	...inboxSlice.actions,
};

export default inboxSlice.reducer;
