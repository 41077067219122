const actions = {
	SOCKET_RECONNECT: 'SOCKET_RECONNECT',

	reconnect: (authToken: string = '') => ({
		type: actions.SOCKET_RECONNECT,
		data: authToken,
	}),

};

export default actions;
