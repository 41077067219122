import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import { TParams, TParamsValue } from '../settings/types';
import {
	IChangePasswordAction,
	IDeactivateTronAction,
	IEmailData,
	IPrepareConfirmPhone,
	IPreparedPhoneVerify,
	IPrepareTronDeactivate,
	IProfileDocData,
	ISignInData,
	ISignUpAction,
	ITronLoginPrepared,
} from './types';

class ProfileAPI {
	getProfileData = (params: TParams = {}) => {
		const url = '/v1/profile';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
			params,
		};
		const result  = apiRequest(config);
		return result;
	};
	editProfileData = (data: FormData | object) => {
		const url = '/v1/profile';
		const config: PartialConfigs<FormData | object> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	editProfileImage = (data: FormData) => {
		const url = '/v1/upload';
		const config: PartialConfigs<FormData> = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	getBonusBalance = () => {
		const url = '/v1/profile/casino/bonus/balance';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};
	uploadDocBinary  = (data: FormData) => {
		const url = '/v1/upload';
		const config: PartialConfigs<FormData> = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	getDocs = () => {
		const url = '/v1/profile/documents';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};
	uploadDoc  = (data:IProfileDocData ) => {
		const url = '/v1/profile/documents';
		const config: PartialConfigs<IProfileDocData> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	signUp = (data: ISignUpAction) => {
		const url = '/v1/users';
		const config: PartialConfigs<ISignUpAction> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	signIn = (data: FormData | ISignInData) => {
		const url = '/v1/login';
		const config: PartialConfigs<FormData | ISignInData> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	signOut = () => {
		const url = '/v1/logout';
		const config: PartialConfigs = {
			method: 'post',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};
	forgetPassword = (data: IEmailData) => {
		const url = '/v1/forgetPassword';
		const config: PartialConfigs<IEmailData> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	resetPassword = (data: ISignInData) => {
		const url = '/v1/forgetPassword/reset';
		const config: PartialConfigs<ISignInData> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	getInboxCount = () => {
		const url = '/v1/requests/messages/count';
		const config: PartialConfigs = {
			method   : 'get',
			url      : url,
			hideError: true,
		};
		const result  = apiRequest(config);
		return result;
	};
	changePassword = (data: IChangePasswordAction) => {
		const url = '/v1/profile/changePassword';
		const config: PartialConfigs<IChangePasswordAction> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};
	deactivateAccount = (data: IDeactivateTronAction) => {
		const url = '/v1/profile/deactivate';
		const config: PartialConfigs<IDeactivateTronAction> = {
			method: 'post',
			url   : url,
			data,
		};
		const result = apiRequest(config);
		return result;
	};
	reactivateAccount = () => {
		const url = '/v1/profile/reactivate';
		const config: PartialConfigs = {
			method: 'post',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};
	sendSignedData = (data: IPrepareTronDeactivate) => {
		const url    = 'v1/profile/deactivate/tronlink/callback';
		const config: PartialConfigs<IPrepareTronDeactivate> = {
			method: 'post',
			url,
			data,
		};
		const result = apiRequest(config);
		return result;
	};
	getVerificationEmail = () => {
		const url = '/v1/userEmail/sendVerification';
		const config: PartialConfigs = {
			method: 'post',
			url,
		};
		const result = apiRequest(config);
		return result;
	};
	getVerificationPhoneNumber = (data: IPreparedPhoneVerify) => {
		const url = '/v1/userSms/sendVerification';
		const config: PartialConfigs<IPreparedPhoneVerify> = {
			method: 'post',
			url,
			data,
		};
		return apiRequest(config);
	};
	confirmPhoneNumber = (data: IPrepareConfirmPhone) => {
		const url = '/v1/userSms/verification';
		const config: PartialConfigs<IPrepareConfirmPhone> = {
			method   : 'post',
			url,
			data,
			hideError: true,
		};
		const result = apiRequest(config);
		return result;
	};
	confirmEmail = (hash: string) => {
		const url = `/v1/userEmail/sendVerification/${hash}`;
		const config: PartialConfigs = {
			method: 'get',
			url,
		};
		const result = apiRequest(config);
		return result;
	};
	sendWalletID = (id: string = '') => {
		const url = `/v1/auth/tronLink/${id}`;

		const config: PartialConfigs = {
			method: 'get',
			url,
		};
		return apiRequest(config);
	};
	sendHashedString = (data: ITronLoginPrepared, params: { [k: string]: TParamsValue }  ) => {
		const url = '/v1/auth/tronLink';

		const config: PartialConfigs<ITronLoginPrepared, { [k: string]: TParamsValue }> = {
			method: 'post',
			url,
			data,
			params: {
				params: params.data,
			},

		};
		return apiRequest(config);
	};
}

export default ProfileAPI;
