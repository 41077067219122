import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { NumberOrNull } from '../../../types/types';
import {
	ICategory,
	ICurrentGame,
	IGame,
	IGamesInitialState,
	IGameUI,
	IProvider,
	ICustomProvider,
	IGamesPagination, IGameEntities,
} from './types';

const initialState: IGamesInitialState = {
	searched_games            : null,
	search_games_x_total_count: 0,
	searched_home_games       : [],
	categories                : [],
	// custom_providers   : [],
	providers                 : [],
	search_term               : null,
	mobile_search_term        : null,
	game                      : {
		alias: '',
		demo : false,
		url  : '',
	},
	games                   : [],
	entities                : {},
	showMore                : false,
	page_number             : 1,
	data_count              : 0,
	selected_providers      : [],
	selected_category       : null,
	games_selected_providers: [],
	home_selected_providers : [],
	pagination              : {
		page   : 1,
		limit  : 24,
		hasMore: true,
	},
	UI: {
		loading              : false,
		providerLoading      : false,
		customProviderLoading: false,
		gameListLoading      : false,
		hasExitedGame        : false,
		infiniteLoading      : false,
		searchLoading        : false,
	},

};

const games = createSlice({
	name    : 'games',
	initialState,
	reducers: {
		setSearchedGames(state: Draft<IGamesInitialState>, action: PayloadAction<IGame[]>) {
			state.searched_games = action.payload;
		},
		setSearchedGamesXTotalCount(state: Draft<IGamesInitialState>, action: PayloadAction<number>) {
			state.search_games_x_total_count = action.payload;
		},
		setSearchedHomeGames(state: Draft<IGamesInitialState>, action: PayloadAction<IGame[]>) {
			state.searched_home_games = action.payload;
		},
		setCategories(state: Draft<IGamesInitialState>, action: PayloadAction<ICategory[]>) {
			state.categories = action.payload;
		},
		// setSubCategories(state: Draft<IGamesInitialState>, action: PayloadAction<ICustomProvider[]>) {
		// 	state.custom_providers = action.payload;
		// },
		setSearchTerm(state: Draft<IGamesInitialState>, action: PayloadAction<string>) {
			state.search_term = action.payload;
		},
		setMobileSearchTerm(state: Draft<IGamesInitialState>, action: PayloadAction<string>) {
			state.mobile_search_term = action.payload;
		},
		setGame(state: Draft<IGamesInitialState>, action: PayloadAction<Partial<ICurrentGame>>) {
			const source = action.payload;
			const target = state.game;
			state.game = { ...target, ...source };
		},
		setGames(state: Draft<IGamesInitialState>, action: PayloadAction<IGame[]>) {
			state.games = action.payload;
		},
		setGamesEntities(state: Draft<IGamesInitialState>, action: PayloadAction<IGameEntities>) {
			state.entities = { ...state.entities, ...action.payload };
		},
		attachGames(state: Draft<IGamesInitialState>, action: PayloadAction<IGame[]>) {
			state.games = [...state.games, ...action.payload];
		},
		resetGames(state: Draft<IGamesInitialState>) {
			state.games = [];
		},
		setProviders(state: Draft<IGamesInitialState>, action: PayloadAction<(IProvider | ICustomProvider)[]>) {
			state.providers = action.payload;
		},
		selectCategory(state: Draft<IGamesInitialState>, action: PayloadAction<NumberOrNull>) {
			state.selected_category = action.payload;
		},
		setPagination(state: Draft<IGamesInitialState>, action: PayloadAction<Partial<IGamesPagination>>) {
			const source = action.payload;
			const target = state.pagination;
			state.pagination = { ...target, ...source };
		},
		selectFirstCategory(state: Draft<IGamesInitialState>) {
			state.selected_category = state.categories[0]?.id;
		},
		toggleSelectedProviders(state: Draft<IGamesInitialState>, action: PayloadAction<string>) {
			const source = action.payload;
			const target = Array.from(state.selected_providers || []);
			const index = target.findIndex(elem => elem === source);
			if (index > -1) {
				target.splice(index, 1);
			} else {
				target.push(source);
			}
			state.selected_providers = target;
		},
		setSelectedProviders(state: Draft<IGamesInitialState>, action: PayloadAction<string[]>) {
			state.selected_providers = action.payload;
		},
		setGamesSelectedProviders(state: Draft<IGamesInitialState>, action: PayloadAction<string[]>) {
			state.games_selected_providers = action.payload;
		},
		setHomeSelectedProviders(state: Draft<IGamesInitialState>, action: PayloadAction<string[]>) {
			state.home_selected_providers = action.payload;
		},
		setShowMore(state: Draft<IGamesInitialState>, action: PayloadAction<boolean>) {
			state.showMore = action.payload;
		},
		setUI(state: Draft<IGamesInitialState>, action: PayloadAction<Partial<IGameUI>>) {
			const source = action.payload;
			const target = state.UI;
			state.UI = { ...target, ...source };
		},
		setPageNumber(state: Draft<IGamesInitialState>, action: PayloadAction<number>) {
			state.page_number = action.payload;
		},

	},
});


export const gameReducers = {
	setSearchedGames           : games.actions.setSearchedGames,
	setSearchedGamesXTotalCount: games.actions.setSearchedGamesXTotalCount,
	setSearchedHomeGames       : games.actions.setSearchedHomeGames,
	setCategories              : games.actions.setCategories,
	// setSubCategories         : games.actions.setSubCategories,
	setSearchTerm              : games.actions.setSearchTerm,
	setMobileSearchTerm        : games.actions.setMobileSearchTerm,
	setGame                    : games.actions.setGame,
	setGames                   : games.actions.setGames,
	setGamesEntities           : games.actions.setGamesEntities,
	resetGames                 : games.actions.resetGames,
	setUI                      : games.actions.setUI,
	selectCategory             : games.actions.selectCategory,
	setGamesSelectedProviders  : games.actions.setGamesSelectedProviders,
	setHomeSelectedProviders   : games.actions.setHomeSelectedProviders,
	setShowMore                : games.actions.setShowMore,
	setProviders               : games.actions.setProviders,
	toggleSelectedProviders    : games.actions.toggleSelectedProviders,
	setPageNumber              : games.actions.setPageNumber,
	selectFirstCategory        : games.actions.selectFirstCategory,
	setSelectedProviders       : games.actions.setSelectedProviders,
	attachGames                : games.actions.attachGames,
	setPagination              : games.actions.setPagination,
};
export default games.reducer;
