import { objectCloneDeep } from '../../helpers/utils';
import { IMessage, IMessageItem } from './types';

export const updateMessages = (
	messages: IMessage[],
	selectedMessage: IMessage | null,
	data: IMessageItem
): { messagesList: IMessage[]; message: IMessage | null } => {
	const clonedMessages = objectCloneDeep(messages);

	const index = clonedMessages.findIndex(message => message.id === data.request_id);

	if (index > -1) {

		clonedMessages[index] = {
			...clonedMessages[index],
			messages: [data, ...clonedMessages[index].messages],
		};

		return { messagesList: clonedMessages, message: clonedMessages[index] };
	}

	return { messagesList: clonedMessages, message: selectedMessage };
};

export const filterMessage = (messages: IMessage[], id: number): IMessage[] => {
	const clonedMessages = objectCloneDeep(messages);

	return clonedMessages.filter(message => message.id !== id);
};

export const sortMessages = (messages: IMessage[]): IMessage[] => {
	const clonedMessages = objectCloneDeep(messages);

	return clonedMessages.map(message => ({
		...message,
		messages: message.messages.sort((a, b) => (a.id < b.id ? 1 : -1)),
	}));
};

export const sortMessage = (message: IMessage): IMessage => {
	const clonedMessage = objectCloneDeep(message);

	return {
		...clonedMessage,
		messages: clonedMessage.messages.sort((a, b) => (a.id > b.id ? 1 : -1)),
	};
};