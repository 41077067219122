import { IRateTopicPayload } from './types';

const actions = {
	GET_TOPICS: 'GET_TOPICS',
	RATE_TOPIC: 'RATE_TOPIC',
	getTopics : () => {
		return {
			type: actions.GET_TOPICS,
		};
	},
	rateTopic: (data: IRateTopicPayload) => {
		return {
			type: actions.RATE_TOPIC,
			data,
		};
	},
};

export default actions;