import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import { TBonusBalancePayload } from './types';

class HistoryAPI {
	getCasinoBonuses = (params: TBonusBalancePayload) => {
		const url = '/v1/profile/casino/bonus/wallets';
		const config: PartialConfigs<void,TBonusBalancePayload> = {
			method: 'get',
			url   : url,
			params,
		};
		const result  = apiRequest(config);
		return result;
	};

}

export default HistoryAPI;
