import { all, takeEvery, fork } from 'redux-saga/effects';
import { emitEvent } from '../../sockets/socket';
import actions from './actions';
import { SOCKET_EMIT_EVENTS } from './types';
import { ISagaActionType } from '../types';

function* reconnect() {
	yield takeEvery(actions.SOCKET_RECONNECT, function*(action: ISagaActionType<string>) {
		const authToken  = action.data;
		yield emitEvent(SOCKET_EMIT_EVENTS.CHANGE_TOKEN, { token: authToken });

	});
}

export default function* socketSaga() {
	yield all([
		fork(reconnect),
	]);
}
