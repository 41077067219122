import { createSlice } from '@reduxjs/toolkit';
import { ITournamentListInitialState } from './types';

const initialState: ITournamentListInitialState ={
	tournaments: {
		active  : [],
		upcoming: [],
		recently: [],
	},
	mainTournament: {},
	UI            : {
		loading: false,
	},
};

const tournamentList = createSlice({
	name    : 'tournamentList',
	initialState,
	reducers: {
		setUI(state, action) {
			state.UI = { ...state.UI, ...action.payload };
		},
		setTournaments(state, action) {
			state.tournaments = { ...state.tournaments, ...action.payload };
		},
		setMainTournament(state, action) {
			state.mainTournament = { ...state.mainTournament, ...action.payload };
		},
		resetTournamentList() {
			return initialState;
		},
	},
});

export const tournamentListReducers = {
	setUI              : tournamentList.actions.setUI,
	setTournaments     : tournamentList.actions.setTournaments,
	setMainTournament  : tournamentList.actions.setMainTournament,
	resetTournamentList: tournamentList.actions.resetTournamentList,
};

export default tournamentList.reducer;