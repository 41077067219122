import { IPaginationMeta } from '../../../translations/types';

const actions = {
	TOURNAMENT_JOIN  : 'TOURNAMENT_JOIN',
	TOURNAMENT_RELOAD: 'TOURNAMENT_RELOAD',

	TOURNAMENT_DEFAULT_GAMES_RELOAD: 'TOURNAMENT_DEFAULT_GAMES_RELOAD',
	TOURNAMENT_MODAL_GAMES_RELOAD  : 'TOURNAMENT_MODAL_GAMES_RELOAD',
	TOURNAMENT_LEADERBOARD_RELOAD  : 'TOURNAMENT_LEADERBOARD_RELOAD',

	joinTournament: (tournamentID: number) => ({
		type: actions.TOURNAMENT_JOIN,
		data: tournamentID,
	}),
   
	getTournament: (tournamentID: number) => ({
		type: actions.TOURNAMENT_RELOAD,
		data: tournamentID,
	}),

	getTournamentDefaultGames: (tournamentID: number) => ({
		type: actions.TOURNAMENT_DEFAULT_GAMES_RELOAD,
		data: tournamentID,
	}),

	getModalGames: (tournamentID: number, params: IPaginationMeta) => ({
		type: actions.TOURNAMENT_MODAL_GAMES_RELOAD,
		data: {
			tournamentID,
			params,
		},
	}),

	getLeaderBoard: (tournamentID: number, params: IPaginationMeta) => ({
		type: actions.TOURNAMENT_LEADERBOARD_RELOAD,
		data: {
			tournamentID,
			params,
		},
	}),
};

export default actions;
