import { eventChannel } from 'redux-saga';
import subscribe from './subscribes';
import appSocket from './socket';
import { logger } from '../helpers/debugLogger';

export function watchMessages() {

	return eventChannel((emit) => {
		if (!appSocket.socket) {
			logger.log('Socket is not connected');
			return () => {
				logger.log('Socket is not connected');
			};
		}
		subscribe(appSocket.socket, emit);

		// unsubscriber
		return () => {
			logger.log('Socket off');
		};
	});
}
