import { TOURNAMENT_STATUS } from './types';

const actions = {
	TOURNAMENTS_LIST_RELOAD: 'TOURNAMENTS_LIST_RELOAD',

	getTournaments: (statusIDs: TOURNAMENT_STATUS[], isList: boolean) => ({
		type: actions.TOURNAMENTS_LIST_RELOAD,
		data: {
			statusIDs,
			isList,
		},
	}),
};


export default actions;
