export enum COMMUNICATION_CODES {
	SOCKET_DEACTIVATE_ACCOUNT   = 1331,
	SOCKET_GET_ACTION_ID_DEPOSIT= 1332,
	SOCKET_GET_DEPOSIT_STATUS   = 1335,
}


export enum TRON_SOCKET_STATUS  {
	cancelled = 3,
}
