import { apiRequest } from '../../service/service';
import { IDepositBaseData, IDoDepositArgs, ISendSignedTronTransactionPayload } from './types';
import { PartialConfigs } from '../../service/types';
import { PAYMENT_PROVIDERS } from '../../constants/payments';

class DepositAPI {
	doDeposit         = ({ method , params }: IDoDepositArgs) => {
		const url    = `/v1/payments/${method}/deposit`;
		const config: PartialConfigs<Partial<IDepositBaseData>> = {
			method: 'post',
			url   : url,
			data  : params,
		};
		return apiRequest(config);
	};
	getDepositMethods = (params = {}) => {
		const url    = '/v1/profile/deposit/payways';
		const config = {
			method: 'get',
			url   : url,
			params,
		};
		const result = apiRequest(config);
		return result;
	};
	sendSignedMessage = (data:  ISendSignedTronTransactionPayload) => {
		const url    = `v1/payments/${PAYMENT_PROVIDERS.tronlink}/interact`;
		const config: PartialConfigs< ISendSignedTronTransactionPayload> = {
			method: 'post',
			url   : url,
			data,
		};
		return apiRequest(config);

	};

	deletePaymentMethod = (cardID: string) => {
		const url    = `v1/profile/userCard/${cardID}`;
		const config: PartialConfigs = {
			method: 'delete',
			url   : url,
		};
		return apiRequest(config);

	};


}

export default DepositAPI;
