import { IGetTokenActions } from './types';


const actions = {
	GET_TOKEN            : 'GET_TOKEN',
	LOADER_UI            : 'LOADER_UI',
	GET_SPORT_PROVIDER_ID: 'GET_SPORT_PROVIDER_ID',

	getScriptsAction: (data: IGetTokenActions) => {
		return {
			type: actions.GET_TOKEN,
			data,
		};
	},

	getSportProviderIdAction: () => {
		return {
			type: actions.GET_SPORT_PROVIDER_ID,
		};
	},
};

export default actions;
