import { TBonusBalancePayload } from './types';

const actions = {
	GET_BONUS_BALANCE: 'GET_BONUS_BALANCE',
	getBonusBalance  : (data: TBonusBalancePayload) => {
		return {
			type: actions.GET_BONUS_BALANCE,
			data,
		};
	},
};

export default actions;