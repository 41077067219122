import React from 'react';

const InboxPreloader: React.FC = () => {
	return (
		<ul className="inbox_preloader">
			<li><span></span></li>
			<li><span></span></li>
			<li><span></span></li>
			<li><span></span></li>
		</ul>
	);
};

export default InboxPreloader;