import io from 'socket.io-client';
import { REACT_APP_WEBSITE_ID, SOCKET_URL } from '../config';
import { LANGUAGES } from '../constants/common';
import { getClientInfo } from '../helpers/browserHelpers';
import { reStoreAuthToken, restoreSocketAuthToken, storeOS } from '../helpers/localStorageUtils';
import { ISocketQuery } from './types';

const storedData = getStoredData();
const clientData = getClientInfo();
const websiteID = REACT_APP_WEBSITE_ID;

storeOS(clientData.os);
const socket = () => createSocket();

export type TSocket = ReturnType<typeof socket>;

export class AppSocket {
	public socket: TSocket = createSocket();
}

const appSocket  = new AppSocket();

function createSocket() {
	const query: ISocketQuery = {
		product_type: 2,
		lang_id     : storedData.langID,
		website_id  : websiteID,
		...clientData,
	};
	const socketAuthToken = restoreSocketAuthToken();
	if (storedData.authToken) {
		query.token = storedData.authToken;
	} else if ( socketAuthToken) {
		query.token = socketAuthToken;
	}

	const socket = io(SOCKET_URL, {
		transports: ['websocket'],
		query,
		timeout   : 30000,
	});

	return socket;
}


export function emitEvent<T>(event: string, data: T) {

	const emitter = new Promise((resolve, reject) => {
		try {
			if (!appSocket.socket) {
				throw new Error('Socket is not connected');
			}
			appSocket.socket.emit(event, data, (res: unknown) => {
				resolve(res);
			});
		} catch (err) {
			reject(err);
		}
	});

	return emitter;
}

// This function does not wait response from socket in opposite to emitEvent() above
export function emitEventMessage(event: string, data: unknown) {

	try {
		if (!appSocket.socket) {
			throw new Error('Socket is not connected');
		}
		appSocket.socket.emit(event, data);
		return Promise.resolve({ status: 'OK' });

	} catch (err) {
		return Promise.reject(err);
	}
}

export function createError(socketResponse?: { body?: unknown; data?: string }) {

	if (typeof socketResponse !== 'object') {
		return new Error('Socket response is not an object: ' + socketResponse);
	}

	if (socketResponse.body) {
		return new Error('Socket response error: ' + socketResponse.body);
	}

	if (socketResponse.data) {
		return new Error('Socket response error: ' + socketResponse.data);
	}

	return new Error(JSON.stringify(socketResponse));
}

function getStoredData() {

	const authToken = reStoreAuthToken();

	const data = {
		authToken,
		langID: LANGUAGES.en,
	};

	return data;
}

export default appSocket;
