import { IGame } from '../games_list/types';

export const gridGamesActions = {
	DEFAULT_GRID_LIST_RELOAD : 'DEFAULT_GRID_LIST_RELOAD',
	DEFAULT_GRID_GAMES_RELOAD: 'DEFAULT_GRID_GAMES_RELOAD',

	defaultGridReload: () => (
		{
			type: gridGamesActions.DEFAULT_GRID_LIST_RELOAD,
		}
	),


	defaultGridGamesReload: (data: IGame[]) => (
		{
			type: gridGamesActions.DEFAULT_GRID_GAMES_RELOAD,
			data,
		}
	),
};