import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { winnersReducers } from './slice';
import { logger } from '../../helpers/debugLogger';
import Api from './api';
import { AxiosApiResponse } from '../../service/types';
import action  from './actions';
import { IWinner, IWinnersGetStore } from './types';
import { RootState } from '../store';


const API = new Api();
const getStoreData = ({ Settings, Profile }: RootState): IWinnersGetStore => {
	const { channelID } = Settings;
	const currencyId = Profile.profileData.currency_id;
	return {
		channelID,
		currencyId,
	};
};

function* getTopWinners() {
	yield takeEvery(action.GET_TOP_WINNERS_LIST, function* () {
		yield put(winnersReducers.setUI({ loading: true }));
		const { channelID, currencyId }: IWinnersGetStore = yield select(getStoreData);
		
		try {
			const params = { limit: 20, channel_id: channelID, currency_id: currencyId };
			const result: AxiosApiResponse<IWinner[]> = yield call(API.getTopWinnersList, params);
			if (result && result.status === 200) {
				yield put(winnersReducers.setTopWinners(result.data.data));
			}
		} catch (e) {
			logger.log('e', e);
		} finally { 
			yield put(winnersReducers.setUI({ loading: false }));
		}
	});
}

function* getLastWinners() {
	yield takeEvery(action.GET_LAST_WINNERS_LIST, function* () {
		yield put(winnersReducers.setUI({ loading: true }));
		const { channelID, currencyId }: IWinnersGetStore = yield select(getStoreData);

		try {
			const params = { limit: 20, channel_id: channelID, currency_id: currencyId };
			const result: AxiosApiResponse<IWinner[]> = yield call(API.getLastWinnersList, params);
			if (result && result.status === 200) {
				yield put(winnersReducers.setLastWinners(result.data.data));
			}
			yield put(winnersReducers.setUI({ loading: false }));
		} catch (e) {
			yield put(winnersReducers.setUI({ loading: false }));
			logger.log('e', e);
		}
	});
}


export default function* winnersSaga() {
	yield all([
		fork(getTopWinners),
		fork(getLastWinners),
	]);
}