import { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { ClickAwayListener } from '@mui/material';
import { queryMinimumCount, queryRegExp } from '../../constants/common';
import gamesActions from '../../redux/games/games_list/actions';
import { detectMediaSize } from '../../helpers/utils';
import SearchField from '../controls/search-field/search-field';
import { profileSelectors } from '../../redux/profile/selectors';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { gamesSelectors } from '../../redux/games/games_list/selectors';
import { gameReducers } from '../../redux/games/games_list/slice';
import { menuReducers } from '../../redux/menu/slice';
import GamesList from './games-list';

import './search-panel.scss';


const {
	selectProfileData,
} = profileSelectors;
const {
	selectSearchedGames,
	selectUI,
} = gamesSelectors;

const {
	setSearchedGames,
	setSearchTerm,
} = gameReducers;
const {
	searchGamesAction,
} = gamesActions;
const {
	closeSidebar,
} = menuReducers;

const SearchPanel = () => {
	const user = useAppSelector(selectProfileData);
	const searchedGames = useAppSelector(selectSearchedGames);
	const { searchLoading } = useAppSelector(selectUI);
	const [value,setVal] = useState('');
	const [focused,setFocused] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const isPathTournament = location.pathname.includes('tournament');
	const hasValue = value.length >= queryMinimumCount && queryRegExp.test(value);
	
	const handleChange = (value: string) => {

		setVal(value);

		if (value.length >= queryMinimumCount) {
			if (!focused) {
				setFocused(!focused);
			}
			dispatch(setSearchedGames([]));
			const params = {
				name : value,
				page : 1,
				limit: 20,
			};

			if (queryRegExp.test(value)) {
				dispatch(searchGamesAction(params));
			}

		}
	};

	const resetSearch = () => {
		dispatch(setSearchTerm(''));
		dispatch(setSearchedGames([]));
		setVal('');
	};

	const showMore = () => {
		setFocused(false);
		dispatch(closeSidebar());
		document.body.classList.remove('menu_opened');
		navigate({
			pathname: 'search-result',
			search  : `?term=${value}`,
		});
	};

	const handleFocus = () => {
		const device = detectMediaSize();
		setFocused(true);
		if (device === 'new_tablet') {
			dispatch(closeSidebar());
		}
	};

	return (
		<Fragment>
			<ClickAwayListener onClickAway={() => setFocused(false)}>
				<div className={`search_block ${isPathTournament ? 'search_block_tournament' : ''}${focused && hasValue ? 'showed' : ''}`}>
					<SearchField value={value} placeholder="find_your_game" changeSearch={handleChange} resetSearch={resetSearch} onFocus={handleFocus}/>
					<div className="results_list">
						<GamesList loading={searchLoading} data={searchedGames || []} user={user} resetSearch={resetSearch}/>
						{
							searchedGames && searchedGames.length > 10 ?
								<div className="more_link"><button onClick={showMore}><FormattedMessage id="show_more"/></button></div>
								: null
						}
					</div>
				</div>
			</ClickAwayListener>
		</Fragment>
	);
};

export default SearchPanel;
