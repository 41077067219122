import { IProfileInitState } from './types';

export const profileSelectors = {
	selectProfileUI             : (state: { Profile: IProfileInitState }) => state.Profile.UI,
	selectProfileData           : (state: { Profile: IProfileInitState }) => state.Profile.profileData,
	selectTempAvatar            : (state: { Profile: IProfileInitState }) => state.Profile.tempAvatar,
	selectProfileDocs           : (state: { Profile: IProfileInitState }) => state.Profile.profileDocs,
	selectAuthToken             : (state: { Profile: IProfileInitState }) => state.Profile.authToken,
	selectPendingLogout         : (state: { Profile: IProfileInitState }) => state.Profile.pendingLogout,
	selectScrollIntoView        : (state: { Profile: IProfileInitState }) => state.Profile.scrollIntoView,
	selectProfileEditStatus     : (state: { Profile: IProfileInitState }) => state.Profile.profileEditStatus,
	selectProfileDocBinaryStatus: (state: { Profile: IProfileInitState }) => state.Profile.profileDocBinaryStatus,
	selectPasswordReseted       : (state: { Profile: IProfileInitState }) => state.Profile.passwordReseted,
	selectOpenDocument          : (state: { Profile: IProfileInitState }) => state.Profile.open_document,
	selectTronlink              : (state: { Profile: IProfileInitState }) => state.Profile.tronlink,
	selectUserLang              : (state: { Profile: IProfileInitState }) => state.Profile.userLang,
};


