import {
	INotification,
	INotificationMeta, INotificationSetPayload,
	INotificationsInitialState,
	INotificationUI,
	INotificationUpdatePayload,
} from './types';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

const initialState: INotificationsInitialState = {
	data: [],
	UI  : {
		loading        : false,
		markAllLoading : false,
		dropDownVisible: false,
		modalVisible   : false,
	},
	meta: {
		total : 0,
		unread: 0,
	},
};

export const notifications = createSlice({
	name        : 'notifications',
	initialState: initialState,
	reducers    : {
		setNotifications: (state: Draft<INotificationsInitialState>, action: PayloadAction<INotificationSetPayload>) => {
			const { data, count } = action.payload;
			state.data = data;
			state.meta.total = count;
		},
		addSingleNotification: (state: Draft<INotificationsInitialState>, action: PayloadAction<INotification>) => {
			const notification = action.payload;
			const oldList = state.data;
			state.meta.unread += 1;
			state.data = [notification, ...oldList];
		},
		addNotificationEndList: (state: Draft<INotificationsInitialState>, action: PayloadAction<INotification[]>) => {
			const notifications = action.payload;
			const oldList = state.data;
			state.data = [...oldList, ...notifications];
		},
		updateNotificationSeen: (state: Draft<INotificationsInitialState>, action: PayloadAction<INotification>) => {
			const notification = action.payload;
			const oldList = state.data;
			state.data = oldList.map((item) => {
				if (item.id === notification.message_id) {
					return {
						...item,
						seen: notification.seen,
					};
				}
				return item;
			});
			state.meta.unread -= 1;
		},
		updateNotificationLoad: (state: Draft<INotificationsInitialState>, action: PayloadAction<INotificationUpdatePayload>) => {
			const { data, loading } = action.payload;
			const oldList = state.data;
			state.data = oldList.map((item) => {
				if (item.id === data.id) {
					return {
						...item,
						loading,
					};
				}
				return item;
			});
		},
		setUI: (state: Draft<INotificationsInitialState>, action: PayloadAction<Partial<INotificationUI>>) => {
			const source = action.payload;
			const target = state.UI;
			state.UI = { ...target, ...source };
		},
		setMeta: (state: Draft<INotificationsInitialState>, action: PayloadAction<Partial<INotificationMeta>>) => {
			const source = action.payload;
			const target = state.meta;
			state.meta = { ...target, ...source };
		},
		resetNotifications: (state: Draft<INotificationsInitialState>) => {
			state.data = [];
			state.meta = {
				total : 0,
				unread: 0,
			};
		},
	},
});

export const notificationReducers = {
	setNotifications      : notifications.actions.setNotifications,
	setUI                 : notifications.actions.setUI,
	setMeta               : notifications.actions.setMeta,
	addSingleNotification : notifications.actions.addSingleNotification,
	addNotificationEndList: notifications.actions.addNotificationEndList,
	updateNotificationSeen: notifications.actions.updateNotificationSeen,
	updateNotificationLoad: notifications.actions.updateNotificationLoad,
	resetNotifications   	: notifications.actions.resetNotifications,
	
};
export default notifications.reducer;