import {
	IDoWithdrawPayload,
	IGetTronFeePayload,
	IGetWithdrawMethodsPayload,
	IGetWithdrawMethodsPaywaysPayload,
} from './types';

const actions = {
	GET_WITHDRAW_METHODS        : 'GET_WITHDRAW_METHODS',
	GET_WITHDRAW_PAYWAYS_METHODS: 'GET_WITHDRAW_PAYWAYS_METHODS',
	DO_WITHDRAW                 : 'DO_WITHDRAW',
	GET_CARDS                   : 'GET_CARDS',
	GET_FEE_TRONLINK            : 'GET_FEE_TRONLINK',
	GET_FEE_TRONLINK_STORE      : 'GET_FEE_TRONLINK_STORE',
	ERROR_FEE_TRONLINK_STORE    : 'ERROR_FEE_TRONLINK_STORE',

	getWithdrawMethods: (data: IGetWithdrawMethodsPayload) => {
		return {
			type: actions.GET_WITHDRAW_METHODS,
			data,
		};
	},
	getWithdrawMethodsPayways: (data: IGetWithdrawMethodsPaywaysPayload) => {
		return {
			type: actions.GET_WITHDRAW_PAYWAYS_METHODS,
			data,
		};
	},
	doWithdraw: (data: IDoWithdrawPayload) => {
		return {
			type: actions.DO_WITHDRAW,
			data,
		};
	},
	getCards: (paymentID: number) => {
		return {
			type: actions.GET_CARDS,
			data: paymentID,
		};
	},
	getFeeTron: (data: IGetTronFeePayload) => {
		return {
			type: actions.GET_FEE_TRONLINK,
			data,
		};
	},
	getFeeTronStore: (data: { dataFee: number }) => {
		return {
			type: actions.GET_FEE_TRONLINK_STORE,
			data,
		};
	},
};

export { actions as withdrawActions };
