import React from 'react';

const ProfileDetailsPreloader = () => {
	return (
		<ul className="profile_details_preloader">
			<li></li>
			<li></li>
			<li></li>
		</ul>
	);
};

export default ProfileDetailsPreloader;