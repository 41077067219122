const actions = {
	GET_PROMOTIONS     : 'GET_PROMOTIONS',
	GET_PROMOTION_INNER: 'GET_PROMOTION_INNER',

	getPromotions: () => {
		return {
			type: actions.GET_PROMOTIONS,
		};
	},
	getPromotionInner: (data: string) => {
		return {
			type: actions.GET_PROMOTION_INNER,
			data,
		};
	},
};

export default actions;
