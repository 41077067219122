import { fork, all, put, call } from 'redux-saga/effects';
import actions from './actions';
import { profileReducers } from '../../redux/profile/slice';
import { ISagaActionType } from '../../redux/types';
import { IUserBalanceResponse, TUserBalance, TUserBonusBalance } from '../types';

function* updateBalance(action: ISagaActionType<TUserBalance>) {
	switch (action.type) {
		case actions.SOCKET_BALANCE_UPDATE:
			yield call(updateUserBalance, action.data);
			break;

		default:
			break;
	}
}

function* updateBonusBalance(action: ISagaActionType<TUserBonusBalance>) {

	switch (action.type) {

		case actions.SOCKET_BONUS_BALANCE_UPDATE:
			yield call(updateUserBonusBalance, action.data);
			break;

		default:
			break;
	}
}

function* updateUserBalance({ data }: TUserBalance) {
	const balance = parseFloat(String(data || 0));
	yield put(profileReducers.updateBalance(balance));
}

function* updateUserBonusBalance({ balance }: TUserBonusBalance) {
	const bonus = parseFloat(String(balance || 0));
	yield put(profileReducers.setProfileBonusBalance(bonus));
}

export default function* balanceSocketSaga(action:  ISagaActionType<IUserBalanceResponse>) {
	yield all([
		fork(updateBalance, action),
		fork(updateBonusBalance, action),
	]);
}
