import { RootState } from '../../store';

export const gamesSelectors = {
	selectGames                   : (state: RootState) => state.Games.GamesList.games,
	selectGamesEntities           : (state: RootState) => state.Games.GamesList.entities,
	selectGame                    : (state: RootState) => state.Games.GamesList.game,
	selectCategories              : (state: RootState) => state.Games.GamesList.categories,
	// selectCustomProviders      : (state: RootState) => state.Games.GamesList.custom_providers,
	selectSearchTerm              : (state: RootState) => state.Games.GamesList.search_term,
	selectMobileSearchTerm        : (state: RootState) => state.Games.GamesList.mobile_search_term,
	selectSearchedGames           : (state: RootState) => state.Games.GamesList.searched_games,
	selectSearchedGamesXTotalCount: (state: RootState) => state.Games.GamesList.search_games_x_total_count,
	selectSearchedHomeGames       : (state: RootState) => state.Games.GamesList.searched_home_games,
	selectUI                      : (state: RootState) => state.Games.GamesList.UI,
	selectShowMore                : (state: RootState) => state.Games.GamesList.showMore,
	selectPageNumber              : (state: RootState) => state.Games.GamesList.page_number,
	selectDataCount               : (state: RootState) => state.Games.GamesList.data_count,
	selectSelectedCategory        : (state: RootState) => state.Games.GamesList.selected_category,
	selectProviders               : (state: RootState) => state.Games.GamesList.providers,
	selectGameSelectedProviders   : (state: RootState) => state.Games.GamesList.games_selected_providers,
	selectHomeSelectedProviders   : (state: RootState) => state.Games.GamesList.home_selected_providers,
	selectSelectedProviders       : (state: RootState) => state.Games.GamesList.selected_providers,
	selectPagination              : (state: RootState) => state.Games.GamesList.pagination,
};
