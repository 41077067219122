import { call, all, takeEvery, fork } from 'redux-saga/effects';
import { watchMessages } from './watcher';

import socketSaga from './socket/externalSaga';
import balanceSaga from './balance/externalSaga';
import updateInfoSaga from './notification/externalSaga';
import winnersSocketSaga from './winners/externalSaga';
import { EventChannel } from 'redux-saga';
import { NotUndefined } from './types';
import { ISagaActionType, ISocketSagaPayload } from '../redux/types';
//import authSaga from './auth/externalSaga';

export default function* externalSagas() {

	const channel: EventChannel<NotUndefined> = yield call(watchMessages);

	yield takeEvery(channel, function* (action: ISagaActionType<ISocketSagaPayload>) {
		yield all([
			fork(socketSaga, action),
			// @ts-expect-error FIXME: fix socket fork structure
			fork(balanceSaga, action),
			// @ts-expect-error FIXME: fix socket fork structure
			fork(updateInfoSaga, action),
			//fork(authSaga, action)
			// @ts-expect-error FIXME: fix socket fork structure
			fork(winnersSocketSaga, action),
		]);
	});
}
