import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { logger } from '../../helpers/debugLogger';
import { modalReducers } from '../modals/slice';
import Api from './api';
import inboxActions from './actions';
import profileActions from '../profile/actions';
import { inboxReducers } from './slice';
import { RootState } from '../store';
import { ISagaActionType } from '../types';
import {
	IMessage,
	IMessageItem,
	IMessagePayload,
	IMarkReadAction,
	IMessagesStateData,
	IReturnTypeofGetStore, IAttachmentFile,
} from './types';
import { AxiosApiResponse } from '../../service/types';
import { ErrorCodes } from '../../errors/ErrorCodes';
import { filterMessage, sortMessage, sortMessages, updateMessages } from './utils';
import { objectCloneDeep, parseToInteger } from '../../helpers/utils';

const API = new Api();

const getStoreData = ({ Profile, Inbox }: RootState): IReturnTypeofGetStore => {
	return {
		profileData         : Profile.profileData,
		inboxDocBinaryStatus: Inbox.inboxDocBinaryStatus,
		messages            : Inbox.messages,
		selectedMessage     : Inbox.selectedMessage,
	};
};

function* uploadInboxAttachedFiles() {
	yield takeEvery(inboxActions.UPLOAD_INBOX_FILES_BINARY, function* (action: ISagaActionType<FormData>) {
		const { inboxDocBinaryStatus }: IReturnTypeofGetStore = yield select(getStoreData);
		yield put(inboxReducers.inboxDocBinaryStatus({ attachments: inboxDocBinaryStatus.attachments, loading: true }));

		try {
			const { data }                   = action;
			const params = {
				return_info: true,
			};

			const result: AxiosApiResponse<IAttachmentFile[]>   = yield call(API.uploadDocBinary, data, params);

			if (result && result.status === 200) {
				const { data } = result.data;
				const clonedData = objectCloneDeep(inboxDocBinaryStatus);

				clonedData.attachments = clonedData.attachments.concat(data);
				yield put(inboxReducers.inboxDocBinaryStatus({ ...clonedData, loading: false }));
			}
		}
		catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
				yield put(inboxReducers.inboxDocBinaryStatus({ attachments: inboxDocBinaryStatus.attachments, loading: false }));
			}
			logger.log(e);
		}
	});
}

function* getMessages() {
	yield takeEvery(inboxActions.GET_MESSAGES, function* () {
		yield put(inboxReducers.UIRefresh({ getMessagesLoading: true }));

		try {
			const result: AxiosApiResponse<IMessage[]> = yield call(API.getMessages);

			if (result && result.status === 200) {
				const sortedMessages = sortMessages(result.data.data);

				yield put(inboxReducers.setMessages(sortedMessages));
				yield put(inboxReducers.UIRefresh({ getMessagesLoading: false }));
			}
		}
		catch (e) {
			yield put(inboxReducers.UIRefresh({ getMessagesLoading: false }));

			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}

function* getMessage() {
	yield takeEvery(inboxActions.GET_MESSAGE, function* (action: ISagaActionType<{ id: number }>) {
		const { data } = action;
		yield put(inboxReducers.UIRefresh({ getMessageLoading: true }));
		const { profileData: { unread_inbox_count } } = yield select(getStoreData);

		try {
			const result: AxiosApiResponse<IMessage> = yield call(API.getMessageById, data.id);

			if (result && result.status === 200) {
				const resultData = result.data.data;
				const messages = {
					...resultData,
					messages: resultData.messages.reverse(),
				};

				yield put(inboxReducers.setMessage(messages));
				yield put(inboxReducers.UIRefresh({ getMessageLoading: false }));
				if (unread_inbox_count) {
					yield put(profileActions.getUnreadInboxCount());
				}
			}
		}
		catch (e) {
			yield put(inboxReducers.UIRefresh({ getMessageLoading: false }));

			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}

function* markAsReadInbox() {
	yield takeEvery(inboxActions.MARK_AS_READ_INBOX, function* (action: ISagaActionType<IMessagesStateData>) {
		try {
			const { data } = action;
			// TODO: The return type is based on assumption, it should be updated
			const result : AxiosApiResponse<IMarkReadAction>  = yield call(API.inboxMarksAsRead, data);

			if (result && result.status === 200) {
				// yield put(inboxReducers.setUnreadInboxCount(data.currentLetterCount - 1));
				yield put(inboxReducers.setLetterRead({ letterId: data.letterId }));
			}
		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
	});

}

function* createMessage() {
	yield takeEvery(inboxActions.CREATE_MESSAGE, function* (action: ISagaActionType<IMessagePayload>) {
		const { data } = action;
		const { messages }: IReturnTypeofGetStore = yield select(getStoreData);
		yield put(inboxReducers.UIRefresh({ loading: true }));

		const params = {
			return_info: true,
		};

		try {
			const result: AxiosApiResponse<IMessage[]> = yield call(API.createMessage, data, params);

			if (result && result.status === 200) {
				yield put(inboxReducers.setMessages([result.data.data[0], ...messages]));
				yield put(inboxReducers.UIRefresh({ loading: false }));
				yield put(modalReducers.setInboxInnerUI({ error: '', visible: false }));
			}
		}
		catch (e) {
			yield put(inboxReducers.UIRefresh({ loading: false }));

			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}

function* replyMessage() {
	yield takeEvery(inboxActions.REPLY_MESSAGE, function* (action: ISagaActionType<IMessagePayload>) {
		const { data } = action;
		const { messages, selectedMessage }: IReturnTypeofGetStore = yield select(getStoreData);
		yield put(inboxReducers.UIRefresh({ loading: true }));

		try {
			const result: AxiosApiResponse<IMessageItem> = yield call(API.replyMessage, data);

			if (result && result.status === 200) {
				const { messagesList, message } = updateMessages(messages, selectedMessage, result.data.data);

				yield put(inboxReducers.setMessages(messagesList));

				if (message) {
					const sortedMessage = sortMessage(message);

					yield put(inboxReducers.setMessage(sortedMessage));
				}

				yield put(inboxReducers.UIRefresh({ loading: false }));
			}
		}
		catch (e) {
			yield put(inboxReducers.UIRefresh({ loading: false }));

			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}

function* deleteMessage() {
	yield takeEvery(inboxActions.DELETE_MESSAGE, function* (action: ISagaActionType<{ id: number }>) {
		const { data } = action;
		yield put(inboxReducers.UIRefresh({ getMessageLoading: true }));

		try {
			const result: AxiosApiResponse<void> = yield call(API.deleteMessage, data.id);

			if (result && result.status === 200) {
				const { messages }: IReturnTypeofGetStore = yield select(getStoreData);
				const filteredMessages = filterMessage(messages, data.id);

				yield put(inboxReducers.setMessages(filteredMessages));
				yield put(inboxReducers.UIRefresh({ getMessageLoading: false }));
				yield put(modalReducers.setConfirmModalUI({ visible: false }));
			}
		}
		catch (e) {
			yield put(inboxReducers.UIRefresh({ getMessageLoading: false }));

			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}


function* inboxSaga() {
	yield all([
		fork(uploadInboxAttachedFiles),
		fork(getMessages),
		fork(getMessage),
		fork(markAsReadInbox),
		fork(createMessage),
		fork(replyMessage),
		fork(deleteMessage),
	]);
}

export default inboxSaga;
