import React from 'react';

const BannerPreloader: React.FC = () => {
	return (
		<ul className="main_banner">
			<li><span></span></li>
		</ul> 
	);
};

export default BannerPreloader;