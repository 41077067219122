import { IUserAuthPayload } from './types';

const actions = {

	SOCKET_AUTH_LOGOUT  : 'SOCKET_AUTH_LOGOUT',
	SOCKET_AUTH_TRONLINK: 'SOCKET_AUTH_TRONLINK',
	SOCKET_AUTH_USER    : 'SOCKET_AUTH_USER',

	userLogout: () => (
		{
			type: actions.SOCKET_AUTH_LOGOUT,
		}
	),

	userLoginTronLink: <T>(data: T) => {
		return {
			type: actions.SOCKET_AUTH_TRONLINK,
			data,
		};
	},

	authUser: (data: IUserAuthPayload) => {
		return {
			type: actions.SOCKET_AUTH_USER,
			data,
		};
	},
};

export default actions;
