import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import { IGetNotificationMeta } from './types';

export default class Notifications{

	markRead = (id?: number) => {
		const url = '/v1/generalMessages';
		const config: PartialConfigs<{id?: number}> = {
			method: 'put',
			url   : url,
			data  : {
				id,
			},
		};
		const result  = apiRequest(config);
		return result;
	};

	getNotificationsCount = () => {
		const url = '/v1/generalMessages';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};

	getNotifications = (params: IGetNotificationMeta) => {
		const url = '/v1/messages';
		const config: PartialConfigs<void, IGetNotificationMeta> = {
			method: 'get',
			url   : url,
			params,
		};
		const result  = apiRequest(config);
		return result;
	};

}
