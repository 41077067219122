import { ITimeOutRequestPayload } from './types';

const actions = {
	ADD_TIME_OUT: 'ADD_TIME_OUT',
	GET_TIME_OUT: 'GET_TIME_OUT',

	addTimeOut: (data: ITimeOutRequestPayload) => {
		return {
			type: actions.ADD_TIME_OUT,
			data,
		};
	},

	getTimeOut: () => {
		return {
			type: actions.GET_TIME_OUT,
		};
	},
};

export { actions as gamblingActions };
