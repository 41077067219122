export enum ACCEPTED_FILE_TYPES {
	PDF = 'application/pdf',
	PDF2 = '.pdf',
	JPEG = 'image/jpeg',
	JPG = 'image/jpg',
	PNG = 'image/png',
	DOC = 'application/msword',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	XLS = 'application/vnd.ms-excel',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	GIF = 'image/gif',
	ZIP = 'application/x-zip-compressed',
	TXT = 'text/plain'
}

export const 	acceptedFileTypes: ACCEPTED_FILE_TYPES[] = [
	ACCEPTED_FILE_TYPES.JPEG,
	ACCEPTED_FILE_TYPES.PNG,
	ACCEPTED_FILE_TYPES.JPG,
	ACCEPTED_FILE_TYPES.JPEG,
	ACCEPTED_FILE_TYPES.PDF,
	ACCEPTED_FILE_TYPES.PDF2,
];

export enum DOCUMENT_TYPES {
	INTERNATIONAL_PASSPORT = 1,
	NATIONAL_PASSPORT = 2,
	DRIVING_LICENCE = 3,
	BILL = 4,
	OTHER = 5,
	SELFIE = 6,
}

export enum USER_DOCUMENT_STATUSES  {
	PENDING  = 1,
	APPROVED = 2,
	REJECTED = 3,
}

export const inboxAcceptedFileTypes: ACCEPTED_FILE_TYPES[] = [
	ACCEPTED_FILE_TYPES.PNG,
	ACCEPTED_FILE_TYPES.JPG,
	ACCEPTED_FILE_TYPES.JPEG,
	ACCEPTED_FILE_TYPES.PDF,
	ACCEPTED_FILE_TYPES.DOC,
	ACCEPTED_FILE_TYPES.DOCX,
	ACCEPTED_FILE_TYPES.XLS,
	ACCEPTED_FILE_TYPES.XLSX,
	ACCEPTED_FILE_TYPES.GIF,
	ACCEPTED_FILE_TYPES.ZIP,
	ACCEPTED_FILE_TYPES.TXT,
];

