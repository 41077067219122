export const modalAppPages = {
	confirmPass: 0,
	qrLink     : 1,
	success    : 2,
};

export const modalSmsPages = {
	smsPage : 0,
	codePage: 1,
	success : 2,
};
export const modalDisabledPages = {
	confirmPass: 0,
	success    : 1,
};

export const values = {
	startStep: 0,
	nextStep : 1,
	noStep   : -1,
};

export const modalTypeIDs = {
	appModal: 1,
	smsModal: 2,
};