import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import {
	IMessageBody,
	IMessagePayload,
	IMessagesStateData,
} from './types';
import { TParams } from '../settings/types';

class InboxAPI {
	uploadDocBinary  = (data: FormData, params: TParams) => {
		const url = '/v1/upload';
		const config: PartialConfigs<FormData> = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			method: 'post',
			url   : url,
			params,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};

	getMessages = () => {
		const url = '/v1/requests';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};

	getMessageById = (id: number) => {
		const url = `/v1/request/${id}`;
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};

	createMessage = (data: IMessagePayload, params: TParams) => {
		const url = '/v1/request';
		const config: PartialConfigs<IMessagePayload> = {
			method: 'post',
			url   : url,
			params,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};

	replyMessage = (data: IMessagePayload) => {
		const url = `/v1/request/${data.id}/message`;
		const config: PartialConfigs<IMessagePayload> = {
			method: 'post',
			url   : url,
			data,
		};
		const result  = apiRequest(config);
		return result;
	};

	deleteMessage = (id: number) => {
		const url = `/v1/request/${id}`;
		const config: PartialConfigs = {
			method: 'delete',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};

	inboxMarksAsRead = (data: IMessagesStateData) => {
		const url = `/v1/request/${data.letterId}/message/${data.messageId}`;

		const config: PartialConfigs<IMessageBody> = {
			method: 'put',
			url   : url,
			data  : data.body,
		};
		const result  = apiRequest(config);
		return result;
	};
}

export default InboxAPI;
