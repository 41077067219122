import {
	IActionsAddValues2FA,
	IActionsCode,
	IActionsConfirm, IActionsConfirm2FALogin,
	IActionsDisabled2FA,
	IActionSendSms, IDataConfirm2FALogin,
	IDisabledData,
	INextData,
	INextDataCodeApp,
	ISendSmsData,
	IValuesMethods2FA,
} from './types';

export const twoFAActions = {
	CONFIRM_PASS_2FA      : 'CONFIRM_PASS_2FA',
	SEND_APP_CODE_2FA     : 'SEND_APP_CODE_2FA',
	ADD_VALUES_2FA        : 'ADD_VALUES_2FA',
	SEND_SMS_2FA          : 'SEND_SMS_2FA',
	SEND_SMS_CODE_2FA     : 'SEND_SMS_CODE_2FA',
	RESEND_SMS_CODE_2FA   : 'RESEND_SMS_CODE_2FA',
	CONFIRM_PASS_2FA_LOGIN: 'CONFIRM_PASS_2FA_LOGIN',
	SEND_SMS_2FA_LOGIN    : 'SEND_SMS_2FA_LOGIN',
	DISABLED_2FA          : 'DISABLED_2FA',

	confirmPass: (data: INextData): IActionsConfirm => {
		return (
			{
				type: twoFAActions.CONFIRM_PASS_2FA,
				data,
			}
		);
	},

	sendAppCode: (data: INextDataCodeApp): IActionsCode => {
		return (
			{
				type: twoFAActions.SEND_APP_CODE_2FA,
				data,
			}
		);
	},

	send2FASms: (data: ISendSmsData): IActionSendSms => {
		return (
			{
				type: twoFAActions.SEND_SMS_2FA,
				data,
			}
		);
	},

	sendSmsCode2FA: (data: INextData): IActionsConfirm => {
		return (
			{
				type: twoFAActions.SEND_SMS_CODE_2FA,
				data,
			}
		);
	},

	getValues2FA: (data: IValuesMethods2FA): IActionsAddValues2FA => {
		return (
			{
				type: twoFAActions.ADD_VALUES_2FA,
				data,
			}
		);
	},

	resendSms: (data: INextData): IActionsConfirm => {
		return (
			{
				type: twoFAActions.RESEND_SMS_CODE_2FA,
				data,
			}
		);
	},

	confirm2FALogin: (data: IDataConfirm2FALogin): IActionsConfirm2FALogin => {
		return (
			{
				type: twoFAActions.CONFIRM_PASS_2FA_LOGIN,
				data,
			}
		);
	},

	sendSms2FALogin: () => {
		return {
			type: twoFAActions.SEND_SMS_2FA_LOGIN,
		};
	},

	disabled2FA: (data: IDisabledData): IActionsDisabled2FA => (
		{
			type: twoFAActions.DISABLED_2FA,
			data,
		}
	),
};