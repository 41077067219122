import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Path } from '@remix-run/router/history';
import { IInitRouter, IHistory } from './types';

const initialState: IInitRouter = {
	history: {
		to     : '',
		options: {},
	},
	location: {
		pathname: '',
		search  : '',
		hash    : '',
	},
	navigateEnd: false,
};

const customRouterSlice = createSlice({
	name    : 'router',
	initialState,
	reducers: {
		setHistory: (state: Draft<IInitRouter>, { payload }: PayloadAction<IHistory>) => {
			state.history = payload;
		},
		setLocation: (state: Draft<IInitRouter>, { payload }: PayloadAction<Path>) => {
			state.location = payload;
		},
		setNavigateEnd: (state: Draft<IInitRouter>, { payload }: PayloadAction<boolean>) => {
			state.navigateEnd = payload;
		},
	},
});

export const routerReducers = {
	setHistory    : customRouterSlice.actions.setHistory,
	setLocation   : customRouterSlice.actions.setLocation,
	setNavigateEnd: customRouterSlice.actions.setNavigateEnd,
};

export default customRouterSlice.reducer;
