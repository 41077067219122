import { all, fork, put, select } from 'redux-saga/effects';
import { notificationReducers } from '../../redux/notifications/slice';
import { RootState } from '../../redux/store';
import { currencyList } from '../../config';
import { profileReducers } from '../../redux/profile/slice';
import { INotification } from '../../redux/notifications/types';
import { ISagaActionType } from '../../redux/types';
import { INotificationGetStore } from './types';
import actions from './actions';
import { IProfileDoc } from '../../redux/profile/types';
import bonusActions from '../../redux/bonus/actions';
import { bonusReducers } from '../../redux/bonus/slice';
const getStoreData = (state: RootState): INotificationGetStore => {
	return {
		dropDownVisible: state.Notifications.UI.dropDownVisible,
		profileDocs    : state.Profile.profileDocs,
		currencyCode   : state.Profile.profileData.currency_code,
		meta           : state.Notifications.meta,
	};
};
function* infoUpdate(action: ISagaActionType<INotification>) {
	const { data, type } = action;

	if (type === actions.UPDATE_NOTIFICATION_SOCKET && data) {
		const { dropDownVisible, profileDocs, currencyCode, meta }: INotificationGetStore = yield select(getStoreData);

		yield put(notificationReducers.setMeta({
			total : meta.total + 1,
			unread: meta.unread + 1,
		}));
		yield put(notificationReducers.setUI({ markAllLoading: false }));

		if (dropDownVisible) {
			const regex = new RegExp('{{currency}}');
			if (regex.test(data.title) && currencyCode) { // Notification Balance adjustment case
				// @ts-expect-error FIXME: currencyList should be properly typed
				data.title = data.title.replace(/{{currency}}/g, currencyList[currencyCode] || currencyCode);
			}
			yield put(notificationReducers.addSingleNotification(data));
		}

		if (data.bonusID) {
			yield put(bonusReducers.setBonusData([]));
			yield put(bonusActions.getBonuses());
		} 

		if (data.isDoc) { // Notification Document status update.

			const updated: IProfileDoc[] = profileDocs.map(item => {
				if (item.id === data.data?.document_id && data?.data?.status_id) {
					return {
						...item,
						status_id: data?.data?.status_id,
					};
				}
				return item;
			});
			yield put(profileReducers.setProfileDocs(updated));
		}
	}

}

export default function* updateInfoSaga(action: ISagaActionType<INotification>) {
	yield all([
		fork(infoUpdate, action),
	]);
}

