import { IAcceptCookiePayload } from './types';

export const cookieActions = {
	COOKIE_IDS    : 'COOKIE_IDS',
	ACCEPT_COOKIES: 'ACCEPT_COOKIES',
	ATTACH_COOKIES: 'ATTACH_COOKIES',

	getCookieIDs: () => ({
		type: cookieActions.COOKIE_IDS,
	}),
	acceptCookies: (data: IAcceptCookiePayload) => ({
		type: cookieActions.ACCEPT_COOKIES,
		data,
	}),
	attachCookies: (data: IAcceptCookiePayload) => ({
		type: cookieActions.ATTACH_COOKIES,
		data,
	}),
};