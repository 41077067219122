
import { FormattedMessage } from 'react-intl';
import { IQualifiedConditionItem, IQualifiedConditions } from './types';
import { NumberOrNull } from '../../../types/types';

export const gamesLimit = 26;
export const boardLimit = 7;
export const GamesSliderSettings = {
	className     : 'games_slider',
	infinite      : false,
	centerPadding : '0',
	slidesToShow  : 5,
	speed         : 700,
	variableWidth : true,
	touchThreshold: 500,
	draggable     : false,
	responsive    : [
		{
			breakpoint: 768,
			settings  : {
				variableWidth: false,
				slidesToShow : 3,
			},
		},
		{
			breakpoint: 480,
			settings  : {
				arrows       : false,
				variableWidth: false,
				slidesToShow : 3,
			},
		},
	],
};


export const conditionDescriptions: IQualifiedConditions = {
	required_spins: {
		title      : 'tournament.qualifiedConditions.spins.title',
		description: 'Minimum count of total spins (sum.) in period',
		required   : 'FILL MISSING SPRINTS',
		id         : 'tournament.qualifiedConditions.spins.description',
		requiredID : 'tournament.qualifiedConditions.spins.requiredID',
	},
	required_spin_stake: {
		title      : 'tournament.qualifiedConditions.spinstake.title',
		description: 'Minimum total stake amount (sum.) in period',
		required   : 'FILL MISSING STAKES',
		id         : 'tournament.qualifiedConditions.spinstake.description',
		requiredID : 'tournament.qualifiedConditions.spinstake.requiredID',
	},
	required_deposit: {
		title      : 'tournament.qualifiedConditions.deposit.title',
		description: 'Minimum deposit (sum.) in period',
		required   : 'FILL MISSING DEPOSITS',
		id         : 'tournament.qualifiedConditions.deposit.description',
		requiredID : 'tournament.qualifiedConditions.deposit.requiredID',
	},
	required_balance: {
		title      : 'tournament.qualifiedConditions.balance.title',
		description: 'Minimum balance (sum.) in period',
		required   : 'FILL MISSING BALANCES',
		id         : 'tournament.qualifiedConditions.balance.description',
		requiredID : 'tournament.qualifiedConditions.balance.requiredID',
	},
	required_stake_count: {
		title      : 'tournament.qualifiedConditions.stake.count.title',
		description: 'Minimum stake count (sum.) in period',
		required   : 'FILL MISSING STAKE COUNT',
		id         : 'tournament.qualifiedConditions.stake.count.description',
		requiredID : 'tournament.qualifiedConditions.stake.count.requiredID',
	},
};

export const startsWithNumber = (inputString: string) => {
	const firstCharacter = inputString.charAt(0);
	return !isNaN(parseInt(firstCharacter));
};

export const reqConditions = 'required';

export const createTitle = (title: string, mainTitle: string) => (
	<FormattedMessage
		id={title}
		defaultMessage={mainTitle}
	/>
);
export const createDescription = (itemDescriptions: IQualifiedConditionItem, sum: NumberOrNull, sign: string) => (
	<FormattedMessage
		id={itemDescriptions.id}
		defaultMessage={itemDescriptions.description}
		values={{ sum, sign }}
	/>
);
export const createRequiredText = (itemDescriptions: IQualifiedConditionItem) => (
	<FormattedMessage
		id={itemDescriptions.requiredID}
		defaultMessage={itemDescriptions.required}
	/>
);