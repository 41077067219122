import socketActions from './socket/actions';
import balanceActions from './balance/actions';
import authActions from './auth/actions';
import winnersActions from './winners/actions';
import { createError, TSocket } from './socket';
import notifActions from './notification/actions';
import { logger } from '../helpers/debugLogger';
import { reStoreAuthToken } from '../helpers/localStorageUtils';
import { SubscribeCodes } from './codes';
import { SOCKET_CONNECTION_STATUS } from '../redux/socket/types';
import { EventEmitter, ISocketCreateEvent, ISocketUpdateEvent } from './types';
import { IUserTronAuthPayload } from './auth/types';

function subscribe(socket: TSocket, emit: EventEmitter) {

	// Standard Events ------------------------------------------------------------------------------
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	socket.on('connect', () => {
		logger.log('Websocket connection established');
		emit(socketActions.setConnectionStatus(SOCKET_CONNECTION_STATUS.CONNECTED));
		emit(socketActions.setSocketSid(socket.id || ''));
		emit(socketActions.emitActionIdDeactivate());
		emit(socketActions.emitActionIdDeposit());
		emit(socketActions.emitActionIdStatus());

		const socketAuthToken = reStoreAuthToken();
		if (socketAuthToken) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			socket.emit('change-token', { token: socketAuthToken }, (res: unknown) => {
				logger.log('Token changed', res);
			});
		}

	});

	socket.on('disconnect', reason => {
		logger.log('Websocket disconnected. Trying reconnect...');
		logger.log('reason : ', reason);
		if (reason === 'io server disconnect') {
			socket.connect();
		}
	});

	socket.on('reconnect', () => {
		logger.log('Websocket reconnect...');
	});

	socket.on('error', error => {
		logger.log('Connection to websocket failed with error ' + error);
		emit(socketActions.setConnectionStatus(SOCKET_CONNECTION_STATUS.DISCONNECTED));
	});

	socket.on('connect_error', () => {
		logger.log('Socket connection error...');
		emit(socketActions.setConnectionStatus(SOCKET_CONNECTION_STATUS.DISCONNECTED));
	});

	// Custom Events --------------------------------------------------------------------------------

	socket.on('update', (event: ISocketUpdateEvent) => {
		const { type, data } = event;
		if (!data) {
			// @ts-expect-error FIXME: change createError signature
			logs(createError(event), true);
			return;
		}
		const typeID = parseInt(String(type));
		switch (typeID) {
			case SubscribeCodes.USER_UPDATE_BONUS_BALANCE:
				// @ts-expect-error FIXME:
				emit(balanceActions.updateBonusBalance(data.data));
				break;

			case SubscribeCodes.USER_UPDATE_BALANCE:
				emit(balanceActions.updateBalance(data));
				break;
			case SubscribeCodes.TRONLINK_LOGIN: {
				if (data && typeof data === 'object') {
					if (data.tronlink_address && data.new_action_id ) {
						emit(authActions.userLoginTronLink<IUserTronAuthPayload>(<IUserTronAuthPayload>data));
					}
				}
				break;
			}
			case SubscribeCodes.DEACTIVATE: {
				window.location.reload();
				break;
			}
			case SubscribeCodes.LATEST_WINNERS: {
				emit(winnersActions.updateLasWinnersAction(data));
				break;
			}

			default:
				logs(`Incoming event: Unknown type ${type}`);
		}
	});


	socket.on('create', (event: ISocketCreateEvent) => {
		const { type, data } = event;

		if (!data) {
			// @ts-expect-error FIXME: change createError signature
			logs(createError(event), true);
			return;
		}

		const typeID = parseInt(String(type));

		switch (typeID) {
			case SubscribeCodes.TRONLINK_LOGIN: {
				if (data && typeof data === 'object') {
					emit(authActions.userLoginTronLink(data));
				}
				break;
			}

			case SubscribeCodes.TRONLINK_SIGN: {
				if (data && typeof data === 'object' && typeof data.data === 'object') {
					// @ts-expect-error FIXME: change authUser signature
					emit(authActions.authUser(data));
				}
				break;
			}
			case SubscribeCodes.NOTIF_BALANCE_ADJUSTMENT:
				emit(notifActions.updateNotificationActionSocket(data));
				break;

			case SubscribeCodes.NOTIF_USER_BONUS_SPIN:
				const bonusData = {
					...data,
					bonusID    : data.data?.bonus_id,
					bonusTypeID: data.data?.bonus_type_id,
					loading    : false,
				};
				emit(notifActions.updateNotificationActionSocket(bonusData));
				break;
				
			// Notifications List
			case SubscribeCodes.NOTIF_CASHBACK:
				emit(notifActions.updateNotificationActionSocket(data));
				break;
			case SubscribeCodes.NOTIF_USER_DOCUMENT_UPDATE:
				const docData = {
					...data,
					docID    : data.data?.document_id,
					docTypeID: data.data?.document_type_id,
					loading  : false,
					isDoc    : true,
				};

				emit(notifActions.updateNotificationActionSocket(docData));
				break;

			default:
				logs(`Incoming event: Unknown type ${type}`);
		}
	});


	socket.on('get', event => {
		logger.log(event, 'eventtt');
	});

	socket.on('logout', () => {
		emit(authActions.userLogout());
	});
}

function logs(message: Error | string, isError = false) {
	/*if (!showLogs) {
        return;
    }*/

	if (isError) {
		logger.log(message);
		return;
	}

	logger.log(message);
}

export default subscribe;


