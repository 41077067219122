import { FC } from 'react';
import { useRootAppNavigate } from '../../../../hooks/useAppNavigate';
import './app-navlink.scss';
import { IAppNavLinkProps } from './types';
export const AppNavLink: FC<IAppNavLinkProps> = (props) => {
	const { to, children, className, ...rest } = props;
	const navigate = useRootAppNavigate();

	const onNavigate = () => {
		navigate(to);
	};

	const calculatedClassName = className ? `app-navlink ${className}` : 'app-navlink';

	return (
		<div {...rest} className={calculatedClassName} onClick={onNavigate}>
			{children}
		</div>
	);
};