import { ReactNode } from 'react';
import { translate } from '../translations/utils';
import { parseJSON } from '../helpers/utils';
import { ModalDescription } from '../components/ModalDescription/ModalDescription';

export const normalizeUserMissingFields = (errorText: string): ReactNode => {
	if (!errorText) {
		return [''];
	}

	const [, fields] = errorText.split(':');

	if (!fields) {
		return [''];
	}

	const parsed = parseJSON<[]>(fields);

	if (!parsed) {
		return [''];
	}


	const description = parsed.map((field) => {
		return translate(`error.missing.${field}`);
	});

	return <ModalDescription
		description={description}
	/>;
};