import { apiRequest } from '../../service/service';
import { IGetModalGamesParams, IGetTournamentDefaultGamesParams, IGetTournamentParams } from './tournament/types';
import { PartialConfigs } from '../../service/types';
import { IPaginationMeta } from '../../translations/types';
import { IGetTournamentsListParams } from './tournamentsList/types';

class TournamentsAPI {

	joinTournament = (tournamentID: number) => {
		const config: PartialConfigs<{ tournament_id: number }> = {
			method: 'post',
			url   : '/v1/tournaments/join?partner_id=1',
			data  : { tournament_id: tournamentID },
		};
		return apiRequest(config);
	};

	getTournament = (tournamentID: number, params: IGetTournamentParams) => {
		const config: PartialConfigs<void,IGetTournamentParams> = {
			method: 'get',
			url   : `/v1/tournaments/${tournamentID}`,
			params,
		};
		return apiRequest(config);
	};

	getTournamentDefaultGames = (tournamentID: number, params: IGetTournamentDefaultGamesParams) => {
		const config: PartialConfigs<void, IGetTournamentDefaultGamesParams> = {
			method: 'get',
			url   : `/v1/tournaments/${tournamentID}/games`,
			params,
		};
		return apiRequest(config);
	};

	getModalGames = (tournamentID: number, params: IGetModalGamesParams ) => {
		const config: PartialConfigs<void, IGetModalGamesParams> = {
			method: 'get',
			url   : `/v1/tournaments/${tournamentID}/games/`,
			params,
		};
		return apiRequest(config);
	};

	getLeaderBoard = (tournamentID: number, params: IPaginationMeta) => {
		const config: PartialConfigs<void, IPaginationMeta> = {
			method: 'get',
			url   : `/v1/tournaments/${tournamentID}/leaderboard`,
			params,
		};
		return apiRequest(config);
	};

	getUserInfo = (tournamentID: number) => {
		const config: PartialConfigs = {
			method: 'get',
			url   : `/v1/tournaments/${tournamentID}/userInfo`,
		};
		return apiRequest(config);
	};

	// Tournaments List

	getTournamentsList = (params: IGetTournamentsListParams) => {
		const config = {
			method: 'get',
			url   : '/v1/tournaments',
			params,
		};
		return apiRequest(config);
	};
}

export default TournamentsAPI;
