import { AxiosError } from 'axios';
import { ErrorCodes } from '../errors/ErrorCodes';
import { translate } from '../translations/utils';
import store from '../redux/store';
import { modalReducers } from '../redux/modals/slice';
import { normalizeUserMissingFields } from './errorUtils';

export const errorHandler = (error: unknown): (void | string)  => {
	if (!(error instanceof Error)) {
		return '';
	}

	if (!(error instanceof AxiosError)) {
		return 'something_went_wrong';
	}

	const code = error.response?.data?.text_code;
	let messageKey = null;
	switch (code) {
		case ErrorCodes.USER_MISSING_FIELDS: {
			messageKey = normalizeUserMissingFields(error.response?.data?.text);
			break;
		}
		default: {
			messageKey = null;
			break;
		}
	}

	store.dispatch(modalReducers.setInfoUI({
		visible        : true,
		type           : 'reject',
		description    : ErrorCodes[code] || translate('something_went_wrong'),
		descriptionNode: messageKey ? messageKey : null,
	}));

};