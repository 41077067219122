import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { logger } from '../../../helpers/debugLogger';
import { AxiosApiResponse } from '../../../service/types';
import { IActionsGridGames, IDefaultGrid, IDefaultGridRes } from './types';
import { gridGamesActions } from './actions';
import { gameReducers } from '../games_list/slice';
import { gameGridReducers } from './slice';
import { RootState } from '../../store';
import GamesGrid from './api';
import {
	createDefaultGrid,
	createDefaultGridLayout,
	createGamesGridLayout,
	GAMES_GRID_MODE,
} from './utils';

const api = new GamesGrid();

const getStoreData = ({ Games, Settings }: RootState) => {
	const { 
		GamesList: { games, pagination, selected_providers },
		GamesGrid: { defaultGridLayout, defaultGrid },
	} = Games;
	const { channelID } = Settings;
	return {
		games,
		channelID,
		defaultGridLayout,
		defaultGrid,
		pagination,
		selected_providers,
	};
};

function* defaultGridListReload() {
	yield takeEvery(gridGamesActions.DEFAULT_GRID_LIST_RELOAD, function* () {
		const { channelID, pagination } = yield select(getStoreData);
		const params = {
			casino_game_id: -1,
			channel_id    : channelID,
		};

		try {
			const res: AxiosApiResponse<IDefaultGridRes[]> = yield call(api.gamesDefaultGridList, params);
			if (res.status === 200) {
				const { data } = res.data;
				// Todo: Check why we setting data length
				const limit: number = data.length > 0 ? data.length : pagination.limit;
				yield put(gameReducers.setPagination({ limit }));
				const defaultGrid: IDefaultGrid[] = createDefaultGrid(data);
				const defaultGridLayout = createDefaultGridLayout(defaultGrid);
				yield put(gameGridReducers.setDefaultGrid(defaultGrid));
				yield put(gameGridReducers.setDefaultGridLayout(defaultGridLayout));
			}

		} catch (err) {
			logger.log(err);
		}
	});
}


function* defaultGridGamesReload() {
	yield takeEvery(gridGamesActions.DEFAULT_GRID_GAMES_RELOAD, function* ({ data: games }:IActionsGridGames) {
		const { pagination, defaultGrid, selected_providers } = yield select(getStoreData);
		const { page } = pagination;
		const firstGame = games ? games[0] : null;

		const hasPosition = !!(firstGame && (firstGame.position_x  || firstGame.position_x === 0));

		const gamesGridMode =(selected_providers && selected_providers.length) || !hasPosition ? GAMES_GRID_MODE.byDefault : GAMES_GRID_MODE.byGames;
		try {
			const defaultGridPages = createGamesGridLayout(games,  defaultGrid, page, gamesGridMode);
			yield put(gameGridReducers.setDefaultGridPages({ [page]: defaultGridPages }));
		} catch (err) {
			logger.log(err);
		}
	});
}

export function* gridGamesSaga() {
	yield all([
		fork(defaultGridListReload),
		fork(defaultGridGamesReload),
	]);
}