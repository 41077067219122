import {
	ILeftMenu,
	IBottomMenu,
	IBottomMenuContent,
	IMainMenuElement,
	IMenuInitState,
	IWebsiteSiteMap, IBottomMenuUI, TABS,
} from './types';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { fill } from '../../helpers/utils';

const initState: IMenuInitState = {
	bottomMenu: {
		items  : [],
		columns: [],
		UI     : {
			notificationVisible: false,
			activeTabBottomMenu: TABS.HOME,
		},
	},
	leftMenu: {
		items  : [],
		columns: [],
	},
	bottomMenuContent: {},
	sidebar          : false,
	mainMenu         : [
		{ id: 1, name: 'games', icon: 'joystick', url: '/games/' },
		{ id: 2, name: 'live_casino', icon: 'casino', url: '/games/' },
	],
	sitemap: {
		profile        : { url: '/profile/',label: 'Profile',locale: true },
		balance        : { url: '/profile/balance/',label: 'Balance',locale: true },
		bonus          : { url: '/profile/balance/',label: 'Bonus',locale: true },
		inbox          : { url: '/profile/balance/',label: 'Inbox',locale: true },
		history        : { url: '/profile/balance/',label: 'History',locale: true },
		about_us       : { url: '/info/about_us/',label: 'About Us',locale: true },
		games          : { url: '/games/',label: 'Games',locale: true },
		promotions     : { url: '/promotions/',label: 'Promotions',locale: true },
		deposit        : { url: '/deposit/',label: 'Deposit',locale: true },
		withdraw       : { url: '/withdraw/',label: 'Withdraw',locale: true },
		info           : { url: '',label: 'Info',locale: true },
		'search-result': { url: '',label: 'search_result',locale: true },
	},
};

const menu = createSlice({
	name        : 'menu',
	initialState: initState,
	reducers    : {
		setBottomMenu(state: Draft<IMenuInitState>, payload: PayloadAction<IBottomMenu>) {
			state.bottomMenu = payload.payload;
		},
		setBottomMenuUI(state: Draft<IMenuInitState>, payload: PayloadAction<IBottomMenuUI>) {
			const target = state.bottomMenu.UI;
			state.bottomMenu.UI =fill(payload.payload, target, true);
		},
		setBottomLeftMenu(state: Draft<IMenuInitState>, payload: PayloadAction<ILeftMenu>) {
			state.leftMenu = payload.payload;
		},
		setBottomMenuContent(state: Draft<IMenuInitState>, payload: PayloadAction<IBottomMenuContent>) {
			state.bottomMenuContent = payload.payload;
		},
		setSidebar(state: Draft<IMenuInitState>, payload: PayloadAction<boolean>) {
			state.sidebar = payload.payload;
		},
		setMainMenu(state: Draft<IMenuInitState>, payload: PayloadAction<IMainMenuElement[]>) {
			state.mainMenu = payload.payload;
		},
		setSitemap(state: Draft<IMenuInitState>, payload: PayloadAction<IWebsiteSiteMap>) {
			state.sitemap = payload.payload;
		},
		closeSidebar(state: Draft<IMenuInitState>) {
			state.sidebar = false;
		},
		toggleSidebar(state: Draft<IMenuInitState>) {
			!state.sidebar && document.body.classList.remove('menu_opened');
			state.sidebar = !state.sidebar;
		},
	},
});

export const menuReducers = {
	setBottomMenu       : menu.actions.setBottomMenu,
	setBottomMenuUI     : menu.actions.setBottomMenuUI,
	setBottomLeftMenu   : menu.actions.setBottomLeftMenu,
	setBottomMenuContent: menu.actions.setBottomMenuContent,
	setSidebar          : menu.actions.setSidebar,
	setMainMenu         : menu.actions.setMainMenu,
	setSitemap          : menu.actions.setSitemap,
	toggleSidebar       : menu.actions.toggleSidebar,
	closeSidebar       	: menu.actions.closeSidebar,
};

export default menu.reducer;