import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';

export default class Menu {

	getBottomMenu = () => {
		const url = '/v1/content/menu/2';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};

	getBottomLeftMenu = () => {
		const url = '/v1/content/menu/1';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};
	getBottomMenuContent = (menuURL: string) => { // calls to get info page content
		const url = '/v1/content/informationPage';
		const config: PartialConfigs<{ url: string }> = {
			method: 'get',
			url   : url,
			params: {
				url: menuURL,
			},
		};
		const result  = apiRequest(config);
		return result;
	};
}
