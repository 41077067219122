export const LANGUAGES = {
	en: 1,
	ru: 2,
	ua: 3,
	tr: 4,
	de: 5,
	1 : 'en',
	2 : 'ru',
	3 : 'uk',
	4 : 'tr',
	5 : 'de',
};

export enum SUBCATEGORY_TYPE {
	providers       = 1,
	customProviders = 2,
}


export enum TRANSACTION_HISTORY_TOGGLE {
	DEPOSIT           = 'DEPOSIT',
	WITHDRAW          = 'WITHDRAW',
	CASHBACK          = 'CASHBACK',
	BALANCE_ADJUSTMENT= 'BALANCE_ADJUSTMENT',
	TRANSACTIONS      = 'TRANSACTIONS',
}

export const bonusStatusIDs = {
	ACTIVE   : 1,
	INACTIVE : 2,
	EXPIRED  : 3,
	FINISHED : 4,
	GRANTED  : 5,
	CLAIMED  : 6,
	CONVERTED: 7,
	DECLINED : 8,
};

export enum BONUS_STATUSES {
	ACTIVE   = 1,
	INACTIVE = 2,
	EXPIRED  = 3,
	FINISHED = 4,
	GRANTED  = 5,
	CLAIMED  = 6,
	CONVERTED= 7,
	DECLINED = 8,

}

export const queryMinimumCount = 2;
export const queryRegExp  = /^(?!\s+$).+/;

export enum Statuses {
	success= 'success',
	pending= 'pending',
	none   =    'none'
}

export enum verificationTypes {
	phone = 'phone',
	email = 'email'
}

export enum FormStatuses {
	SUCCESS = 'success',
	FAILURE = 'failure',
}

export enum BonusTypes {
	DEPOSIT_BONUS = 1,
	SPORTSBOOK_BONUS = 2,
	WELCOME = 3,
	DEPOSIT = 4,
	FREE_SPIN = 5,
	BIRTHDAY = 6,
	BONUS_SPIN = 7,
	REGISTRATION_FREE_SPIN = 8,
	BALANCE_ADJUSTMENT = 9,
	CASHBACK = 10,
	FREEBET = 11,
	DEPOSIT_FREE_SPIN = 12,
	PACK = 13,
	NEXT_DEPOSIT_FREE_SPIN = 14,
	NEXT_DEPOSIT_BONUS = 15,
	PACK_NEXT = 16,
	MANUAL_BONUS = 17
}


export enum CurrencySigns {
	USD = 1,
	UAH = 2,
	RUB = 3,
	EUR = 4,
	GBP = 6,
	KZT = 8,
	TRY = 9,
	ARS = 15,
	BTC = 37,
	UZS = 38,
	PLN = 40,
}

export type CurrencySignsType = keyof typeof CurrencySigns;

export enum GAME_BONUSES {
	DEPOSIT_FREE_SPIN = BonusTypes.DEPOSIT_FREE_SPIN,
	PACK              = BonusTypes.PACK,
	NEXT_DEPOSIT_FREE_SPIN = BonusTypes.NEXT_DEPOSIT_FREE_SPIN,
	FREE_SPIN         = BonusTypes.FREE_SPIN,
	PACK_NEXT         = BonusTypes.PACK_NEXT,
	REGISTRATION_FREE_SPIN = BonusTypes.REGISTRATION_FREE_SPIN,
}
export const GAME_AVAILABLE_BONUSES: {
	[BonusTypes.DEPOSIT_FREE_SPIN]     : BonusTypes;
	[BonusTypes.PACK]                  : BonusTypes;
	[BonusTypes.NEXT_DEPOSIT_FREE_SPIN]: BonusTypes;
	[BonusTypes.FREE_SPIN]             : BonusTypes;
	[BonusTypes.PACK_NEXT]             : BonusTypes;
	[BonusTypes.REGISTRATION_FREE_SPIN]: BonusTypes;
} = {
	[BonusTypes.FREE_SPIN]             : BonusTypes.FREE_SPIN,
	[BonusTypes.REGISTRATION_FREE_SPIN]: BonusTypes.REGISTRATION_FREE_SPIN,
	[BonusTypes.DEPOSIT_FREE_SPIN]     : BonusTypes.DEPOSIT_FREE_SPIN,
	[BonusTypes.NEXT_DEPOSIT_FREE_SPIN]: BonusTypes.NEXT_DEPOSIT_FREE_SPIN,
	[BonusTypes.PACK]                  : BonusTypes.PACK,
	[BonusTypes.PACK_NEXT]             : BonusTypes.PACK_NEXT,
};

export enum CryptoCurrencies {
	BTC = 'BTC',
	LTC = 'LTC',
	BCH = 'BCH',
	ETH = 'ETH',
	CPD = 'CPD'
}

export enum WINNERS_TOGGLE {
	TOP_WINS           = 'TOP_WINS',
	LAST_WINS          = 'LAST_WINS',
}

export const SLIDER_PER_PAGE_ITEM_COUNT = 6;