import enMessages from './locales/en.json';
import ruMessages from './locales/ru.json';
import deMessages from './locales/de.json';
import frMessages from './locales/fr.json';
import trMessages from './locales/tr.json';
import locale_en from '../translations/locales/en.json';
import locale_ru from '../translations/locales/ru.json';
import locale_de from '../translations/locales/de.json';
import locale_fr from '../translations/locales/fr.json';
import locale_tr from '../translations/locales/tr.json';
import locale_ar from '../translations/locales/ar.json';
import { ELanguageCodes, TMessages, TTranslations } from './types';

const translations: TTranslations = {
	en: locale_en,
	ru: locale_ru,
	de: locale_de,
	fr: locale_fr,
	tr: locale_tr,
	ar: locale_ar,
};

export const langToString = (id: string, code?: ELanguageCodes) => {
	const locale = code || 'en';
	const messages: TMessages = {
		en: enMessages,
		ru: ruMessages,
		de: deMessages,
		fr: frMessages,
		tr: trMessages,
		ar: locale_ar,
	};
	const lang = messages[locale];

	return lang[id];
};

export default translations;
