import { Navigate } from 'react-router';
import { useAppSelector } from '../../hooks/storeHooks';


export const RouterDistributor = () => {
	const currentLanguage = useAppSelector(state => state.Settings.current_language);
	if (currentLanguage) {
		return <Navigate to={`${currentLanguage.code}`} />;
	}
	return (
		<div/>
	);
};
