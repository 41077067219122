import { all, fork } from 'redux-saga/effects';

import tournamentSaga from './tournament/saga';
import tournamentsListSaga from './tournamentsList/saga';

export default function* rootTournamentSaga() {
	yield all([
		fork(tournamentSaga),
		fork(tournamentsListSaga),
	]);
}
