import { IGetNotificationPayload, IUpdateNotificationLoadPayload } from './types';

const actions = {
	GET_NOTIFICATIONS       : 'GET_NOTIFICATIONS',
	GET_NOTIFICATIONS_COUNT : 'GET_NOTIFICATIONS_COUNT',
	MARK_All_READ           : 'MARK_All_READ',
	MARK_READ               : 'MARK_READ',
	UPDATE_NOTIFICATION_LOAD: 'UPDATE_NOTIFICATION_LOAD',
	ADD_SINGLE_NOTIFICATIONS: 'ADD_SINGLE_NOTIFICATIONS',

	UI_REFRESH: 'UI_REFRESH',


	getNotificationsAction: (data: IGetNotificationPayload) => {
		return {
			type: actions.GET_NOTIFICATIONS,
			data,
		};
	},
	getNotificationsCountAction: () => {
		return {
			type: actions.GET_NOTIFICATIONS_COUNT,
		};
	},

	markReadAction: (data: { id: number}) => {
		return {
			type: actions.MARK_READ,
			data,
		};
	},

	updateNotificationLoadAction: (data: IUpdateNotificationLoadPayload) => {
		return {
			type: actions.UPDATE_NOTIFICATION_LOAD,
			data,
		};
	},
	markAllReadAction: (data: IGetNotificationPayload) => {
		return {
			type: actions.MARK_All_READ,
			data,
		};
	},
};

export default actions;
