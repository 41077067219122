const actions = {
	UPDATE_LAST_WINNERS_SOCKET: 'UPDATE_LAST_WINNERS_SOCKET',
	updateLasWinnersAction    : <T>(data: T) => {
		return {
			type: actions.UPDATE_LAST_WINNERS_SOCKET,
			data,
		};
	},
};

export default actions;
