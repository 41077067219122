
const actions = {

	SOCKET_BALANCE_UPDATE      : 'SOCKET_BALANCE_UPDATE',
	SOCKET_BONUS_BALANCE_UPDATE: 'SOCKET_BONUS_BALANCE_UPDATE',

	updateBalance: <T>(data: T ) => ({
		type: actions.SOCKET_BALANCE_UPDATE,
		data,
	}),

	updateBonusBalance: <T>(data: T) => ({
		type: actions.SOCKET_BONUS_BALANCE_UPDATE,
		data,
	}),
};

export default actions;
