const actions = {
	GET_BOTTOM_MENU        : 'GET_BOTTOM_MENU',
	GET_LEFT_MENU          : 'GET_LEFT_MENU',
	GET_BOTTOM_MENU_CONTENT: 'GET_BOTTOM_MENU_CONTENT',

	getLeftMenuAction: () =>{
		return {
			type: actions.GET_LEFT_MENU,
		};
	},
	getBottomMenuAction: () => {
		return {
			type: actions.GET_BOTTOM_MENU,
		};
	},
	getBottomMenuContentAction: (infoPageURL: string) => {
		return {
			type: actions.GET_BOTTOM_MENU_CONTENT,
			data: infoPageURL,
		};
	},
};

export default actions;