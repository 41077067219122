import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import { IHistoryFilters } from './types';
import { NumberOrNull } from '../../types/types';

class HistoryAPI {
	getCasinoHistory = (params: IHistoryFilters<number[] | NumberOrNull>) => {
		const url = '/v1/profile/history/casino';
		const config: PartialConfigs<void, IHistoryFilters<number[] | NumberOrNull>> = {
			method: 'get',
			url   : url,
			params,
		};
		const result = apiRequest(config);
		return result;
	};
	getDepositHistory = (params: IHistoryFilters) => {
		const url = '/v1/profile/deposits';
		const config: PartialConfigs<void, IHistoryFilters> = {
			method: 'get',
			url   : url,
			params,
		};
		const result = apiRequest(config);
		return result;
	};
	getWithdrawalsHistory = (params: IHistoryFilters) => {
		const url = '/v1/profile/withdrawals';
		const config: PartialConfigs<void,IHistoryFilters> = {
			method: 'get',
			url   : url,
			params,
		};
		const result = apiRequest(config);
		return result;
	};
	getBalanceAdjustmentHistory = (params: IHistoryFilters) => {
		const url = '/v1/profile/balanceAdjustments';
		const config: PartialConfigs<void,IHistoryFilters> = {
			method: 'get',
			url   : url,
			params,
		};
		const result = apiRequest(config);
		return result;
	};
	finishTransaction = (params : { id: number }) => {
		const url = `/v1/withdraw/finishTransaction/${params.id}`;
		const config: PartialConfigs = {
			method: 'post',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};
	getTransactionHistory = (params: IHistoryFilters) => {
		const url = '/v1/profile/transactions';
		const config: PartialConfigs<void, IHistoryFilters> = {
			method: 'get',
			url   : url,
			params,
		};
		const result = apiRequest(config);
		return result;
	};

}

export default HistoryAPI;
