import { ScriptItem } from '../settings/types';
import { SCRIPT_DISPLAY_TYPE } from '../../helpers/types';

export interface ICookieState {
	cookiesInfo: ICookie[];
	UI         : ICookieUI;

}

export enum SCRIPT_COOKIE_TYPE {
	NONE               = 1,
	STRICTLY_NECESSARY = 2,
	PERFORMANCE_COOKIE = 3,
	FUNCTIONAL_COOKIES = 4,
	TARGETING_COOKIES  = 5,
}

export interface ICookieUI {
	cookieAccept: boolean;
}

export interface ICookie {
	id         : SCRIPT_COOKIE_TYPE;
	title      : string;
	description: string;
	checked    : boolean;
	disabled   : boolean;
	isOpen     : boolean;

}


export interface IAcceptCookiePayload {
	typeCookie : SCRIPT_DISPLAY_TYPE;
	IDs       ?: number[];
}


export interface ICookieGetStore {
	cookieScripts: ScriptItem[];
	scripts      : ScriptItem[];
}