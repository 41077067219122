import React from 'react';

const TournamentBannerPreloader: React.FC = () => {
	return (
		<ul className="tournamentPrize custom_container">
			<li/>
		</ul>
	);
};

export default TournamentBannerPreloader;