import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Desktop } from '../../../helpers/devices';
import { useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';
import { BANNER_GROUPS } from '../../../redux/banners/types';
import './style.scss';
import { bannerActions } from '../../../redux/banners/actions';
import { bannersSelectors } from '../../../redux/banners/selectors';

const {
	selectAuthToken,
} = profileSelectors;
const {
	selectSidebarBlog,
} = bannersSelectors;

const SidebarBlog: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const sidebar_blog = useAppSelector(selectSidebarBlog);
	const authToken  = useAppSelector(selectAuthToken);
	const dispatch = useDispatch();

	useEffect(  ()=>{
		dispatch(bannerActions.getBanners(BANNER_GROUPS.sidebarBlog));
	},[dispatch, authToken]);

	return (
		<Desktop>
			<div />
			{/*<div className="affiliate_business">*/}
			{/*	<div className="button_block affiliate_block">*/}
			{/*		<a href="https://validator.antillephone.com/validate?domain=www.uaslots.com&seal_id=a0cac512223a2b05c5dff5630fbf7528c832e9aba40584c0c16015254b1cd0298b89edeb9ab1609c4d43ce0c98c657db&stamp=cc296cdcfabfc3f2c329f2ac965fe9a5" target='_blank' rel="noopener noreferrer">*/}
			{/*			<span className="item_inner" dangerouslySetInnerHTML={{ __html: sidebar_blog[0].script_code }}/>*/}
			{/*		</a>*/}
			{/*	</div>*/}
			{/*	<div className="button_block business_block">*/}
			{/*		<a href="https://validator.antillephone.com/validate?domain=www.uaslots.com&seal_id=a0cac512223a2b05c5dff5630fbf7528c832e9aba40584c0c16015254b1cd0298b89edeb9ab1609c4d43ce0c98c657db&stamp=cc296cdcfabfc3f2c329f2ac965fe9a5" target='_blank' rel="noopener noreferrer">*/}
			{/*			<span className="item_inner" dangerouslySetInnerHTML={{ __html: sidebar_blog[0].script_code }}/>*/}
			{/*		</a>*/}
			{/*	</div>*/}
			{/*	<div>{sidebar_blog[0].description}</div>*/}
			{/*</div>*/}
		</Desktop>
	);
};

export default SidebarBlog;
