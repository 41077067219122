import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { IDeviceProps } from './types';


const Desktop: React.FC<IDeviceProps> = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1200 });
	return isDesktop ? children : null;
};
const Tablet: React.FC<IDeviceProps> = ({ children }) => {
	const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1199 });
	return isTablet ? children : null;
};
const Mobile: React.FC<IDeviceProps> = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 575 });
	return isMobile ? children : null;
};
const NotMobile: React.FC<IDeviceProps> = ({ children }) => {
	const isNotMobile = useMediaQuery({ minWidth: 576 });
	return isNotMobile ? children : null;
};
const NotDesktop: React.FC<IDeviceProps> = ({ children }) => {
	const isNotDesktop = useMediaQuery({ maxWidth: 1199 });
	return isNotDesktop ? children : null;
};
const Default: React.FC<IDeviceProps> = ({ children }) => {
	const isDefault = useMediaQuery({ minWidth: 768 });
	return isDefault ? children : null;
};
const MinDefault: React.FC<IDeviceProps> = ({ children }) => {
	const isMinDefault = useMediaQuery({ maxWidth: 767 });
	return isMinDefault ? children : null;
};

const Device =  () => {
	const isMobile = useMediaQuery({ maxWidth: 575 });
	const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1199 });
	return isMobile ? 'mobile' : isTablet ? 'tablet' : 'web';
};

export {
	Desktop,
	Tablet,
	Mobile,
	NotMobile,
	NotDesktop,
	Default,
	MinDefault,
	Device,
};