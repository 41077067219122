import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { fill } from '../../helpers/utils';
import { I2FAMethod, I2FASlice, I2FABaseData, IUITwoFA, IValuesMethods2FA } from './types';
import { twoFAMethods } from './utils';

const initialState: I2FASlice = {
	method  : twoFAMethods,
	baseData: {
		codeSms: '',
		pass   : '',
		qrHash : '',
	},
	UI: {
		loading        : false,
		error          : false,
		active         : false,
		currentType    : 0,
		errorProfileReq: false,
		attempt        : 0,
		isAttempt      : false,
		resendTime     : 59, // default count downtime 1 minute
	},
	twoFLogin: {
		enable_2fa_app: false,
		enable_2fa_sms: false,
	},
};

export const twoFASlice = createSlice({
	name    : 'TwoFA',
	initialState,
	reducers: {
		setMethod: (state: Draft<I2FASlice>, action: PayloadAction<I2FAMethod[]>) => {
			state.method = action.payload;
		},
		setBaseData: (state: Draft<I2FASlice>, action: PayloadAction<Partial<I2FABaseData>>) => {
			const target = state.baseData;
			state.baseData = fill(action.payload, target, true);
		},
		setUI: (state: Draft<I2FASlice>, action: PayloadAction<IUITwoFA>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
		set2FALogin: (state: Draft<I2FASlice>, action: PayloadAction<IValuesMethods2FA>) => {
			state.twoFLogin = action.payload;
		},
		reset2FAData: (state: Draft<I2FASlice>) => {
			return { ...initialState, method: state.method };
		},
	},
});


export const twoFactorReducers = {
	setMethod   : twoFASlice.actions.setMethod,
	setBaseData : twoFASlice.actions.setBaseData,
	setUI       : twoFASlice.actions.setUI,
	set2FALogin : twoFASlice.actions.set2FALogin,
	reset2FAData: twoFASlice.actions.reset2FAData,
};
export default twoFASlice.reducer;

