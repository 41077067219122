import React from 'react';

const SearchedGamesPreloader: React.FC = () => {
	return (
		<ul className="results_preloader">
			<li><span></span></li>
			<li><span></span></li>
			<li><span></span></li>
		</ul>
	);
};

export default SearchedGamesPreloader;