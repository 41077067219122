import { isMobileDevice } from '../../../helpers/utils';
import { IGame } from '../games_list/types';
import { IDefaultGrid, IDefaultGridLayout, IDefaultGridRes } from './types';

const isMobile = isMobileDevice();

export const defaultSizes = {
	normal: { x: 5, y: 5, max: 36 }, // 6 x 6
	mobile: { x: 1, y: 4, max: 10 }, // 2 x 5
};

export const GAMES_WEIGHT = {
	small : 1,
	medium: 2,
	big   : 4,
};

export const GAMES_GRID_MODE = {
	byDefault: 'byDefault',
	byGames  : 'byGames',
};

export function createDefaultGrid(data: IDefaultGridRes[]): IDefaultGrid[] {

	const sizes = isMobile ? defaultSizes.mobile : defaultSizes.normal;
	const grid = [];
	if (data.length) {
		grid.length = 0;
		data.map(item => {
			grid.unshift({
				posX  : item.position_x,
				posY  : item.position_y,
				weight: item.weight,
			});
		});
	} else {
		const maxX = sizes.x;
		const maxY = sizes.y;

		for (let i = 0; i <= maxY; i++) {
			for (let j = 0; j <= maxX; j++) {
				grid.unshift({
					posX  : j,
					posY  : i,
					weight: GAMES_WEIGHT.small,
				});
			}
		}
	}

	return grid;
}

export function createDefaultGridLayout(defaultGrid: IDefaultGrid[] = []) {

	const result: IDefaultGridLayout[] = [];

	defaultGrid.forEach((gridItem) => {

		result.push({
			i: 'id-' + Date.now() + '-' + Math.floor(Math.random() * 1000),
			x: gridItem.posX,
			y: gridItem.posY,
			w: getW(gridItem.weight),
			h: getH(gridItem.weight),
		});
	});

	return result;
}


// Merge two sorted arrays into one sorted array
function merge<T>(left: T[], right: T[], keys: (keyof T)[]): T[] {
	const result: T[] = [];
	let i = 0, j = 0;

	// Compare and merge
	while (i < left.length && j < right.length) {
		let comparison = 0;

		for (const key of keys) {
			const aValue = left[i][key];
			const bValue = right[j][key];

			if (aValue < bValue) {
				comparison = -1;
				break;
			}
			if (aValue > bValue) {
				comparison = 1;
				break;
			}
		}
		// Push the smaller value to the result
		if (comparison <= 0) {
			result.push(left[i]);
			i++;
		} else {
			result.push(right[j]);
			j++;
		}
	}

	// Add remaining elements
	return result.concat(left.slice(i)).concat(right.slice(j));
}

// Recursive merge sort function
function mergeSort<T>(array: T[], keys: (keyof T)[]): T[] {
	if (array.length <= 1) return array;

	const mid = Math.floor(array.length / 2);
	const left = mergeSort(array.slice(0, mid), keys);
	const right = mergeSort(array.slice(mid), keys);

	return merge(left, right, keys);
}

function sortBy<T>(array: T[], keys: (keyof T)[]): T[] {
	return mergeSort(array, keys);
}

export function createGamesGridLayout(gamesList: IGame[], defaultGrid: IDefaultGrid[] = [], page:number = 1, gridType: string = GAMES_GRID_MODE.byDefault) {
	const result:IDefaultGridLayout[] = [];
	const sizes = isMobile ? defaultSizes.mobile : defaultSizes.normal;
	const maxY = sizes.y;
	const perPage = maxY + 1;

	if (gridType === GAMES_GRID_MODE.byDefault) {
		defaultGrid.forEach( (gridItem, index) => {

			const gameItem = gamesList[index];
			if (!gameItem) {
				return;
			}

			result.push({
				i: String(gameItem.id),
				x: gridItem.posX,
				y: gridItem.posY,
				w: getW(gridItem.weight),
				h: getH(gridItem.weight),
			});
		});

	} else {
		// const sourceGamesList = gamesList;
		const sourceGamesList = sortBy(gamesList, ['position_y', 'position_x']);
		sourceGamesList.forEach(gameItem => {
			const { id, position_x, position_y, weight } = gameItem;
			const realY = position_y - (page - 1) * perPage;

			result.push({
				i: String(id),
				x: position_x,
				y: realY,
				w: getW(weight),
				h: getH(weight),
			});
		});
	}


	return result;
}

export function getW(weight: number) {
	return (weight === GAMES_WEIGHT.small) ? 1 : 2;
}

export function getH(weight: number) {
	return (weight === GAMES_WEIGHT.big) ? 2 : 1;
}

export function getWeight(gridItem:IDefaultGridLayout) {
	if (!gridItem) {
		return GAMES_WEIGHT.small;
	}
	if (gridItem.w === 2 && gridItem.h === 2) {
		return GAMES_WEIGHT.big;
	}
	if (gridItem.w === 2 && gridItem.h === 1) {
		return GAMES_WEIGHT.medium;
	}
	return GAMES_WEIGHT.small;
}