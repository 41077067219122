import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ICookie, ICookieState, ICookieUI } from './types';
import { cookiesInfo } from './utils';

const initState: ICookieState = {
	cookiesInfo: cookiesInfo,
	UI         : {
		cookieAccept: true,
	},
};
const cookie = createSlice({
	initialState: initState,
	name        : 'cookie',
	reducers    : {
		setCookieInfo(state: Draft<ICookieState>, action: PayloadAction<ICookie[]>) {
			state.cookiesInfo = action.payload;
		},
		setCookieInfoOpen(state: Draft<ICookieState>, action: PayloadAction<number>) {
			const data = Array.from(state.cookiesInfo);
			const res = data.map(item => {
				if (item.id === action.payload) {
					item.isOpen = !item.isOpen;
				}
				return item;
			});
			state.cookiesInfo = res;
		},
		setUI(state: Draft<ICookieState>, action: PayloadAction<Partial<Partial<ICookieUI>>>) {
			const source = state.UI;
			const target = action.payload;
			state.UI = { ...source, ...target };
		},
	},
});


export const cookieReducers = {
	setCookieInfo    : cookie.actions.setCookieInfo,
	setUI            : cookie.actions.setUI,
	setCookieInfoOpen: cookie.actions.setCookieInfoOpen,
};

export default cookie.reducer;