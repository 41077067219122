import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	IBalloonNotificationClaimType,
	IBalloonNotificationInitialState,
} from './types';

const initialState: IBalloonNotificationInitialState = {
	BonusClaimUI: {
		visible    : false,
		title      : '',
		description: '',
		duration   : 3000,
	},
};

export const balloonNotification = createSlice({
	name        : 'balloon-notification',
	initialState: initialState,
	reducers    : {
		setBonusClaimUI(state: Draft<IBalloonNotificationInitialState>, action: PayloadAction<Partial<IBalloonNotificationClaimType>>) {
			const source = state.BonusClaimUI;
			const target = action.payload;
			state.BonusClaimUI = { ...source, ...target };
		},
		resetModalState() {
			return initialState;
		},
	},
});

export const notificationReducers = {
	setBonusClaimUI: balloonNotification.actions.setBonusClaimUI,
	resetModalState: balloonNotification.actions.resetModalState,
};
export default balloonNotification.reducer;