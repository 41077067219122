import React from 'react';

const ProviderPreloader: React.FC = () => {
	return (
		<ul className="provider_preloader">
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
		</ul>
	);
};

export default ProviderPreloader;