import { apiRequest } from '../../../service/service';
import { IGameGridConfigs, IParamsDefaultGrid } from './types';

export default class GamesGrid {

	gamesDefaultGridList = (params: IParamsDefaultGrid) => {
		const url = '/v1/casinoGame/orders';
		const config: IGameGridConfigs = {
			method       : 'get',
			url          : url,
			withoutLangID: true,
			params,
		};

		return apiRequest(config);
	};
}


