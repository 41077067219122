import { all, call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import dayjs from 'dayjs';
import {
	clearData, removeSessionItem,
	reStoreAuthToken, restoreReactiveToken, restoreReferer,
	storeAuthToken,
	storeReactiveToken, storeSocketAuthToken,
} from '../../helpers/localStorageUtils';
import { FORMAT_REQ } from '../../components/controls/year-piker_range/utils';
import { emitEventMessage } from '../../sockets/socket';
import { getRefererData, objectCloneDeep, openTronWalletOrMarket, parseToInteger } from '../../helpers/utils';
import { Statuses } from '../../constants/common';
import { router } from '../../routes/browserRoutes';
import { logger } from '../../helpers/debugLogger';
import socketActions from '../socket/actions';
import { routerReducers } from '../router/slice';
import { modalReducers } from '../modals/slice';
import Api from './api';
import profileActions from './actions';
import { getFullName, getStatuses, tronDeepLink } from './utils';
import { RGamblingReducers } from '../gambling/slice';
import { profileReducers } from './slice';
import { STORAGE_KEYS, TRONLINK_CALLBACK_URL } from '../../helpers/constants';
import { RootState } from '../store';
import { ISagaActionType } from '../types';
import {
	IChangePasswordAction,
	IDeactivateTronAction,
	IEditProfilePayload,
	IEmailData, IInboxCount,
	IPrepareConfirmPhone,
	IPreparedPhoneVerify,
	IPrepareTronDeactivate,
	IProfileData,
	IProfileDoc,
	IProfileDocBinary,
	IReturnTypeofGetStore,
	ISignInData,
	ISignInResponse,
	ISignUpAction,
	ITronLinkDeactivate,
	ITronLoginAction,
	ITronLoginPrepared,
	ITronLoginWalletResponse,
	ITronSignData,
	SLUGS,
	TRON_ACTION_TYPES,
} from './types';
import { IReferer } from '../../helpers/types';
import { AxiosApiResponse } from '../../service/types';
import { TParamsValue } from '../settings/types';
import { notificationReducers } from '../notifications/slice';
import { twoFactorReducers } from '../2FA/slice';
import { twoFAActions } from '../2FA/actions';
import { bonusReducers } from '../bonus/slice';
import { ErrorCodes } from '../../errors/ErrorCodes';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

import { historyReducers } from '../history/slice';

const API = new Api();
const errorMessages = {
	errorLogin: 'error.login.modal',
};

const getStoreData = ({ Settings, Profile, Modals, Socket }: RootState): IReturnTypeofGetStore => {
	const { daysFormat, resendEmailTime } = Settings.initialSettings;
	const currentLanguage   = Settings.current_language;
	const tronLoginDeepLink = objectCloneDeep(Profile.tronlink.params);
	const tronSignDeepLink  = Profile.tronlink.signParams;
	const origin     = window.location.origin;
	const API_URL           = process.env.REACT_APP_API_PATH || '';

	if (currentLanguage) {
		tronLoginDeepLink.url   = origin.concat(`/${currentLanguage.code}/tronlink`);
	}

	return {
		languages             : Settings.languages,
		profileData           : Profile.profileData,
		profileDocBinaryStatus: Profile.profileDocBinaryStatus,
		profileDocs           : Profile.profileDocs,
		Login                 : Modals.Login,
		Register              : Modals.Register,
		sid                   : Socket.sid,
		tronLoginDeepLink,
		tronSignDeepLink,
		API_URL,
		daysFormat,
		resendEmailTime,
	};
};


function* profileDataSaga() {
	yield takeEvery(profileActions.PROFILE_DATA_RELOAD, function* () {
		try {
			const result: AxiosApiResponse<IProfileData>   = yield call(API.getProfileData);
			if (result && result.status === 200) {
				const { data } = result.data;
				const { enable_2fa_app, enable_2fa_sms } = data;
				data.full_name = getFullName(data);
				const balance: AxiosApiResponse<number>  = yield call(API.getBonusBalance);

				if (balance && balance.data) {
					data.bonus_balance = balance.data.data;
				}
				const token = reStoreAuthToken();
				const statusEmailVerification = getStatuses(Boolean(data.email_verified));
				const statusPhoneVerification = getStatuses(Boolean(data.phone_verified));
				yield put(profileReducers.setToken(token || ''));

				if (!data.time_zone) {
					data.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				}
				yield put(profileReducers.setProfileData(data));
				yield put(profileReducers.setVerificationStatuses({ statusPhoneVerification, statusEmailVerification }));
				yield put(twoFAActions.getValues2FA({ enable_2fa_app, enable_2fa_sms }));
			}
		}
		catch (e) {
			/*const message = {
       title: "Error",
       message: e.message,
       type: "danger"
       };
       logger.log(message);*/
		}
	});

}

function* editProfileData() {
	yield takeEvery(profileActions.EDIT_PROFILE_DATA, function* ({ data: actionData }:  ISagaActionType<IEditProfilePayload>) {
		const { data, is2FA, setCurrency } = actionData;
		const { daysFormat } = yield select(getStoreData);
		yield put(profileReducers.UIRefresh({ loading: true }));

		try {
			let formatForRequest: string | null  = null;
			const dateBirth = data.get('birth');
			if (dateBirth && typeof dateBirth === 'string' ) {
				formatForRequest = dateBirth ?  formatForRequest = dayjs(dateBirth, daysFormat).format(FORMAT_REQ) : '';
				data.set('birth', formatForRequest);
			}

			const result: AxiosApiResponse<IProfileData> = yield call(API.editProfileData, data);
			if (result && result.status === 200) {
				const { data } = result.data;

				const balance: AxiosApiResponse<number> = yield call(API.getBonusBalance);
				if (!data.time_zone) {
					data.time_zone = '';
				}

				if (balance && balance.data) {
					data.bonus_balance = balance.data.data;
				}
				yield put(profileActions.profileDataReload());
				yield put(profileReducers.setProfileEditStatus({
					hasError: false,
					errorMsg: {},
				}));
				yield put(profileReducers.UIRefresh({ isEditData: false }));

				if (window.location.search) {
					window.location.search = '';
				}
				if (setCurrency) {
					yield put(modalReducers.setInfoUI({ visible: true, type: 'success', description: 'set_currency_success' }));
				}
			}
		} catch (e) {
			if (e instanceof Error) {
				logger.error(e);
			}
			if (is2FA) {
				yield put(twoFactorReducers.setUI({ errorProfileReq: false }));
			}
		} finally {
			yield put(profileReducers.UIRefresh({ loading: false }));
		}
	});
}

function* editProfileImage() {
	yield takeEvery(profileActions.EDIT_PROFILE_IMAGE, function* (action: ISagaActionType<FormData>) {
		try {
			const { data } = action;
			const result: AxiosApiResponse<[string]>   = yield call(API.editProfileImage, data);
			if (result && result.status === 200) {
				const { data } = result.data;
				const avatar   = data[0];
				const avatarForm = new FormData();
				avatarForm.append('avatar', avatar);
				yield put(profileActions.editProfileData(avatarForm));
			}
		}
		catch (e: unknown) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
				yield put(profileReducers.setTempAvatar(null));
			}

			logger.log(e);
		}
	});

}

function* getProfileDocs() {
	yield takeEvery(profileActions.GET_PROFILE_DOCS, function* () {
		try {
			const result: AxiosApiResponse<IProfileDoc[]>   = yield call(API.getDocs);
			if (result && result.status === 200) {
				const { data } = result.data;
				yield put(profileReducers.setProfileDocs(data));
			}
		}
		catch (e: unknown) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}

function* uploadProfileDocBinary() {
	yield takeEvery(profileActions.UPLOAD_PROFILE_DOC_BINARY, function* (action: ISagaActionType<FormData>) {
		yield put(profileReducers.profileDocBinaryStatus({ loading: true }));

		try {
			const { data }                   = action;
			const result: AxiosApiResponse<[string]>   = yield call(API.uploadDocBinary, data);
			const storeData: IReturnTypeofGetStore = yield select(getStoreData);
			const { profileDocBinaryStatus } = storeData;
			if (result && result.status === 200) {
				const { data } = result.data;
				const url      = data[0];
				const clonedData = objectCloneDeep(profileDocBinaryStatus);
				clonedData.url = url;
				yield put(profileReducers.profileDocBinaryStatus({ ...clonedData, loading: false }));
			}
		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
				yield put(profileReducers.profileDocBinaryStatus({ loading: false }));
			}
			logger.log(e);
		}
	});
}

function* uploadProfileDoc() {
	yield takeEvery(profileActions.UPLOAD_PROFILE_DOC, function* (action: ISagaActionType<IProfileDocBinary>) {
		try {
			const { data }     = action;
			const callBack  = data.callBack;
			const storeData: IReturnTypeofGetStore    = yield select(getStoreData);
			const result: AxiosApiResponse<IProfileDoc>       = yield call(API.uploadDoc, data.data);
			if (result && result.status === 200) {
				const { data } = result.data;
				yield put(profileReducers.setProfileDocs([data, ...storeData.profileDocs]));
				callBack();
			}
		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}

			logger.log(e);
		}
	});

}

function* signUp() {
	yield takeEvery(profileActions.SIGN_UP, function* (action: ISagaActionType<ISignUpAction>) {
		try {
			let { data } = action;
			yield put(modalReducers.setRegisterUI({ button: 'loading' }));
			yield delay(1200);
			const referer = restoreReferer();
			if (referer)
				data = { ...getRefererData(), ...data };

			const result: AxiosApiResponse<IProfileData> = yield call(API.signUp, data);
			if (result && result.status === 200) {
				removeSessionItem(STORAGE_KEYS.REFERER);
				const result = yield call(setProfileData, { email: data.email, password: data.password });
				if (result && result.status === 200) {
					yield put(
						modalReducers.setRegisterUI({ button: 'loading success' }));
					document.body.classList.remove('popup_opened');
					yield delay(1000);
					yield put(modalReducers.setRegisterUI({ visible: false, button: '' }));
					window.scrollTo({ top: 0, behavior: 'smooth' });

				}
				else {
					alert('login problem');
				}
			}
		}
		catch (e: unknown) {
			if (e instanceof Error) {
				yield put(modalReducers.setRegisterUI({ error: e.message, button: 'loading reject' }));
				yield delay(1500);
				yield put(modalReducers.setRegisterUI({ button: '' }));
			}
			logger.log(e);
		}
	});
}

function* signIn() {
	yield takeEvery(profileActions.SIGN_IN, function* (action: ISagaActionType<FormData>) {
		try {
			const { data } = action;
			yield put(modalReducers.setLoginUI({ button: 'loading' }));
			yield delay(1200);

			const result: AxiosApiResponse<ISignInResponse> = yield call(setProfileData, data);
			if (result && result.status === 200) {
				const { user } = result.data.data;
				if (user.status_id !== 3) {
					yield put(profileReducers.setProfileData(user));
					const { Login }: IReturnTypeofGetStore = yield select(getStoreData);
					if (Login.redirect) {
						yield put(routerReducers.setNavigateEnd(true));
						yield put(routerReducers.setHistory({ to: Login.redirect, options: { replace: true } }));
					}
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
			yield put(modalReducers.setLoginUI({ button: '', visible: false, loading: false }));
		}
		catch (e) {
			yield put(modalReducers.setLoginUI({ error: errorMessages.errorLogin, button: 'loading reject' }));
			yield delay(1500);
			yield put(modalReducers.setLoginUI({ button: '' }));
		}
	});

}

function* setProfileData(data: FormData | ISignInData) {
	const result: AxiosApiResponse<ISignInResponse> = yield call(API.signIn, data);
	if (result && result.status === 200) {
		const { user } = result.data.data;

		const { Login }: IReturnTypeofGetStore = yield select(getStoreData);
		if (user.status_id === 3) {
			if (user.enable_2fa_app || user.enable_2fa_sms) {
				yield put(modalReducers.setTwoFALoginModalUI({ visible: false }));
				yield put(twoFactorReducers.set2FALogin(user));
			}

			if (Login.visible) {
				yield put(modalReducers.setLoginUI({ visible: false, button: '' }));
				document.body.classList.remove('popup_opened');
				yield put(modalReducers.setReactivateUI({ visible: true }));
				storeReactiveToken(result.data.data.access_token);
			}
		} else if (user.enable_2fa_app || user.enable_2fa_sms) {
			storeAuthToken(result.data.data.access_token);
			yield put(twoFactorReducers.set2FALogin(user));
			yield put(modalReducers.setLoginUI({ visible: false, button: '' }));
			yield put(modalReducers.setTwoFALoginModalUI({ visible: true }));
			document.body.classList.remove('popup_opened');
		} else {
			storeAuthToken(result.data.data.access_token);
			yield put(socketActions.reconnect(result.data.data.access_token));
			const balance: AxiosApiResponse<number> = yield call(API.getBonusBalance);
			if (balance && balance.data) {
				user.bonus_balance = balance.data.data;
			}
			if (Login.visible) {
				yield put(modalReducers.setLoginUI({ button: 'loading success' }));
				yield delay(500);
				document.body.classList.remove('popup_opened');
				yield put(modalReducers.setLoginUI({ visible: false, button: '' }));
			}
			yield put(profileReducers.setToken(result.data.data.access_token));
			yield put(profileReducers.setProfileData(user));

			yield put(profileActions.profileDataReload());
		}
	}
	return  result;
}

function* signOut() {
	yield takeEvery(profileActions.SIGN_OUT, function* () {

		yield put(profileReducers.signOut());
		try {
			yield put(profileReducers.pendingLogout(true));
			const result: AxiosApiResponse<Record<string, never>> = yield call(API.signOut);
			if (result && result.status === 200) {
				yield put(profileReducers.setToken(''));
				clearData(STORAGE_KEYS.AUTH_TOKEN);
				yield call(router.navigate, '/');
				yield put(historyReducers.resetHistoryState());
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
		yield put(profileReducers.pendingLogout(false));
		const authToken = reStoreAuthToken();
		yield call(emitEventMessage, 'logout', { token: authToken });
		clearData(STORAGE_KEYS.AUTH_TOKEN);
		clearData(STORAGE_KEYS.SOCKET_AUTH_TOKEN);
		yield put(socketActions.reconnect(''));
		yield put(profileReducers.profileReset());
		yield put(notificationReducers.resetNotifications());
		yield put(modalReducers.resetModalState());
		yield put(RGamblingReducers.resetSlice());
		yield put(bonusReducers.resetBonusState());
	});

}

function* resetPassword() {
	yield takeEvery(profileActions.RESET_PASSWORD, function* (action: ISagaActionType<ISignInData>) {
		try {
			const { data: { email, password } } = action;
			const result: AxiosApiResponse<Record<string, never>>  = yield call(API.resetPassword, action.data);

			if (result && result.status === 200) {
				const result = yield call(setProfileData, { email, password });
				if (result && result.status === 200) {
					yield put(profileReducers.setPasswordReseted(true));
					yield put(modalReducers.setResetUI({ visible: false }));
					document.body.classList.remove('popup_opened');
				}
			}
		}
		catch (error) {
			if (error instanceof Error) {
				const errorCode = parseToInteger(error.message);
				yield put(modalReducers.setResetUI({ error: ErrorCodes[errorCode] }));
			}
			logger.log(error);
		}
	});

}

function* forgetPassword() {
	yield takeEvery(profileActions.FORGET_PASSWORD, function* (action: ISagaActionType<IEmailData>) {
		yield put(profileReducers.UIRefresh({ forgetLoading: true }));
		try {
			const { data } = action;
			const result: AxiosApiResponse<Record<string, never>>   = yield call(API.forgetPassword, data);
			if (result && result.status === 200) {
				yield put(modalReducers.setForgetUI({ success: true }));
			}
		}
		catch (error) {
			if (error instanceof Error) {
				const errorCode = parseToInteger(error.message);
				yield put(modalReducers.setForgetUI({ error: ErrorCodes[errorCode] }));
			}
			logger.log(error);
		}

		yield put(profileReducers.UIRefresh({ forgetLoading: false }));

	});

}

function* getUnreadInboxCount() {
	yield takeEvery(profileActions.GET_UNREAD_INBOX_COUNT, function* () {
		try {
			const result: AxiosApiResponse<IInboxCount>   = yield call(API.getInboxCount);
			if (result && result.status === 200) {
				const { data } = result.data;
				yield put(profileReducers.setUnreadInboxCount(data.count));
			}
		}
		catch (e) {
			if (e instanceof Error) {
				logger.error(e.message);
			}
			logger.log(e);
		}
	});

}

function* changePassword() {
	yield takeEvery(profileActions.CHANGE_PASSWORD, function* (action: ISagaActionType<IChangePasswordAction>) {
		try {
			const { data } = action;
			const result: AxiosApiResponse<Record<string, never>>   = yield call(API.changePassword, data);

			if (result && result.status === 200) {
				yield put(profileReducers.UIRefresh({ passwordChanged: true }));
				yield put(modalReducers.setInfoUI({
					visible    : true,
					title      : 'change_pass_title',
					description: 'password_changed_success_description',
					type       : 'success',
				}));
			}
		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({
					visible    : true,
					type       : 'reject',
					title      : ErrorCodes[errorCode],
					description: ErrorCodes[errorCode],
				}));
			}
			logger.log(e);
		}
	});
}


function* deactivateProfile() {
	yield takeEvery(profileActions.DEACTIVATE_PROFILE, function* (action: ISagaActionType<IDeactivateTronAction>) {
		try {
			const { data  } = action;
			const { tronLinkAddress } = data;
			const result: AxiosApiResponse<ITronLinkDeactivate> = yield call(API.deactivateAccount, data);
			if (result && result.status === 200) {
				if (tronLinkAddress) {
					const unSignedData      = result.data.data.sign_message;
					const actionId          = result.data.data.action_id;
					if (window.tronWeb) {
						const signedTransaction: string = yield window.tronWeb.trx.sign(unSignedData);
						const preparedData: IPrepareTronDeactivate = {
							actionId  : actionId,
							signedData: signedTransaction,
						};
						const res = yield call(API.sendSignedData, preparedData);
						if (res && res.status === 200) {
							yield put(profileReducers.signOut());
						}
					}
				}
			}
			yield put(profileReducers.signOut());
		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
	});
}

function* deactivateProfileMobile() {
	yield takeEvery(profileActions.DEACTIVATE_PROFILE_MOBILE, function* (action: ISagaActionType<IDeactivateTronAction>) {
		try {
			const { tronSignDeepLink, profileData }: IReturnTypeofGetStore   = yield select(getStoreData);
			tronSignDeepLink.callbackUrl = TRONLINK_CALLBACK_URL;

			const { data } = action;
			const result: AxiosApiResponse<ITronLinkDeactivate>   = yield call(API.deactivateAccount, data);


			if (result && result.status === 200) {
				tronSignDeepLink.actionId = `${result.data.data.action_id}`;
				tronSignDeepLink.message = `${result.data.data.sign_message}`;
				tronSignDeepLink.loginAddress = profileData.tronlink_address || '';

				yield put(profileReducers.setTronlinkSignParams(tronSignDeepLink));
				const href = tronDeepLink(tronSignDeepLink);

				openTronWalletOrMarket(href);
			}

		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI(
					{ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
	});

}

function* reactivateProfile() {
	yield takeEvery(profileActions.REACTIVATE_PROFILE, function* () {
		try {
			const result: AxiosApiResponse<Record<string, string>>   = yield call(API.reactivateAccount);
			if (result && result.status === 200) {
				const reactiveToken = restoreReactiveToken();
				const userToken = reactiveToken
					? reactiveToken
					: '';
				storeAuthToken(userToken);
				clearData(STORAGE_KEYS.SOCKET_AUTH_TOKEN);

				yield put(profileActions.profileDataReload());
				yield put(modalReducers.setReactivateUI({ visible: false }));
				document.body.classList.remove('popup_opened');
				clearData(STORAGE_KEYS.REACTIVE_TOKEN);
			}
		}
		catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI(
					{ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
	});

}

function* verificationPhoneNumber() {
	yield takeEvery(profileActions.VERIFICATION_PHONE_NUMBER, function* (action: ISagaActionType<{ number: string }>) {
		const { number } = action.data;
		const { profileData }: IReturnTypeofGetStore = yield select(getStoreData);
		const prepared: IPreparedPhoneVerify = {
			user_id: profileData.id,
			slug   : SLUGS.verification,
		};
		try {
			if (profileData.phone) {
				yield call(API.getVerificationPhoneNumber, prepared);
			} else {
				const cloneProfile = { user_id: profileData.id, phone: number };
				const resProfile: AxiosApiResponse<IProfileData> = yield call(API.editProfileData, cloneProfile);

				if (resProfile.status === 200) {
					yield call(API.getVerificationPhoneNumber, prepared);
				}
			}
			yield put(modalReducers.setVerificationUI({ visible: true }));
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI(
					{ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
	});
}

function* loginViaTronLink() {
	// @ts-expect-error TODO this generator function has return statement. need to be clarified the reason
	yield takeEvery(profileActions.TRONLINK_LOGIN, function* (action: ISagaActionType<ITronLoginAction>) {

		const { data }                   = action;
		const { walletID, actionType } = data;

		yield put(profileReducers.setTronlinkUI({ loading: true }));

		switch (actionType) {
			case TRON_ACTION_TYPES.LOGIN: {
				try {
					yield put(modalReducers.setLoginUI({ button: 'loading' }));
					yield put(modalReducers.setRegisterUI({ button: 'loading' }));

					const result: AxiosApiResponse<ITronSignData> = yield call(API.sendWalletID, walletID);

					if (result && result.status === 200) {
						const data             = result.data.data;
						const { sign_message } = data;
						let hashed = '';
						if (window.tronWeb) {
							hashed = yield window.tronWeb.trx.sign(sign_message);
						}

						const prepared: ITronLoginPrepared = {
							token_address: walletID,
							sign_message : sign_message,
							sign_hex     : hashed,
						};
						const refererData: IReferer = {
							data: {},
						};
						const referer= restoreReferer();
						if (referer)
							refererData.data = getRefererData();


						const params: {
							[k: string]: TParamsValue;
						} = {
							...refererData.data,
						};

						const resp: AxiosApiResponse<ISignInResponse> = yield call(API.sendHashedString, prepared, params);

						if (resp && resp.status === 200) {
							const { user } = resp.data.data;

							const { Login, Register } = yield select(getStoreData);

							if (user.status_id === 3) {

								if (Login.visible) {
									yield put(modalReducers.setLoginUI(
										{ visible: false, button: '' }));
									yield put(modalReducers.setRegisterUI(
										{ visible: false, button: '' }));

									yield put(modalReducers.setReactivateUI({ visible: true }));
									storeReactiveToken(resp.data.data.access_token);

								}
								clearData(STORAGE_KEYS.SOCKET_AUTH_TOKEN);

								yield put(profileReducers.setTronlinkUI({ loading: false }));

							}
							else {
								storeAuthToken(resp.data.data.access_token);
								clearData(STORAGE_KEYS.SOCKET_AUTH_TOKEN);
								yield put(socketActions.reconnect(resp.data.data.access_token));
								const balance: AxiosApiResponse<number> = yield call(API.getBonusBalance);
								if (balance && balance.data) {
									user.bonus_balance = balance.data.data;
								}

								if (Login.visible) {
									yield put(modalReducers.setLoginUI(
										{ button: 'loading success', visible: false }));
									yield put(modalReducers.setRegisterUI(
										{ button: 'loading success', visible: false }));

									yield delay(500);
									document.body.classList.remove('popup_opened');
									yield put(modalReducers.setLoginUI(
										{ visible: false, button: '' }));
									yield put(modalReducers.setRegisterUI(
										{ visible: false, button: '' }));

								}
								if (Register.visible) {
									yield put(modalReducers.setLoginUI(
										{ button: 'loading success', visible: false }));
									yield put(modalReducers.setRegisterUI(
										{ button: 'loading success', visible: false }));

									yield delay(500);
									document.body.classList.remove('popup_opened');
									yield put(modalReducers.setLoginUI(
										{ visible: false, button: '' }));
									yield put(modalReducers.setRegisterUI(
										{ visible: false, button: '' }));
								}

								if (Register.visible) {
									yield put(modalReducers.setLoginUI(
										{ button: 'loading success', visible: false }));
									yield put(modalReducers.setRegisterUI(
										{ button: 'loading success', visible: false }));

									yield delay(500);
									document.body.classList.remove('popup_opened');
									yield put(modalReducers.setLoginUI(
										{ visible: false, button: '' }));
									yield put(modalReducers.setRegisterUI(
										{ visible: false, button: '' }));

								}
								yield put(
									profileReducers.setToken(resp.data.data.access_token));
								yield put(profileReducers.setProfileData(user));
								yield put(profileReducers.setTronlinkUI({ loading: false }));

							}
						}
						return resp;
					}
				}
				catch (e) {
					if (e instanceof Error) {
						yield put(profileReducers.setTronlinkUI({ loading: false }));
						yield put(modalReducers.setLoginUI({ button: '' }));
						yield put(modalReducers.setRegisterUI({ button: '' }));
					}

					logger.log(e);
				}
				return;
			}

			default: {
				logger.log('Never reached');
			}
		}

		yield put(profileReducers.setTronlinkUI({ loading: false }));
		yield put(modalReducers.setLoginUI({ button: '' }));

	});

}

function* loginViaTronLinkMobileApp() {
	yield takeEvery(
		profileActions.TRONLINK_LOGIN_MOBILE_APP, function* () {

			const { sid, tronLoginDeepLink } = yield select(getStoreData);

			const params = Object.assign({}, tronLoginDeepLink);
			yield put(modalReducers.setLoginUI({ button: 'loading' }));
			try {
				const result: AxiosApiResponse<ITronLoginWalletResponse> = yield call(API.sendWalletID, sid);

				if (result && result.status === 200) {
					params.actionId = result.data.data.action_id;
					params.callbackUrl += sid;
					const socketAuthToken = result.data.data.access_token;
					if (socketAuthToken) {
						storeSocketAuthToken(socketAuthToken);
						yield put(socketActions.reconnect(socketAuthToken));
					}

					const href      = tronDeepLink(params);
					const timerID =  openTronWalletOrMarket(href, 1000);


					yield  put(profileReducers.setTronlinkUI({ loading: false, timerID: Number(timerID) }));

				}
			}
			catch (e) {
				logger.log(e);
			}
		});
}

function* verificationEmail() {
	yield takeEvery(profileActions.VERIFICATION_EMAIL, function* () {
		const { resendEmailTime } = yield select(getStoreData);

		try {
			const res: AxiosApiResponse<Record<string, string>> = yield call(API.getVerificationEmail);

			if (res.status === 200 && res.data.data.success) {
				yield put(profileReducers.setVerificationStatuses({ emailResendTime: resendEmailTime }));
				yield put(modalReducers.setInfoUI({
					visible    : true,
					title      : 'Success',
					description: 'verification_email_send_success',
					type       : 'success',
				}));

				yield put(profileActions.profileDataReload());
			}
		} catch (e) {
			yield put(modalReducers.setInfoUI({
				visible    : true,
				type       : 'reject',
				description: 'verification_email_error',
			}));
		}
	});
}

function* confirmEmail() {
	yield takeEvery(profileActions.CONFIRM_EMAIL, function* (action: ISagaActionType<{ hash: string }>) {
		const { hash } = action.data;
		try {
			const res: AxiosApiResponse<Record<string, string>> = yield call(API.confirmEmail, hash);
			if (res.status === 200) {
				yield put(profileReducers.setVerificationStatuses({ statusEmailVerification: Statuses.success, emailResendTime: 0 }));
				yield put(modalReducers.setInfoUI({
					visible    : true,
					title      : 'Success',
					description: 'verification_email_confirm_success',
					type       : 'success',
					navigate   : true,
					hrefTo     : 'profile',
				}));
			}
		} catch (e) {
			yield put(modalReducers.setInfoUI({
				visible : true,
				title   : 'Error',
				message : 'verification_email_confirm_error',
				type    : 'reject',
				navigate: true,
				hrefTo  : 'profile',
			}));
		}
	});
}

function* confirmVerificationPhoneNumber() {
	yield takeEvery(profileActions.VERIFICATION_CONFIRM_PHONE_NUMBER, function* (action: ISagaActionType<string>) {
		const code  = action.data;
		const { profileData } = yield select(getStoreData);

		const confirmPhoneBody: IPrepareConfirmPhone = {
			user_id: profileData.id,
			code,
		};
		try {
			const res: AxiosApiResponse<Record<string, string>> = yield call(API.confirmPhoneNumber, confirmPhoneBody);
			if (res.status === 200) {
				yield put(profileReducers.setVerificationStatuses({ statusPhoneVerification: Statuses.success }));
				yield put(modalReducers.setVerificationUI({ visible: false }));
				yield put(modalReducers.setInfoUI({
					visible    : true,
					title      : 'Success',
					description: 'verification_phone_number_confirm_success',
					type       : 'success',
				}));
			}
		} catch (e) {
			yield put(profileReducers.setVerificationStatuses({ error: true }));
		}
	});
}


function* profileSaga() {
	yield all([
		fork(profileDataSaga),
		fork(editProfileImage),
		fork(editProfileData),
		fork(getProfileDocs),
		fork(uploadProfileDocBinary),
		fork(uploadProfileDoc),
		fork(signUp),
		fork(signIn),
		fork(signOut),
		fork(forgetPassword),
		fork(getUnreadInboxCount),
		fork(changePassword),
		fork(deactivateProfile),
		fork(deactivateProfileMobile),
		fork(loginViaTronLink),
		fork(loginViaTronLinkMobileApp),
		fork(reactivateProfile),
		fork(verificationPhoneNumber),
		fork(confirmVerificationPhoneNumber),
		fork(verificationEmail),
		fork(confirmEmail),
		fork(resetPassword),
	]);
}

export default profileSaga;
