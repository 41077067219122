import { put, takeEvery, all, fork, select, call } from 'redux-saga/effects';
import actions from './actions';
import Api from '../api';
import { createTournamentLists, getMainTournament  } from '../utils';
import { REACT_APP_WEBSITE_ID } from '../../../config';
import { logger } from '../../../helpers/debugLogger';
import { RootState } from '../../store';
import { IGetTournamentsListParams, ITournamentListGetStore, TOURNAMENT_STATUS } from './types';
import { tournamentListReducers } from './slice';
import { ISagaActionType } from '../../types';
import { AxiosApiResponse } from '../../../service/types';
import { ITournament } from '../tournament/types';

const tournamentsAPI = new Api();

const getStoreData = ({ Settings }: RootState): ITournamentListGetStore => ({
	websiteID: REACT_APP_WEBSITE_ID,
	langID   : Settings?.current_language?.id,
});

function* getTournamentsList() {
	yield takeEvery(actions.TOURNAMENTS_LIST_RELOAD, function* (action: ISagaActionType<{ statusIDs: TOURNAMENT_STATUS[]; isList: boolean }>) {
		yield put(tournamentListReducers.setUI({ loading: true }));
		const { statusIDs, isList } = action.data;
		try {
			const { websiteID, langID }: ITournamentListGetStore    = yield select(getStoreData);
			const params: IGetTournamentsListParams = {
				status_ids: statusIDs,
				lang_id   : langID,
				website_id: websiteID,
			};

			const res: AxiosApiResponse<ITournament[]> = yield call(tournamentsAPI.getTournamentsList, params);
			if (res && res.status === 200) {
				const tournamentList = createTournamentLists(res.data.data);
				if (isList) {
					const mainTournament: ITournament = getMainTournament(tournamentList);
					yield put(tournamentListReducers.setMainTournament(mainTournament));
				}
				yield put(tournamentListReducers.setTournaments(tournamentList));
			}
		} catch (e) {
			logger.log(e);
		}
		yield put(tournamentListReducers.setUI({ loading: false }));
	});

}

function* tournamentsListSaga() {
	yield all([
		fork(getTournamentsList),
	]);
}

export default tournamentsListSaga;
