import { ReactNode } from 'react';
import { TParamsValue } from '../redux/settings/types';
import { CountryCode } from './constants';
import { NumberOrString } from '../types/types';

export type StringORNull = string | null;

export interface IReferer {
	data: Record<NumberOrString, TParamsValue>;
}

export type TCountryList = {
	[key in CountryCode]: string;
};

export type TCountryCode = keyof TCountryList;

export type TCountry = {
	label: string;
	code : string;
	value: string;
};


export interface IDeviceProps {
	children: ReactNode;
}


export interface IOutsideClickDetectorProps {
	onOutsideClick: (event: MouseEvent) => void;
	children      : ReactNode;
	className?    : string;
}

export interface RefererData {
	[key: string]: string;
}

export interface INormalizeNotificationData {
	review_date: string;
	type_id    : number;
	id         : number;
	status_id  : number;
	url        : string;
}

export interface INormalizedNotificationData {
	id       : number;
	status_id: number;
	action   : string;
	body     : ReactNode;
	created  : string;
	img      : string;
	seen     : boolean;
	title    : ReactNode;
	type     : number;
	user_id  : number;
	isDoc    : boolean;
}
export enum SCRIPT_DISPLAY_TYPE {
	ALL      = 1,
	NECESSARY= 2,
	CHOOSES  = 3,
}

export interface EnumItem<T> {
	id  : T;
	name: string;
}

export type TDelayFunction = {
  <T extends unknown[]>(func: (...args: T) => void, wait: number): void; 
};

export type TInvertObject = Record<string, string | number>;
