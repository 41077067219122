import { IGameInfoPayload, IGameSearchPayload, IGamesPagination, IGameStartPayload } from './types';

const actions = {
	GET_GAMES_LIST      : 'GET_GAMES_LIST',
	SEARCH_GAMES        : 'SEARCH_GAMES',
	GET_CATEGORIES      : 'GET_CATEGORIES',
	GET_CUSTOM_PROVIDERS: 'GET_CUSTOM_PROVIDERS',
	SET_CATEGORIES      : 'SET_CATEGORIES',
	GET_PROVIDERS       : 'GET_PROVIDERS',
	GET_SUB_PROVIDERS   : 'GET_SUB_PROVIDERS',
	GET_GAMES_BY_PARAMS : 'GET_GAMES_BY_PARAMS',
	START_GAME          : 'START_GAME',
	INFO_GAME           : 'INFO_GAME',
	GET_SIMILAR         : 'GET_SIMILAR',


	searchGamesAction: (data: IGameSearchPayload) => {
		return {
			type: actions.SEARCH_GAMES,
			data,
		};
	},

	getCategoriesAction: (withNavigate?: boolean) => {
		return {
			type: actions.GET_CATEGORIES,
			data: {
				withNavigate,
			},
		};
	},
	getSubProviders: (categoryID: number) => {
		return {
			type: actions.GET_SUB_PROVIDERS,
			data: categoryID,
		};
	},

	getProvidersAction: (categoryID: number) => {
		return {
			type: actions.GET_PROVIDERS,
			data: categoryID,
		};
	},
	getCustomProviders: (categoryID: number, withNavigate?: boolean) => {
		return {
			type: actions.GET_CUSTOM_PROVIDERS,
			data: {
				categoryID,
				withNavigate,
			},
		};
	},
	getGamesListAction: (data: Partial<IGamesPagination>) => {
		return {
			type: actions.GET_GAMES_LIST,
			data,
		};
	},

	startGameAction: (data: IGameStartPayload) => {
		return {
			type: actions.START_GAME,
			data,
		};
	},

	getInfoGame: (data: IGameInfoPayload) => {
		return {
			type: actions.INFO_GAME,
			data,
		};
	},
};

export default actions;
