
import { ITotalBalance } from '../../types';

export const TotalBalance = ({ currencyIcon, balance, className = '' }: ITotalBalance) => {
	return (
		<div className={`${className}`}>
			<div className={'currencyIconWrapper'}>
				{currencyIcon}
			</div>
			{balance}
		</div>
	);
};