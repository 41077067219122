import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useAppSelector } from '../../hooks/storeHooks';
import { HitType } from './types';


export const GA4 = () => {
	const trackingCode = useAppSelector(state => state.Settings.initialSettings.analytics_id);
	const { pathname, search } = window.location;

	useEffect(() => {
		if (trackingCode && typeof trackingCode === 'string') {
			ReactGA.initialize([{
				trackingId: trackingCode,
			}]);
		}

	}, [trackingCode]);

	useEffect(() => {
		ReactGA.send({ hitType: HitType.pageview, page: window.location.pathname + window.location.search });
	}, [pathname, search]);

	return <div/>;
};
