import {
	IDeleteMessagePayload,
	IMarkReadAction,
	IMessagePayload,
} from './types';

const actions = {
	UPLOAD_INBOX_FILES_BINARY: 'UPLOAD_INBOX_FILES_BINARY',
	GET_MESSAGES             : 'GET_MESSAGES',
	GET_MESSAGE              : 'GET_MESSAGE',
	CREATE_MESSAGE           : 'CREATE_MESSAGE',
	REPLY_MESSAGE            : 'REPLY_MESSAGE',
	DELETE_MESSAGE           : 'DELETE_MESSAGE',
	MARK_AS_READ_INBOX       : 'MARK_AS_READ_INBOX',

	uploadInboxDocBinary: (data: FormData) => {
		return {
			type: actions.UPLOAD_INBOX_FILES_BINARY,
			data,
		};
	},
	getMessagesAction: () => {
		return {
			type: actions.GET_MESSAGES,
		};
	},
	getMessageAction: (id: number) => {
		return {
			type: actions.GET_MESSAGE,
			data: { id },
		};
	},
	markAsReadInbox: (data: IMarkReadAction) => {
		return {
			type: actions.MARK_AS_READ_INBOX,
			data,
		};
	},
	createMessage: (data: IMessagePayload) => {
		return {
			type: actions.CREATE_MESSAGE,
			data,
		};
	},
	replyMessage: (data: IMessagePayload) => {
		return {
			type: actions.REPLY_MESSAGE,
			data,
		};
	},
	deleteMessageAction: (payload: IDeleteMessagePayload) => {
		return {
			type: actions.DELETE_MESSAGE,
			data: payload,
			payload,
		};
	},
};

export default actions;
