import { SOCKET_CONNECTION_STATUS } from '../../redux/socket/types';

const actions = {

	SOCKET_CONNECTION_STATUS_SET : 'SOCKET_CONNECTION_STATUS_SET',
	SOCKET_CONNECTION_SID        : 'SOCKET_CONNECTION_SID',
	EMIT_ACTION_ID_DEACTIVATE    : 'EMIT_ACTION_ID',
	EMIT_ACTION_ID_DEPOSIT       : 'EMIT_ACTION_ID_DEPOSIT',
	EMIT_ACTION_ID_DEPOSIT_STATUS: 'EMIT_ACTION_ID_DEPOSIT_STATUS',

	setConnectionStatus: (status : SOCKET_CONNECTION_STATUS) => ({
		type: actions.SOCKET_CONNECTION_STATUS_SET,
		data: {
			status,
		},
	}),
	setSocketSid: (sid: string) => ({
		type: actions.SOCKET_CONNECTION_SID,
		data: {
			sid,
		},
	}),
	emitActionIdDeactivate: () => ({
		type: actions.EMIT_ACTION_ID_DEACTIVATE,
	}),

	emitActionIdDeposit: () => ({
		type: actions.EMIT_ACTION_ID_DEPOSIT,
	}),

	emitActionIdStatus: () => {
		return {
			type: actions.EMIT_ACTION_ID_DEPOSIT_STATUS,
		};
	},

};

export default actions;
