import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IInitSportsBook, ISportBookData, ISportBookUI } from './types';
import { fill } from '../../helpers/utils';


const initialState: IInitSportsBook = {
	sportsData: null,
	providerId: null,

	UI: {
		loading: false,
		error  : false,
	},
};

const sportsBookSlice = createSlice({
	name    : 'sportsBook',
	initialState,
	reducers: {
		setToken: (state: Draft<IInitSportsBook>, action: PayloadAction<ISportBookData>) => {
			state.sportsData = action.payload;
		},
		setSportProviderId: (state: Draft<IInitSportsBook>, action: PayloadAction<number>) => {
			state.providerId = action.payload;
		},
		setSportsBookUI: (state: Draft<IInitSportsBook>, action: PayloadAction<Partial<ISportBookUI>>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
		reset: () => initialState,
	},
});


export const actions = {
	...sportsBookSlice.actions,
};

export default sportsBookSlice.reducer;

