import { IDoDepositTronPayload } from './types';

const actions = {
	GET_DEPOSIT_METHODS   : 'GET_DEPOSIT_METHODS',
	DO_DEPOSIT            : 'DO_DEPOSIT',
	SET_DEPOSIT_FAILURE   : 'SET_DEPOSIT_FAILURE',
	DO_DEPOSIT_TRON       : 'DO_DEPOSIT_TRON',
	DO_DEPOSIT_TRON_MOBILE: 'DO_DEPOSIT_TRON_MOBILE',
	SEND_SIGNED_MESSAGE   : 'SEND_SIGNED_MESSAGE',
	AUTHORIZE_TRON        : 'AUTHORIZE_TRON',
	PAYMENT_METHOD_DELETE : 'PAYMENT_METHOD_DELETE',


	getDepositMethods: () => {
		return {
			type: actions.GET_DEPOSIT_METHODS,
		};
	},

	doDeposit: () => {
		return {
			type: actions.DO_DEPOSIT,
		};
	},

	sendSignedMessage: (data: string) => {
		return {
			type: actions.SEND_SIGNED_MESSAGE,
			data,
		};
	},

	doDepositTron: (data: IDoDepositTronPayload) => {
		return {
			type: actions.DO_DEPOSIT_TRON,
			data,
		};
	},
	doDepositTronMobile: (data: IDoDepositTronPayload) => {
		return {
			type: actions.DO_DEPOSIT_TRON_MOBILE,
			data,
		};
	},

	authorizeTron: () => {
		return {
			type: actions.AUTHORIZE_TRON,
		};
	},


	paymentMethodDelete: (data: string) => {
		return {
			type: actions.PAYMENT_METHOD_DELETE,
			data,
		};
	},


};

export { actions as depositActions };
