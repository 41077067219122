import { IBonusAdapted } from './types';
import { BONUS_STATUSES } from '../../constants/common';

export interface BonusItem {
	id       : number;
	status_id: number;
}

export const setBonusItemLoading = (
	bonusData: IBonusAdapted[],
	id: number,
	loading: boolean,
	error: boolean,
	statusID: BONUS_STATUSES | null = null
): IBonusAdapted[] => {
	const mutableArray = Array.from(bonusData);
	if (loading) {
		return mutableArray.map(bonus =>
			bonus.id === id ? { ...bonus, loading } : bonus
		);
	}

	if (!error) {
		const updatedBonusData = mutableArray.map((bonus, index, array) => {
			if (bonus.id === id) {
				return statusID
					? { ...bonus, status_id: statusID }
					: array.splice(index, 1);
			}
			return bonus;
		});

		return updatedBonusData.flat();
	}

	return bonusData;
};
