import { dateType } from '../../history/filters/types';

export interface IObj {
    value: string;
    label: string;
}

export const hours: IObj[] = [];
export const minutes: IObj[] = [];

for (let i = 0; i < 24; i++) {
	const val = i < 10 ? '0' + i : i.toString();
	hours.push({
		label: val,
		value: val,
	});
}

for (let i = 0; i < 60; i++) {
	const val = i < 10 ? '0' + i : i.toString();
	minutes.push({
		label: val,
		value: val,
	});
}

export function formatForPicker(daysFormat: string | undefined) {
	// Look react-datepicker format documentation
	return daysFormat && daysFormat
		.toLowerCase()
		.replace(/[m]{2,}/g, `${daysFormat
			.toLowerCase()
			.match(/[m]{2,}/g)?.join('')
			.toUpperCase()
		}`);
}

export function getHourAndMinutes(date: dateType) {
	if (!date) {
		return {
			hour   : 0,
			minutes: 0,
		};
	}
	const hour: number = date.getHours();
	const minutes: number = date.getMinutes();

	return {
		hour,
		minutes,
	};
}

export const startTime:Date = new Date();
export const endTime:Date = new Date(new Date().getTime() + 2592000000);

export const FORMAT_REQ:string = 'YYYY-MM-DD';

