import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import Api from './api';
import { withdrawActions } from './actions';
import { modalReducers } from '../modals/slice';
import { formatCategoriesUrl, parseToInteger } from '../../helpers/utils';
import { adaptCards, adaptWithdrawalMethods, generateErrorMap, validateWithdraw } from './utils';
import { PAYMENT_PROVIDERS } from '../../constants/payments';
import { verificationTypes } from '../../constants/common';
import { logger } from '../../helpers/debugLogger';
import { withdrawReducers } from './slice';
import { depositReducers } from '../deposit/slice';
import { storeWaitPayment } from '../../helpers/localStorageUtils';
import { WAIT_PAYMENT_STATUS } from '../../helpers/constants';
import { menuReducers } from '../menu/slice';
import { RootState } from '../store';
import {
	IDoWithdrawPayload, IDoWithdrawResponse,
	IGetTronFeePayload,
	IGetWithdrawMethodsPayload,
	IWithdrawGetStore,
	IWithdrawMethod,
	IWithdrawPaywaysMethod,
} from './types';
import { ISagaActionType } from '../types';
import { AxiosApiResponse } from '../../service/types';
import { ICard } from '../../components/withdraw/components/PaymentMethodFields/types';
import { ITronAccountEntities } from '../profile/types';
import { ErrorCodes } from '../../errors/ErrorCodes';

const API = new Api();

const getStore = (store: RootState): IWithdrawGetStore => {
	return {
		withdrawBaseData: store.Withdraw.baseData,
		withdrawMethod  : store.Withdraw.withdraw_method,
	};
};

const lang = {
	blocked: 'blocked_description_withdraw',
};

const messageErr = {
	pending: 'withdraw_pending',
	success: 'withdraw_success',
};

function* withdrawMethods() {
	yield takeEvery(withdrawActions.GET_WITHDRAW_METHODS, function* (action: ISagaActionType<IGetWithdrawMethodsPayload>) {
		yield put(withdrawReducers.withdrawUiRefresh({ listLoading: true }));
		try {
			const { data: { current } } = action;
			const response: AxiosApiResponse<IWithdrawMethod[]> = yield call(API.getWithdrawMethods);
			if (response && response.status === 200) {
				const { data } = response.data;
				if (data.length) {
					const [ method ] = data;
					const currentMethod = method?.payments?.find(item => item.payment_id === Number(current));
					if (currentMethod) {
						yield put(withdrawReducers.selectWithdrawMethod(currentMethod));
					}

					const sitemap = formatCategoriesUrl(data[0].payments || [], 'id', 'name');
					const adaptedData = adaptWithdrawalMethods(data[0]) as IWithdrawMethod;
					yield put(withdrawReducers.setWithdrawMethods(adaptedData));
					// @ts-expect-error FIXME: fix sitemap type
					yield put(menuReducers.setSitemap(sitemap));
				}

			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);

				if (errorCode === ErrorCodes.WITHDRAWAL_BLOCKED) {
					yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
				}

			}

			logger.log(e);
		}
		yield put(withdrawReducers.withdrawUiRefresh({ listLoading: false }));
	});
}

function* withdrawMethodsPayways() {
	yield takeEvery(withdrawActions.GET_WITHDRAW_PAYWAYS_METHODS, function* (action: ISagaActionType<IGetWithdrawMethodsPayload>) {
		yield put(withdrawReducers.withdrawUiRefresh({ loadingPayways: true }));
		try {
			const { data: { current } } = action;
			if (current) {
				const response: AxiosApiResponse<IWithdrawPaywaysMethod[]> = yield call(API.getWithdrawMethodsPayways, current);
				if (response && response.status === 200) {
					const { data } = response.data;
					yield put(withdrawReducers.setWithdrawPaywaysMethods(data));
				}
			}
		} catch (e) {
			logger.log(e);
		}
		yield put(withdrawReducers.withdrawUiRefresh({ loadingPayways: false }));
	});
}

function* doWithdraw() {
	yield takeEvery(withdrawActions.DO_WITHDRAW, function* (action: ISagaActionType<IDoWithdrawPayload>) {
		const { withdrawBaseData, withdrawMethod }: IWithdrawGetStore = yield select(getStore);
		const errors = validateWithdraw(withdrawBaseData, withdrawMethod);
		const hasErrors = errors ? errors.length > 0 : false;
		if (hasErrors) {
			// @ts-expect-error FIXME: fix the generateErrorMap type
			const errorMap = generateErrorMap(errors);
			yield put(withdrawReducers.baseErrorRefresh(errorMap));
			return;
		}

		yield put(withdrawReducers.withdrawUiRefresh({ loading: true }));


		if (PAYMENT_PROVIDERS.tronlink === action.data.method){
			yield put(modalReducers.setInfoUI({ visible: true, type: 'pending', description: messageErr.pending }));
		}
		try {
			const params = {
				...withdrawBaseData,
			};
			const response: AxiosApiResponse<IDoWithdrawResponse> = yield call(API.doWithdraw, params, withdrawMethod?.payment_id || action.data.method);
			if (response && response.status === 200) {
				if (PAYMENT_PROVIDERS.tronlink !== action.data.method){
					yield put(modalReducers.setInfoUI({ visible: true, type: 'success', description: messageErr.success }));
				}
				storeWaitPayment(WAIT_PAYMENT_STATUS.WITHDRAW);
				if (response.data.data.url) {
					window.location.href = `${response.data.data.url}`;
				} else {
					if (PAYMENT_PROVIDERS.tronlink !== action.data.method) {
						yield put( withdrawReducers.setWithdrawSuccess( {
							showSuccess: true,
							successMsg : 'success',
							id         : String(action.data.method),
						} ) );
					}
				}
				yield put(withdrawReducers.baseDataRefresh({ 
					amount       : '',
					email        : '',
					phone        : '',
					card_id      : '', 
					ewallet_id   : '',
					masterCardEur: '',
				}));
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				const isPhone = errorCode === ErrorCodes.PHONE_VERIFICATION_REQUIRED;
				const isEmail = errorCode === ErrorCodes.EMAIL_VERIFICATION_REQUIRED;
				if (isPhone || isEmail){
					const linkTitle = isPhone ? verificationTypes.phone : verificationTypes.email;
					yield put(modalReducers.setInfoUI({
						visible    : true,
						type       : 'reject',
						description: e.message,
						showLink   : true,
						linkTitle  : `verification_link_to_${linkTitle}`,
						hrefTo     : `profile?ver=${linkTitle}`,
					}));
				}
			}
		}
		yield put(withdrawReducers.withdrawUiRefresh({ loading: false, favouriteAmount: 0 }));
		
	});
}

function* getCards() {
	yield takeEvery(withdrawActions.GET_CARDS, function* (action: ISagaActionType<number>) {

		try {
			const { data } = action;
			const response: AxiosApiResponse<ICard[]> = yield call(API.getCards, data);

			if (response && response.status === 200) {
				const adaptedData = adaptCards(response.data.data) as ITronAccountEntities;
				// yield put(withdrawActions.setCards({ [data]: response.data.data }));
				yield put(withdrawReducers.setCards({ cards: response.data.data, paymentID: data }));
				yield put(depositReducers.accountDataRefresh(adaptedData));
			}

		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);
		}
	});
}

function* getFeeTronlink() {
	yield takeEvery(withdrawActions.GET_FEE_TRONLINK, function* (action: ISagaActionType<IGetTronFeePayload>) {
		yield put(withdrawReducers.withdrawUiRefresh({ loadingFee: true }));
		try {
			const { data } = action;
			const response: AxiosApiResponse<{fee: number}> = yield call(API.getFeeTronlink, data);
			if (response && response.status === 200) {
				const { fee } = response.data.data;
				yield put(withdrawReducers.setFeeTron(String(fee)));
			} else {
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: lang.blocked }));
			}

		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			logger.log(e);

		} finally {
			yield put(withdrawReducers.withdrawUiRefresh({ loadingFee: false }));
		}
	});
}


function* withdrawSaga() {
	yield all([
		fork(withdrawMethods),
		fork(withdrawMethodsPayways),
		fork(doWithdraw),
		fork(getCards),
		fork(getFeeTronlink),
	]);
}

export default withdrawSaga;
