import { IClaimDeclineBonusPayload } from './types';
import { IHistoryFilters } from '../history/types';

const actions = {
	GET_BONUSES       : 'GET_BONUSES',
	DECLINE_BONUS     : 'DECLINE_BONUS',
	CLAIM_BONUS       : 'CLAIM_BONUS',
	GET_CASINO_BONUSES: 'GET_CASINO_BONUSES',
	GET_CASINO_SPINS  : 'GET_CASINO_SPINS',
	getBonuses        : () => {
		return {
			type: actions.GET_BONUSES,
		};
	},
	declineBonus: (payload: IClaimDeclineBonusPayload) => {
		return {
			type: actions.DECLINE_BONUS,
			data: payload,
			payload,
		};
	},
	claimBonus: (data: IClaimDeclineBonusPayload) => {
		return {
			type: actions.CLAIM_BONUS,
			data,
		};
	},
	getCasinoBonuses: (data: IHistoryFilters) => {
		return {
			type: actions.GET_CASINO_BONUSES,
			data,
		};
	},

	getCasinoSpins: (data: IHistoryFilters) => {
		return {
			type: actions.GET_CASINO_SPINS,
			data,
		};
	},
};

export default actions;
