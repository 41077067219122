import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTE_PATH } from '../../routes/utils';
import { useAppDispatch } from '../../hooks/storeHooks';
import { modalReducers } from '../../redux/modals/slice';

const { setInfoUI } = modalReducers;

export const NavigatePaymentSystem = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
    
	if (location.pathname === `/${ROUTE_PATH.paymentSuccess}`) {
		dispatch(setInfoUI({ visible: true, type: 'success', description: `${ROUTE_PATH.paymentSuccess}_message` }));
	}
	if (location.pathname === `/${ROUTE_PATH.paymentFail}`) {
		dispatch(setInfoUI({ visible: true, type: 'warning', description: `${ROUTE_PATH.paymentFail}_message` }));
	}

	useEffect(() => {
		navigate('/');
	}, [navigate]);

	return null;
};