import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { BANNER_GROUPS, IBannerPayload, IBannersInitialState, IUI } from './types';
import { fill } from '../../helpers/utils';

const initState: IBannersInitialState = {
	[BANNER_GROUPS.footerApps]     : [],
	[BANNER_GROUPS.footerCompanies]: [],
	[BANNER_GROUPS.footerPayments] : [],
	[BANNER_GROUPS.footerProviders]: [],
	[BANNER_GROUPS.footerSocial]   : [],
	[BANNER_GROUPS.lumos]          : [],
	[BANNER_GROUPS.sidebarBlog]    : [],
	[BANNER_GROUPS.gameList]       : [],
	[BANNER_GROUPS.promotions]     : [],
	[BANNER_GROUPS.license]        : [],
	UI                             : {
		loading: false,
	},
};

const banners = createSlice({
	name        : 'banners',
	initialState: initState,
	reducers    : {
		setBanners(state: Draft<IBannersInitialState>, action: PayloadAction<IBannerPayload>) {
			const section = action.payload.section;
			state[section] = action.payload.data;
		},
		setUI: (state: Draft<IBannersInitialState>, action: PayloadAction<IUI>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
	},
});

export const bannerReducers = {
	setBanners: banners.actions.setBanners,
	setUI     : banners.actions.setUI,
};

export default banners.reducer;