import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import getDisplayedCookies, {  SCRIPT_DISPLAY_TYPES } from './utils';
import { cookieActions } from './actions';
import Api from './api';
import { injectToDocument } from '../settings/utils';
import { setCookie } from '../../helpers/cookies';
import { cookieExpirationDate } from '../../config';
import { logger } from '../../helpers/debugLogger';
import { ISagaActionType } from '../types';
import { IAcceptCookiePayload, ICookie, ICookieGetStore, SCRIPT_COOKIE_TYPE } from './types';
import { cookieReducers } from './slice';
import { RootState } from '../store';
import { AxiosApiResponse } from '../../service/types';
import { ScriptItem } from '../settings/types';
import { SCRIPT_DISPLAY_TYPE } from '../../helpers/types';

const API = new Api();

const getStoreData = ({ Settings }: RootState): ICookieGetStore => {
	return {
		cookieScripts: Settings.cookieScripts,
		scripts      : Settings.scripts,
	};
};

function* cookieIDs() {
	yield takeEvery(cookieActions.COOKIE_IDS, function* () {
		try {
			const response: AxiosApiResponse<number[]> = yield call(API.getCookieIDs);
			if (response && response.status === 200) {
				const { data } = response.data;
				const displayedCookies: ICookie[] = getDisplayedCookies(data);

				yield put(cookieReducers.setCookieInfo(displayedCookies));
			}
		} catch (e) {
			if (e instanceof Error) {
				const message = {
					title  : 'Error',
					message: e.message,
					type   : 'danger',
				};
				logger.log(message);
			}
		}
	});
}

function* acceptCookies() {
	yield takeEvery(cookieActions.ACCEPT_COOKIES, function* (action: ISagaActionType<IAcceptCookiePayload>) {
		const { data } = action;
		const { typeCookie, IDs = [] } = data;
		const { cookieScripts }: ICookieGetStore = yield select(getStoreData);
		switch (typeCookie) {
			case SCRIPT_DISPLAY_TYPES.ALL: {
				injectToDocument(cookieScripts);
				setCookie('cookie_type', SCRIPT_DISPLAY_TYPE.ALL, cookieExpirationDate);
				break;
			}
			case SCRIPT_DISPLAY_TYPES.NECESSARY: {
				const necessaryCookie = cookieScripts.filter(item => item.type === SCRIPT_COOKIE_TYPE.STRICTLY_NECESSARY);
				injectToDocument(necessaryCookie);
				setCookie('cookie_type', SCRIPT_DISPLAY_TYPE.NECESSARY, cookieExpirationDate);
				break;
			}
			case SCRIPT_DISPLAY_TYPES.CHOOSES: {
				const necessaryCookie = cookieScripts.filter(item => {
					for (let i = 0; i < IDs.length; i++) {
						if (IDs[i] === item.type) {
							return item;
						}
					}
					return null;
				});
				injectToDocument(necessaryCookie);
				setCookie('cookie_type', SCRIPT_DISPLAY_TYPE.CHOOSES, cookieExpirationDate, IDs);
				break;
			}
			default: {
				break;
			}
		}
		yield put(cookieReducers.setUI({ cookieAccept: true }));
	});
}

function* attachCookies() {
	yield takeEvery(cookieActions.ATTACH_COOKIES, function* ({ data } : ISagaActionType<IAcceptCookiePayload>) {
		const { typeCookie, IDs = [] } = data;
		const { cookieScripts }: ICookieGetStore = yield select(getStoreData);
		switch (typeCookie) {
			case SCRIPT_DISPLAY_TYPES.ALL: {
				injectToDocument(cookieScripts);
				break;
			}
			case SCRIPT_DISPLAY_TYPES.NECESSARY: {
				const necessaryCookie: ScriptItem[] = cookieScripts.filter(item => item.type === SCRIPT_COOKIE_TYPE.STRICTLY_NECESSARY);
				injectToDocument(necessaryCookie);
				break;
			}
			case SCRIPT_DISPLAY_TYPES.CHOOSES: {
				const cookies: ScriptItem[] = cookieScripts.filter(item => {
					for (let i = 0; i < IDs.length; i++) {
						if (Number(IDs[i]) === item.type) {
							return item;
						}
					}
					return null;
				});
				injectToDocument(cookies);
				break;
			}
			default: {
				break;
			}
		}
	});
}

function* cookiesSaga() {
	yield all([
		fork(cookieIDs),
		fork(acceptCookies),
		fork(attachCookies),
	]);
}

export default cookiesSaga;
