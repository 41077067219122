import { IBonusAdapted, IBonusResponse, IWallet } from './types';

/**
 * Adapt bonuses data
 * @param data - bonuses data
 * @returns adapted bonuses data with wallets included in the bonuses array
 * there are 3 types of bonuses: free spins, wallets and pack bonuses.
 * pack bonuses are mix of free spins and bonus money ( wallets are bonus money)
 * pack bonuses are created by the system, and they are visible to user as a single bonus
 * this function merges free spins and wallets into a single array, so that
 * the user can see all bonuses in one place and pack bonuses as a single bonus
 *
 * In order to decline or claim bonus, we need to know the id of the bonus item.
 * 1. Free spins have wallet_id as claimableID ( free_spins list element wallet_id )
 * 2. Wallets have id as claimableID ( wallets list element id )
 * 3. Pack bonuses have id as claimableID ( wallets list element id )
 *
 *
 */
const adaptBonuses = (data: IBonusResponse) => {
	const { free_spins = [], wallets = [] } = data;
	const bonuses: IBonusAdapted[] = [];

	free_spins.forEach(freeSpin => {
		const packBonusWallet: IWallet | undefined = wallets.find(item => item.pack_bonus_id === freeSpin.pack_bonus_id && !!item.pack_bonus_id && !!freeSpin.pack_bonus_id);

		const mergedItem: IBonusAdapted = {
			claimableID            	   : freeSpin.wallet_id || freeSpin.id,
			spins_total                : freeSpin.spins_total,
			free_spin_wagering_turnover: freeSpin.wagering_turnover,
			title                      : freeSpin.title,
			id                         : freeSpin.id,
			wallet_id                  : freeSpin.wallet_id,
			created                    : freeSpin.created,
			game_image_sm_url          : freeSpin.game_image_sm_url,
			logo_url                   : freeSpin.logo_url,
			status_id              	   : freeSpin.status_id,
			description                : freeSpin.description,
			currency_id            	   : freeSpin.currency_id,

			expire_date   : freeSpin.expire_date,
			game_name     : freeSpin.game_name,
			casino_game_id: freeSpin.casino_game_id,
			bonus_type_id : freeSpin.bonus_type_id,
		};

		if (packBonusWallet) {
			mergedItem.claimableID = packBonusWallet.id;
			mergedItem.initial_balance = packBonusWallet.initial_balance;
			mergedItem.wagering_turnover = packBonusWallet.wagering_turnover;
			bonuses.push(mergedItem);
		} else {
			const adaptedFreeSpin: IBonusAdapted = {
				claimableID            	   : freeSpin.wallet_id || freeSpin.id,
				spins_total                : freeSpin.spins_total,
				free_spin_wagering_turnover: freeSpin.wagering_turnover,
				title                      : freeSpin.title,
				id                         : freeSpin.id,
				wallet_id                  : freeSpin.wallet_id,
				created                    : freeSpin.created,
				game_image_sm_url          : freeSpin.game_image_sm_url,
				logo_url                   : freeSpin.logo_url,
				status_id              	   : freeSpin.status_id,
				description                : freeSpin.description,
				currency_id            	   : freeSpin.currency_id,
				
				expire_date   : freeSpin.expire_date,
				game_name     : freeSpin.game_name,
				casino_game_id: freeSpin.casino_game_id,
				bonus_type_id : freeSpin.bonus_type_id,
			};
			bonuses.push(adaptedFreeSpin);
		}
	});

	wallets.forEach(wallet => {
		/**
		 * If wallet has pack_bonus_id, it means that this wallet is already included in the bonuses array.
		 */
		if (wallet.pack_bonus_id) {
			return;
		}
		const adaptedWallet: IBonusAdapted = {
			claimableID                : wallet.id,
			id                         : wallet.id,
			user_id                    : wallet.user_id,
			initial_balance            : wallet.initial_balance,
			balance                    : wallet.balance,
			bonus_id                   : wallet.bonus_id,
			bonus_type_id              : wallet.bonus_type_id,
			status_id                  : wallet.status_id,
			wagering_turnover          : wallet.wagering_turnover,
			free_spin_wagering_turnover: wallet.free_spin_wagering_turnover,
			expire_date                : wallet.expire_date,
			created                    : wallet.created,
			days_to_expire             : wallet.days_to_expire,
			comment                    : wallet.comment,
			product_type               : wallet.product_type,
			market_id                  : wallet.market_id,
			min_wagering_odd           : wallet.min_wagering_odd,
			currency_id                : wallet.currency_id,
			bonus_item_id              : wallet.bonus_item_id,
			logo_url                   : wallet.logo_url,
			title                      : wallet.title,
			description                : wallet.description,
			pack_bonus_id              : wallet.pack_bonus_id,
		};
		bonuses.push(adaptedWallet);
	});
	return {
		bonuses: bonuses.sort((a, b) => {
			return new Date(b.created || '').getTime() - new Date(a.created || '').getTime();
		}),
		unclaimedBonusCount: data.unclaimed_bonus_count,
	};
}
;

export {
	adaptBonuses,
};
