import Preloader from '../preloader';

const MainPreloader = () => {
	return (
		<div className='main_preloader main_wrapper'>
			<Preloader section='header'/>
			<Preloader section='banner'/>
			<Preloader section='winners'/>
			<Preloader section='category'/>
			<Preloader section='gamesList'/>
		</div>
	);
};

export default MainPreloader;