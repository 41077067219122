// export const SCRIPT_COOKIE_TYPE = {
// 	NONE              : 1,
// 	STRICTLY_NECESSARY: 2,
// 	PERFORMANCE_COOKIE: 3,
// 	FUNCTIONAL_COOKIES: 4,
// 	TARGETING_COOKIES : 5,
// };

import { SCRIPT_COOKIE_TYPE } from './types';

export const SCRIPT_DISPLAY_TYPES = {
	ALL      : 1,
	NECESSARY: 2,
	CHOOSES  : 3,
};

export const cookiesInfo = [
	{
		id         : SCRIPT_COOKIE_TYPE.STRICTLY_NECESSARY,
		title      : 'Strictly Necessary',
		description: 'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
		checked    : true,
		disabled   : true,
		isOpen     : false,
	},
	{
		id         : SCRIPT_COOKIE_TYPE.PERFORMANCE_COOKIE,
		title      : 'Performance Cookies',
		description: 'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
		checked    : true,
		disabled   : false,
		isOpen     : false,
	},
	{
		id         : SCRIPT_COOKIE_TYPE.FUNCTIONAL_COOKIES,
		title      : 'Functional Cookies',
		description: 'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
		checked    : true,
		disabled   : false,
		isOpen     : false,
	},
	{
		id         : SCRIPT_COOKIE_TYPE.TARGETING_COOKIES,
		title      : 'Targeting Cookies',
		description: 'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
		checked    : true,
		disabled   : false,
		isOpen     : false,
	},
];

export default function getDisplayedCookies(IDs: number[]) {
	if (!IDs.length) {
		return [cookiesInfo[0]];
	}

	return cookiesInfo.filter(item => {
		for (let i = 0; i < IDs.length; i++) {
			if (item.id === SCRIPT_COOKIE_TYPE.STRICTLY_NECESSARY) {
				return item;
			}
			if (Number(IDs[i]) === item.id) {
				return item;
			}
		}
		return null;
	});
}
