import { apiRequest } from '../../service/service';
import { IConfirmPassParams, ISendSMSCodeParams } from './types';
import { PartialConfigs } from '../../service/types';
import { TWO_FA_TYPES } from '../../components/modals/TwoFaLogin/utils';

class TwoFAApi {
	confirmPass2FA = (typeID: TWO_FA_TYPES | undefined, data: IConfirmPassParams) => {
		const url = `v1/enable2fa/${typeID}`;
		const config: PartialConfigs<IConfirmPassParams> = {
			method   : 'post',
			url      : url,
			data,
			hideError: true,
		};
		return apiRequest(config);
	};

	sendAppCode2FA = (typeID: TWO_FA_TYPES, data: ISendSMSCodeParams) => {
		const url = `v1/enable2fa/${typeID}`;
		const config: PartialConfigs<ISendSMSCodeParams> = {
			method   : 'put',
			url      : url,
			data,
			hideError: true,
		};
		return apiRequest(config);
	};

	confirm2FALogin = (typeID: string, data: ISendSMSCodeParams) => {
		const url = `v1/2fa/${typeID}`;
		const config: PartialConfigs<ISendSMSCodeParams> = {
			method   : 'put',
			url      : url,
			data,
			hideError: true,
		};
		return apiRequest(config);
	};

	sendSms2FALogin = (typeID: string, data: object) => {
		const url = `v1/2fa/${typeID}`;
		const config: PartialConfigs<object> = {
			method   : 'post',
			url      : url,
			data,
			hideError: true,
		};
		return apiRequest(config);
	};
}

export default TwoFAApi;
