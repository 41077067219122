import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import { isArray } from '../../../helpers/utils';
import Api from '../api';
import actions from './actions';
import { createPrizes, moreGamesBtn, createTournamnet, createQualifiedCounditions, createFilledConditions, createPlayerInfo  } from '../utils';
import { boardLimit } from '../../../pages/tournament/tournament-inner/utils';
import { REACT_APP_WEBSITE_ID } from '../../../config';
import { logger } from '../../../helpers/debugLogger';
import { RootState } from '../../store';
import {
	IGetModalGamesParams,
	IGetTournamentDefaultGamesParams,
	IGetTournamentParams,
	IGetTournamentResponse,
	IGetUserInfoResponse, ILeaderBoard, ILeaderBoardResponse,
	IQualifiedConditions,
	ITimePeriodAdapted, ITournamentGame,
	ITournamentGetStore,
} from './types';
import { tournamentReducers } from './slice';
import { ISagaActionType } from '../../types';
import { AxiosApiResponse } from '../../../service/types';
import { getHeaderTotalCount } from '../../utils';
import { IPaginationMeta } from '../../../translations/types';

const tournamentsAPI = new Api();

const getStoreData = ({ Profile, Tournaments, Settings }: RootState): ITournamentGetStore => {
	const isLoggedIn         = !!Profile.authToken;
	const userInfo           = Profile.profileData;
	const playerName         = userInfo.first_name || userInfo.username || userInfo.email;
	const currentLeaderBoard = Tournaments.Tournament.leaderBoard;
	const currentGamesList   = Tournaments.Tournament.tournamentGames;
	const channelID = Settings.channelID;

	return {
		websiteID: REACT_APP_WEBSITE_ID,
		langID   : Settings?.current_language?.id || 1, // FIXME: default language id
		isLoggedIn,
		playerName,
		currentLeaderBoard,
		currentGamesList,
		channelID,
	};
};

function* getTournament() {
	yield takeEvery(actions.TOURNAMENT_RELOAD, function* (action: ISagaActionType<number>) {
		yield put(tournamentReducers.setUI({ loading: true }));

		const { websiteID, langID }: ITournamentGetStore = yield select(getStoreData);
		try {
			const { data: tournamentID } = action;
			const params: IGetTournamentParams = {
				website_id: websiteID,
				lang_id   : langID,
			};
			const res: AxiosApiResponse<IGetTournamentResponse> = yield call(tournamentsAPI.getTournament, tournamentID, params);
			if (res && res.status === 200) {
				yield put(actions.getTournamentDefaultGames(tournamentID));
				const { data } = res.data;
				const { isLoggedIn, playerName }                              = yield select(getStoreData);
				const { time_period: timePeriod, prizes, qualify_conditions } = data;
				const qualifiedConditions: IQualifiedConditions                                   = createQualifiedCounditions(qualify_conditions);
				const { tournamentPrizes, prizesimages }                      = createPrizes(prizes);
				const isStarted = new Date(timePeriod.start).getTime() < new Date().getTime();
				const adaptedTimePeriod: ITimePeriodAdapted = {
					...timePeriod,
					isStarted,
				};
				if (isStarted) {
					yield call(makeRequestLeaderBoard, tournamentID, { page: 1, limit: boardLimit });
				}

				if (isLoggedIn) {
					const res: AxiosApiResponse<IGetUserInfoResponse> = yield call(tournamentsAPI.getUserInfo, tournamentID);
					if (res && res.status === 200) {
						const { qualify_stats, user_place }  = res.data.data;
						qualifiedConditions.filledConditions = createFilledConditions(qualify_stats);
						const player                         = createPlayerInfo(user_place, playerName, prizesimages);
						yield put(tournamentReducers.updateLeaderBoard({ player }));
					}
				}
				const tournament = createTournamnet(data, adaptedTimePeriod, tournamentPrizes, prizesimages, qualifiedConditions);
				yield put(tournamentReducers.updateTournament(tournament));
			}
		} catch (e) {
			logger.log(e);
		}
		yield put(tournamentReducers.setUI({ loading: false }));
	});
}

function* makeRequestLeaderBoard(tournamentID: number, params: IPaginationMeta) {
	yield put(tournamentReducers.setUI({ leaderBoardLoading: true }));
	try {

		const { currentLeaderBoard }: ITournamentGetStore = yield select(getStoreData);
		const res: AxiosApiResponse<ILeaderBoardResponse[]> = yield call(tournamentsAPI.getLeaderBoard, tournamentID, params);
		if (res && res.status === 200) {
			const normalized: ILeaderBoard[] = res.data.data.map((item) => {
				const { place, player, score, user_masked_name, points } = item;
				return { place, playerName: player.name, score, user_masked_name, points };
			});
			const concatLeaderBoard: ILeaderBoard[] = currentLeaderBoard.leaderBoard.concat(normalized);
			const totalBoardCount   = getHeaderTotalCount(res);
			yield put(tournamentReducers.updateLeaderBoard({
				leaderBoard    : concatLeaderBoard,
				totalBoardCount: totalBoardCount,
			}));
		}
	} catch (e) {
		logger.log(e);
	}
	yield put(tournamentReducers.setUI({ leaderBoardLoading: false }));
}

function* getTournamentLeaderBoard() {
	yield takeEvery(actions.TOURNAMENT_LEADERBOARD_RELOAD, function* (action: ISagaActionType<{ tournamentID: number; params: IPaginationMeta }>) {
		const { tournamentID, params } = action.data;
		yield call(makeRequestLeaderBoard, tournamentID, params);
	});
}

function* getTournamentDefaultGames() {
	yield takeEvery(actions.TOURNAMENT_DEFAULT_GAMES_RELOAD, function* (action: ISagaActionType<number>) {
		const {  channelID }: ITournamentGetStore = yield select(getStoreData);
		yield put(tournamentReducers.setUI({ defaultGamesLoading: true }));

		try {
			const { websiteID }    = yield select(getStoreData);
			const tournamentID  = action.data;
			const params: IGetTournamentDefaultGamesParams = {
				page      : 1,
				limit     : 10,
				website_id: websiteID,
				channel_id: channelID,
			};
			const res: AxiosApiResponse<ITournamentGame[]> = yield call(tournamentsAPI.getTournamentDefaultGames, tournamentID, params);
			if (res && res.status === 200) {
				const { data } = res.data;
				if (isArray(data) && data.length) {
					data.push(moreGamesBtn);
					yield put(tournamentReducers.setTournamentGames({ tournamentDefaultGames: data }));
				}
			}
		} catch (e) {
			logger.log(e);
		}
		yield put(tournamentReducers.setUI({ defaultGamesLoading: false }));
	});
}

function* getModalGames() {
	yield takeEvery(actions.TOURNAMENT_MODAL_GAMES_RELOAD, function* (action:ISagaActionType<{ tournamentID: number; params: IPaginationMeta}>) {
		const { channelID }: ITournamentGetStore = yield select(getStoreData);
		yield put(tournamentReducers.setUI({ gameListLoading: true }));
		try {
			const { tournamentID, params } = action.data;
			const { websiteID, currentGamesList }: ITournamentGetStore = yield select(getStoreData);
			const normalizedParams: IGetModalGamesParams = {
				...params,
				page      : params.page + 1,
				website_id: websiteID,
				channel_id: channelID,
			};
			const res: AxiosApiResponse<ITournamentGame[]> = yield call(tournamentsAPI.getModalGames, tournamentID, normalizedParams);

			if (res && res.status === 200) {
				const { data } = res;
				const concatGames = currentGamesList.tournamentGames.concat(data.data);
				const totalGamesCount = getHeaderTotalCount(res);
				yield put(tournamentReducers.setTournamentGames({
					tournamentGames: concatGames,
					totalGamesCount: totalGamesCount,
				}));
			}
		} catch (e) {
			logger.log(e);
		}
		yield put(tournamentReducers.setUI({ gameListLoading: false }));
	});

}

function* joinTournament() {
	yield takeEvery(actions.TOURNAMENT_JOIN, function* (action: ISagaActionType<number>) {
		const { data: tournamentID } = action;
		yield put(tournamentReducers.setUI({ loading: true }));
		try {
			yield call(tournamentsAPI.joinTournament, tournamentID);
		} catch (e) {
			logger.log(e);
		}
		yield put(tournamentReducers.setUI({ loading: false }));
	});
}

function* tournamentSaga() {
	yield all([
		fork(getTournament),
		fork(getTournamentLeaderBoard),
		fork(getTournamentDefaultGames),
		fork(getModalGames),
		fork(joinTournament),
	]);
}

export default tournamentSaga;
