import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/controls/loader';
import { useRootAppNavigate } from '../../hooks/useAppNavigate';
import { reStoreAuthToken, storeAuthToken } from '../../helpers/localStorageUtils';

const DepositInApp = () => {
	const { jwt } = useParams();
	const navigate = useRootAppNavigate();
	const authToken = reStoreAuthToken();

	useEffect(() => {
		if (!authToken && jwt) {
			storeAuthToken(jwt);
			window.location.reload();
		}
		if (authToken) {
			return navigate('/');
		}
	}, [authToken, jwt, navigate]);


	return (
		<div>
			<Loader/>
		</div>
	);
};

export default DepositInApp;
