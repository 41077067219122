const actions = {
	UPDATE_NOTIFICATION_SOCKET    : 'UPDATE_NOTIFICATION_SOCKET',
	updateNotificationActionSocket: <T>(data: T) => {
		return {
			type: actions.UPDATE_NOTIFICATION_SOCKET,
			data,
		};
	},
};

export default actions;
