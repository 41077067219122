import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	IBalanceAdjustmentHistory,
	ICasinoHistory,
	IDepositHistory,
	IFilterRefreshPayload,
	IHistoryInitState,
	ITransactionsHistory,
	IWithdrawalHistory,
} from './types';
import { history_page_limit } from '../../components/history/config';
import { fill } from '../../helpers/utils';

const initialState : IHistoryInitState = {
	casinoHistory: {
		data     : [],
		dataCount: 0,
		filters  : {
			page : 1,
			limit: history_page_limit,
		},
		UI: {
			loading: false,
		},
	},
	casinoSpins: {
		filters: {
			page : 1,
			limit: history_page_limit,
		},
	},
	casinoBonuses: {
		filters: {
			page : 1,
			limit: history_page_limit,
		},
	},
	depositHistory: {
		data     : [],
		dataCount: 0,
		filters  : {
			page : 1,
			limit: history_page_limit,
		},
		UI: {
			loading: false,
		},
	},
	withdrawalsHistory: {
		data     : [],
		dataCount: 0,
		filters  : {
			page : 1,
			limit: history_page_limit,
		},
		UI: {
			loading: false,
		},
	},
	transactionsHistory: {
		data     : [],
		dataCount: 0,
		filters  : {
			page : 1,
			limit: history_page_limit,
		},
		UI: {
			loading: false,
		},
	},
	balanceAdjustmentHistory: {
		data                  : [],
		dataCount             : 0,
		balanceAdjustmentExist: false,
		filters               : {
			page : 1,
			limit: 1,
		},
		UI: {
			loading: false,
		},
	},
};
const history = createSlice({
	name        : 'history',
	initialState: initialState,
	reducers   	: {
		setCasinoHistory(state: Draft<IHistoryInitState>, action: PayloadAction<Partial<ICasinoHistory>>) {
			const source = action.payload;
			const target = state.casinoHistory;
			const result = fill(source, target);
			state.casinoHistory = result;
		},
		setDepositHistory(state: Draft<IHistoryInitState>, action: PayloadAction<Partial<IDepositHistory>>) {
			const source = action.payload;
			const target = state.depositHistory;
			const result = fill(source, target);
			state.depositHistory = result;
		},
		setWithdrawalHistory(state: Draft<IHistoryInitState>, action: PayloadAction<Partial<IWithdrawalHistory>>) {
			const source = action.payload;
			const target = state.withdrawalsHistory;
			const result = fill(source, target);
			state.withdrawalsHistory = result;
		},
		setBalanceAdjustmentHistory(state: Draft<IHistoryInitState>, action: PayloadAction<Partial<IBalanceAdjustmentHistory>>) {
			const source = action.payload;
			const target = state.balanceAdjustmentHistory;
			const result = fill(source, target);
			state.balanceAdjustmentHistory = result;
		},
		filterRefresh(state: Draft<IHistoryInitState>, action: PayloadAction<IFilterRefreshPayload>) {
			const { filter, type } = action.payload;
			state[type].filters = filter;
		},
		setBalanceAdjustmentExist(state: Draft<IHistoryInitState>, action: PayloadAction<boolean>) {
			state.balanceAdjustmentHistory.balanceAdjustmentExist = action.payload;
		},
		setTransactionsHistory(state: Draft<IHistoryInitState>, action: PayloadAction<Partial<ITransactionsHistory>>) {
			const source = action.payload;
			const target = state.transactionsHistory;
			const result = fill(source, target);
			state.transactionsHistory = result;
		},
		resetHistoryState() {
			return initialState;
		},
	},
});


export const historyReducers = {
	setCasinoHistory           : history.actions.setCasinoHistory,
	setDepositHistory          : history.actions.setDepositHistory,
	setWithdrawalHistory       : history.actions.setWithdrawalHistory,
	setBalanceAdjustmentHistory: history.actions.setBalanceAdjustmentHistory,
	setBalanceAdjustmentExist  : history.actions.setBalanceAdjustmentExist,
	setTransactionsHistory     : history.actions.setTransactionsHistory,
	filterRefresh              : history.actions.filterRefresh,
	resetHistoryState          : history.actions.resetHistoryState,
};
export default history.reducer;