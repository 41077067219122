import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	ILeaderBoardPayload,
	ITournament,
	ITournamentGamesPayload,
	ITournamentInitialState, ITournamentUI,
} from './types';

const initialState: ITournamentInitialState = {
	tournament: {
		tournamentDefaultGames: [],
		timePeriod            : {
			start    : '',
			end      : '',
			isStarted: false,
		},
		name          			  : '',
		qualifiedConditions: {
			requiredConditions: {},
		},
		id                : 0,
		title             : '',
		subTitle          : '',
		description       : '',
		bannerUrl         : '',
		termsAndConditions: '',
		tournamentPrizes  : [],
		prizesimages      : [],

	},
	tournamentGames: {
		tournamentGames: [],
		totalGamesCount: 0,
	},
	leaderBoard: {
		leaderBoard   	: [],
		totalBoardCount: 0,
	},
	UI: {
		loading            : false,
		defaultGamesLoading: false,
		leaderBoardLoading : false,
		gameListLoading	   : false,
		tournamentID       : null,
	},
};

const tournament = createSlice({
	name    : 'tournament',
	initialState,
	reducers: {
		updateTournament(state: Draft<ITournamentInitialState>, action: PayloadAction<Partial<ITournament>>) {
			const source = action.payload;
			const target = state.tournament;
			state.tournament = { ...target, ...source };
		},
		setTournamentGames(state: Draft<ITournamentInitialState>, action: PayloadAction<Partial<ITournamentGamesPayload>>) {
			const source = action.payload;
			const target = state.tournamentGames;
			state.tournamentGames = { ...target, ...source };
		},
		updateLeaderBoard(state: Draft<ITournamentInitialState>, action: PayloadAction<Partial<ILeaderBoardPayload>>) {
			const source = action.payload;
			const target = state.leaderBoard;
			state.leaderBoard = { ...target, ...source };
		},
		setUI(state: Draft<ITournamentInitialState>, action: PayloadAction<Partial<Partial<ITournamentUI>>>) {
			const source = action.payload;
			const target = state.UI;
			state.UI = { ...target, ...source };
		},
	},
});

export const tournamentReducers = {
	updateTournament  : tournament.actions.updateTournament,
	setTournamentGames: tournament.actions.setTournamentGames,
	updateLeaderBoard : tournament.actions.updateLeaderBoard,
	setUI             : tournament.actions.setUI,
};

export default tournament.reducer;