import Preloader from '../index';

const ProfilePageReloadPreloader = () => {
	return (
		<div className='profile_page_preloader'>
			<Preloader section='header'/>
			<Preloader section='profile' />
		</div>
	);
};

export default ProfilePageReloadPreloader;