import React from 'react';
import { IGamesGroupPreloaderProps } from '../types';

const GamesGroupPreloader: React.FC<IGamesGroupPreloaderProps> = ({ itemCount, className, headClassName, pageNumber }) => {
	const skeletonCount: string[] = new Array(itemCount).fill('Item');
	const showHeader = Number(pageNumber) <= 2;
	return (
		<div className={className}>
			{showHeader && headClassName && <div className={headClassName}> <span className="animate_block"></span> </div>}
			<ul className="games_preloader">
				{skeletonCount.map((_, i) =>  <li key={i}><span></span></li>)}
			</ul>
		</div>
	);
};

export default GamesGroupPreloader;
