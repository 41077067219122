import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	IDefaultGrid,
	IDefaultGridLayout,
	IDefaultGridPages,
	IGamesGridInitialState,
	IGamesGridInitialUI,
} from './types';

const initialState: IGamesGridInitialState = {
	defaultGrid      : [],
	defaultGridLayout: [],
	defaultGridPages : {},
	UI               : {
		loading: false,
	},

};

const gamesGrid = createSlice({
	name    : 'gamesGrid',
	initialState,
	reducers: {
		setDefaultGrid(state: Draft<IGamesGridInitialState>, action: PayloadAction<IDefaultGrid[]>) {
			state.defaultGrid = action.payload;
		},
		setDefaultGridLayout(state: Draft<IGamesGridInitialState>, action: PayloadAction<IDefaultGridLayout[]>) {
			state.defaultGridLayout = action.payload;
		},
		setDefaultGridPages(state: Draft<IGamesGridInitialState>, action: PayloadAction<IDefaultGridPages>) {
			state.defaultGridPages = { ...state.defaultGridPages, ...action.payload };
		},
		resetGridPages(state: Draft<IGamesGridInitialState>) {
			state.defaultGridPages = initialState.defaultGridPages;
		},
		setUI(state: Draft<IGamesGridInitialState>, action: PayloadAction<Partial<IGamesGridInitialUI>>) {
			const source = action.payload;
			const target = state.UI;
			state.UI = { ...target, ...source };
		},
	},
});


export const gameGridReducers = {
	setDefaultGrid      : gamesGrid.actions.setDefaultGrid,
	setDefaultGridLayout: gamesGrid.actions.setDefaultGridLayout,
	setDefaultGridPages : gamesGrid.actions.setDefaultGridPages,
	resetGridPages      : gamesGrid.actions.resetGridPages,
	setUI               : gamesGrid.actions.setUI,
};
export default gamesGrid.reducer;