const actions = {
	GET_TOP_WINNERS_LIST : 'GET_TOP_WINNERS_LIST',
	GET_LAST_WINNERS_LIST: 'GET_LAST_WINNERS_LIST',

	getTopWinnersAction: () => {
		return {
			type: actions.GET_TOP_WINNERS_LIST,
		};
	},

	getLastWinnersAction: () => {
		return {
			type: actions.GET_LAST_WINNERS_LIST,
		};
	},
};

export default actions;
