
export interface ISocketInitialState {
	status: SOCKET_CONNECTION_STATUS;
	sid   : string;
}
export enum SOCKET_CONNECTION_STATUS {
	CONNECTED   = 1,
	DISCONNECTED= 2,
}

export interface ISocketStore {
	connectionStatus: SOCKET_CONNECTION_STATUS;
}

export enum SOCKET_EMIT_EVENTS {
	CHANGE_TOKEN = 'change-token',
}