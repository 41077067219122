interface I2FAType {
    enable_2fa_app: boolean;
    enable_2fa_sms: boolean;
}

export const check2FAType = ({ enable_2fa_app, enable_2fa_sms }: I2FAType) => {
	if (enable_2fa_app && enable_2fa_sms) {
		return TWO_FA_TYPES.both;
	}
	if (enable_2fa_app) {
		return TWO_FA_TYPES.appAuth;
	}
	if (enable_2fa_sms) {
		return TWO_FA_TYPES.smsAuth;
	}
	return TWO_FA_TYPES.none;
};

export enum TWO_FA_TYPES {
	none    = 0,
	appAuth = 1,
	smsAuth = 2,
	both    = 3,
}


export const invertedValues = {
	[TWO_FA_TYPES.appAuth]: TWO_FA_TYPES.smsAuth,
	[TWO_FA_TYPES.smsAuth]: TWO_FA_TYPES.appAuth,
};

export type TInvertedValues = typeof invertedValues;