
export interface IHelpInitialState {
	ratings    : IRating[];
	term       : string;
	topicsRates: ITopicRating;
	chat       : CHAT_VIEW_STATUS;
	topics     : ITopic[];
}

/**
 * this interface is an assumptions,
 * as at the moment of writing this code,
 * the actual interface is not available
*/
export interface ITopic {
	id         : number;
	name       : string;
	rate       : number;
	title      : string;
	defaultOpen: boolean;
	questions  : IQuestion[];
}

export interface IQuestion {
	id         : number;
	title      : string;
	answer     : string;
	defaultOpen: boolean;
}


export interface IRating {
	id  : number;
	name: string;
}

export interface ITopicRating {
	[topic_id: number]: number;
}

export interface ITopicRate {
	question: number;
	rating  : number;
}

export interface IRateTopicPayload {
	rating_id: number;

}

export interface ITopicsResponse extends ITopic{

}

export enum CHAT_VIEW_STATUS {
	OPEN,
	CLOSE
}