import {
	ApiRequestConfig,
	ApiResponse, ISportBookData, ISportBookProvidersData, ISportBookRequestBody,
	TParams,
} from './types';
import { apiRequest } from '../../service/service';

class SportsBookApi {
	getScripts = (params: TParams = {}, data: ISportBookRequestBody): Promise<void | ApiResponse<ISportBookData>> => {
		const url = 'v1/sport/startGame';
		const config: ApiRequestConfig = {
			method: 'post',
			url   : url,
			params,
		};
		const requestConfig = {
			...config,
			data,
		};
		return apiRequest(requestConfig);
	};

	getSportProviderId = (params: TParams = {}): Promise<void | ApiResponse<ISportBookProvidersData[]>> => {
		const url = 'v1/sport/providers';
		const config: ApiRequestConfig = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};
}

export default SportsBookApi;
